import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { checkRole } from '../../../helper/useUserData';


export const AddRowBtn = ({handleAdd,setSpinning,...props}) =>{
    const ref = useRef(null);
    const [rowAdd,setRowAdd] = useState(1);

    useEffect(()=>{
        props?.setRef(ref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ref])

    useHotkeys('ctrl+a', event => {
        event.preventDefault();
        ref.current.click();
    },
    {
        ignoreTags: ['input', 'select', 'textarea'],
        enableOnFormTags:true,
        preventDefault:true,
    })

    const handleChange = (value) => {
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(value) || value === '' || value === '-') {
            setRowAdd(value);
        }
    };

    return <Tooltip title="Add Row (Ctrl+A)">
        <Space.Compact block="true">
            <InputNumber 
                onChange={handleChange} 
                defaultValue={1} 
                min={1}
                max={10} 
                controls={false}
                parser={value => value.replace(/[A-Z]|[a-z]|[$ ]|\.+/g, "")}
                style={{width:40}}
                onPressEnter={(e)=>{
                    e.preventDefault();
                    ref.current.click();
                }}
            />
            <Button 
                type='default' 
                htmlType='button' 
                icon={<PlusOutlined/>} 
                onClick={()=>{ 
                    setSpinning(true); 
                    setTimeout(() => {
                        handleAdd(rowAdd)
                    }, 200);}
                } 
                ref={ref}>Row (Ctrl+A)</Button>
        </Space.Compact>
        
    </Tooltip>
}

export const DeleteRowBtn = ({removeRows}) =>{
    const ref = useRef(null);
    useHotkeys('ctrl+d', event => {
        event.preventDefault();
        ref.current.click();
    },
    {
        ignoreTags: ['input', 'select', 'textarea'],
        enableOnFormTags:true,
        preventDefault:true,
    })
    return checkRole('delete') && <Tooltip title="Delete Row (Ctrl+D)">
        <Button type='default' htmlType='button' icon={<DeleteOutlined />} onClick={removeRows} ref={ref}>Delete (Ctrl+D)</Button>
    </Tooltip>
}

export const SaveBtn = ({...props}) =>{
    const ref = useRef(null);
    useHotkeys('ctrl+s', event => {
        event.preventDefault();
        ref.current.click();
    },
    {
        ignoreTags: ['input', 'select', 'textarea'],
        enableOnFormTags:true,
        preventDefault:true,
    })
    return checkRole('save') && <Tooltip title="Save (Ctrl+S)">
        <Button type='primary' htmlType='submit' ref={ref}>Save (Ctrl+S)</Button>
    </Tooltip>
}

export const ClearAllFilterBtn = ({setFilteredInfo}) =>{
    const ref = useRef(null);
    useHotkeys('ctrl+h', event => {
        event.preventDefault();
        ref.current.click();
    },
    {
        ignoreTags: ['input', 'select', 'textarea'],
        enableOnFormTags:true,
        preventDefault:true,
    })
    return <Tooltip title="Clear All Filters (Ctrl+H)">
        <Button type='default' htmlType='button' icon={<FilterOutlined />} onClick={()=>{ setFilteredInfo({}); }} ref={ref}>Clear All Filters (Ctrl+H)</Button>
    </Tooltip>
}