import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const VerticalBarChart = () => {

    const [labels, setLabels] = useState([]);
    const [backgroundColors, setBackgroundColors] = useState([]);
    const [labelValue, setLabelValue] = useState([]);

    const getPortfolioSummary = async () =>{
        var response = await PortfolioSettingServices.getPortfolioSummary();
        var responses = response.data?.data;
        var lables = []; var colors = []; var counts = [];
        for (const key in responses) {
            lables.push(responses[key]?.name);
            colors.push(responses[key]?.color);
            counts.push(responses[key]?.count);
        }
        setLabels(lables);
        setBackgroundColors(colors);
        setLabelValue(counts);
    };

    useEffect(() => {
        getPortfolioSummary();
    }, []);

    const options = {
        plugins: {
            legend: {
            display: false,
            },
            tooltip: {
            callbacks: {
                label: function (data) {
                    return data.formattedValue;
                },
            },
            },
            datalabels: {
            formatter: function (value) {
                return "₺" + new Intl.NumberFormat("tr-TR").format(value);
            },
            color: "white",
            font: {
                weight: 'bold',
                size: 12,
                family: 'poppins'
            },
            },
        },
    };
    // The following colors will be used sequentially for the chart bars
    const data = {
        labels: labels,
        datasets: [
            {
                label: labels,
                data: labelValue,
                backgroundColor: backgroundColors,
                borderWidth: 1,
            },
        ],
    };
    return <Bar data={data} options={options} />;
}
export default VerticalBarChart;
