import React, { useEffect, useState } from "react"; 
import Delayed from "../../../utils/Delayed"; 
import { left } from "@popperjs/core";
import { Card, Flex } from "antd";

function VideoGuideList({
  data 
}) {
    
    const Row = ({ item }) => {
    return (
      <div className="portfolio-listbx" style={{ marginBottom: 5 }}>
        <table width={`100%`}>
          <tbody>
            <tr>
              <td width={200}> 
                <div className="embed-responsive embed-responsive-16by9">
                  <video width="320" height="240" autoPlay={false} muted={true} controls >
                    <source src={item.linkUrl} type="video/mp4" className="embed-responsive-item"/>
                    <source src={item.linkUrl} type="video/ogg" className="embed-responsive-item"/>
                  </video>
                </div>
              </td>

              <td valign="top">
                <div style={{ padding: 10 }}>
                    
                  <Flex justify="space-between">
                    <div   style={{ width: "100%", textAlign:left }}>
                      <h4 className="text-primary">{item.caption}</h4>
                    </div>
                    <div className="text-nowrap">
                      {
                        new Date(item.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          //hour: '2-digit',
                          //minute: '2-digit',
                          //second: '2-digit',
                        })
                      }
                    </div>
                  </Flex>
                  <div   style={{ width: "100%", textAlign:left }}>
                    <h6 className="text-primary"> {item?.tags?.join(",")} </h6>
                  </div>
                  <div   style={{ width: "100%" ,textAlign:left }}>
                    <ExpandableText text={item?.description} maxLength={250} />
                  </div>
                </div>
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ExpandableText = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    if (text?.length <= maxLength) {
      return <p>{text}</p>;
    }

    if (text?.length > maxLength) {
      return (
        <div>
          <p>{isExpanded ? text : `${text?.substring(0, maxLength)}...`}</p>
          {/* <button onClick={toggleExpand} style={{ color: "blue", cursor: "pointer" }}>
              {isExpanded ? 'Show Less' : 'Show More'}
            </button> */}
        </div>
      );
    }
  };

  return (
    <div
      className="overflow-y pr-3 listview_bx"
      style={{ maxHeight: window?.innerHeight - 100, width: "100%" }}
    >
      {(data?.length > 0 &&
        data?.map((item, index) => (
          <Row key={index} item={item} isLast={index === data.length - 1} />
        ))) || <Delayed waitBeforeShow={2000}>No items found.</Delayed>}
    </div>
  );
}

export default VideoGuideList;
