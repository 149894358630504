import { Flex, Image } from 'antd'
import React from 'react'
import DashboardSvg from './../assets/Metamodel_Latest.drawio (1).png';

const CAMetaModel = ({...props}) => {
    return (
        <div className='container-fluid ca-meta-model'>
            <Flex justify='center' align='center'>
                <Image
                    src={DashboardSvg}
                    movable
                />
            </Flex>
        </div>
    )
}

export default CAMetaModel