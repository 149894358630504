import { Button, Card, Flex, Form, Layout, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import BusinessProcessHeader from '../businessProcess/BusinessProcessHeader';
import { getAllMenuPortfolio, getMenuPortfolio } from '../../utils/settingCommon';
import CardItems from './business-capability/CardItems';
import PortfolioSettingServices from '../../services/services/PortfolioSettingsService';

const selectDefaultProps = {
    showSearch:true,
    style:{minWidth:'150px'},
    size:'medium',
    allowClear:true,
}

const BusinessCapabiltyLandscapeReports = ({routeModuleName,...props}) => {
    const loadingBarRef = useRef(null);
    const [filterForm] = Form.useForm();
    const [portfolioData,setPortfolioData] = useState({});
    const [portfolioLevels,setPortfolioLevels] = useState([]);
    useEffect(()=>{
        let m = getMenuPortfolio({routeModuleName})
        setPortfolioData(m);
        getFilterLevel(m?.moduleName)
    },[routeModuleName])

    const Buttons = () => {
        return (
            <React.Fragment>
                <Button type={`default`}>Actions</Button>
            </React.Fragment>
        )
    }

    const getFilterLevel =async (moduleName) => {
        let res = await PortfolioSettingServices?.getFilteredLevels(moduleName);
        let levels = await res?.data?.data;
        setPortfolioLevels(levels || [])
    }

    return (
        <React.Fragment>
            <LoadingBar color='#1f1f1f' ref={loadingBarRef} />
            <Layout>
                <Layout.Content className='business-capability-landscape-report'>
                    <section>
                        <BusinessProcessHeader 
                            activePage={0}
                            setActivePage={()=>{}}
                            heading={"Business Capabilty Landscape"}
                            matrix={false}
                            grid={false}
                            list={false}
                            addBtn={false}
                            bulk_delete={false}
                            showActionBtn={false}
                            Buttons = {<Buttons/>}
                        />
                    </section>
                    <section>
                        <Form 
                            form={filterForm}
                            layout="inline"
                            style={{maxWidth:"none" }}
                            className='mt-2'
                        >
                            <Form.Item label="Portfolio">
                                <Select 	
                                    {...selectDefaultProps}
                                    allowClear={true}
                                    placeholder={"Select Portfolio"}
                                    defaultValue={''}
                                    options={
                                        getAllMenuPortfolio()?.map(item=>{
                                            return {
                                                label: item?.moduleName,
                                                value: item?.name,
                                            };
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Choose Level">
                                <Select 
                                    {...selectDefaultProps}
                                    placeholder={"Choose Level"}
                                    defaultValue={1}
                                    options={
                                        portfolioLevels?.map(item=>{
                                            return {label:item?.replace("L",""),value:item};
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Show Relationship">
                                <Select 
                                    {...selectDefaultProps}
                                    mode='tags'
                                    placeholder={"Show Relationship"}
                                    allowClear={true}
                                >
                                    {
                                        getAllMenuPortfolio()?.map(item=>{
                                            return (<Select.Option 
                                                    value={item.moduleName}
                                                    key={Math.random()}
                                                >
                                                    <span className='portfolio-option-color' style={{"--color":item?.color}}></span>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Button type='primary' htmlType='submit' className='px-4'>Go</Button>
                        </Form>
                    </section>
                    <section>
                        <Card className='mt-2'>
                            <Flex gap={8} className='overflow-x pb-2 bcl_resports'>
                                <CardItems items={tempBusinessCapability2}/>
                            </Flex>
                        </Card>
                    </section>
                </Layout.Content>
            </Layout>
        </React.Fragment>
    )
}

BusinessCapabiltyLandscapeReports.defaultProps = {
    routeModuleName:"BusinessCapability",
}

export default BusinessCapabiltyLandscapeReports;

const tempBusinessCapability = [
    {
        title: "Strategy and Planning",
        level:1,
        childrens:[
            {
                title: "Strategy Development",
                level:2,
            },
            {
                title: "Business Development",
                level:2,
            }
        ]
    },
    {
        title: "Manufacturing",
        level:1,
        childrens:[
            {
                title: "Production Schedule Development",
                level:2,
                childrens:[
                    {
                        title: "Hercules",
                        level:3,
                    },
                    {
                        title: "ARO",
                        level:3,
                    },
                    {
                        title: "BOP",
                        level:3,
                    },
                    {
                        title: "Capacity Planning",
                        level:3,
                    },
                    {
                        title: "Forecast Production Volumes",
                        level:3,
                    },
                ]
            },
            {
                title: "BOM Explosion",
                level:2,
                childrens:[
                    {
                        title: "MOST/ABBE",
                        level:3,
                    },
                    {
                        title: "NAPP",
                        level:3,
                    },
                    {
                        title: "Provide Usages Per Plant",
                        level:3,
                    },
                    {
                        title: "Break Down MPS PNO34 To Features (PML)",
                        level:3,
                    },
                    {
                        title: "Break Down PML To Parts per Week And Plant",
                        level:3,
                    },
                ]
            },
            {
                title: "Capacity Planninng",
                level:2,
                childrens:[
                    {
                        title: "LOGDAT Logistics Data",
                        level:3,
                    },
                    {
                        title: "SBS Toolbox",
                        level:3,
                    },
                    {
                        title: "Prepare Packinginfo",
                        level:3,
                    },
                    {
                        title: "Balance Production",
                        level:3,
                    },
                ]
            }
        ]
    },
    {
        title: "Logistics",
        level:1,
        childrens:[
            {
                title: "Inbound Logistics",
                level:2,
            },
            {
                title: "In-Plant Logistics",
                level:2,
            },
            {
                title: "Outbound Logistics",
                level:2,
            },
            {
                title: "Customs",
                level:2,
            }
        ]
    },
    {
        title: "Logistics-1",
        level:1,
    },
    {
        title: "Logistics-2",
        level:1,
    },
]


const tempBusinessCapability2 = [
    {
        title: "Manufacturing",
        level:1,
        childrens:[
            {
                title: "Hercules",
                level:3,
            },
            {
                title: "ARO",
                level:3,
            },
            {
                title: "BOP",
                level:3,
            },
            {
                title: "Capacity Planning",
                level:3,
            },
            {
                title: "Forecast Production Volumes",
                level:3,
            },
        ]
    },
    {
        title: "Logistics",
        level:1,
        childrens:[
            {
                title: "Inbound Logistics",
                level:2,
            },
            {
                title: "In-Plant Logistics",
                level:2,
            },
            {
                title: "Outbound Logistics",
                level:2,
            },
            {
                title: "Customs",
                level:2,
            }
        ]
    },    
    {
        title: "Strategy and Planning",
        level:1,
        childrens:[
            {
                title: "Strategy Development",
                level:2,
            },
            {
                title: "Business Development",
                level:2,
            }
        ]
    },
]
