import { BellOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import UserService from "../../../services/UserService";
import GlobalSearch from './GlobalSearch';
import { Button, Popover } from 'antd';
import NotificationHeader from './NotificationHeader';
const HeaderMenusItems = () => {
	// const userRole = UserService?.hasRole(['app-manager']);
	const userRole = 'admin';
	
	const getRoleName = () => {
		if(UserService.hasRole(['Admin'])){
			return "Admin";
		}else if(UserService.hasRole(['Editor'])){
			return "Editor";
		}else if(UserService.hasRole(['Viewer'])){
			return "Viewer";
		}else{
			return "Viewer";
		}
	}
	
	const headerMenus = [
		// {
		// 	// 'icon': <UserOutlined />,
		// 	'label': <GlobalSearch />,
		// 	'key': '#',
		// 	'navigate': 'false',
		// },
		{
			// 'icon': <UserOutlined />,
			'label': <Popover placement="bottomLeft" content={NotificationHeader}>
				<Button icon={<BellOutlined />} type='text' className='p-2'></Button>
			</Popover>,
			'key': '#',
			'navigate': 'false',
		},
		{
			'icon': <UserOutlined />,
			'label': "Hello, "+ UserService.getUserFullName().split(' ')[0] + `(${getRoleName()})`,
			'key': '#',
			'navigate': 'false',
		},
		{
			'icon': <LogoutOutlined />,
			'label': `Logout`,
			'key': 'logout',
			'navigate': 'false',
		}
	]
	return (headerMenus);
}

export default HeaderMenusItems;
