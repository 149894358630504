import React, { useEffect, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import TextWithTooltip from '../../../utils/TextWithTooltip';
import { Link } from 'react-router-dom';
import { Col, Collapse, Flex } from 'antd';

const RecentlyViewed = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        getRecentViewed_Portfolio();
    },[]);
 
    const getRecentViewed_Portfolio=()=>{ 
        let recentPortfolio = localStorage.getItem("recent_viewed_portfolio"); 
        let recentPortfolio_json= JSON.parse(recentPortfolio)?.reverse().slice(0, 9);

        setList(recentPortfolio_json);  
    }
    
    return (
        <React.Fragment>
            <Col flex={`auto`}>
                <Collapse defaultActiveKey={['1']} size="small" bordered={true}  expandIconPosition="end">
                    <Collapse.Panel header={<PageTitleHeading text={"Recently Viewed"} className={`my-0`}/>} key="1" showArrow={true} >
                        <div className="p-2">
                            <table className='table table-sm w-100 fs-smaller'>
                                <thead className="table-active">
                                    <tr>
                                        <th>
                                            <Flex gap={4} justify='space-between'>
                                                <div> Name </div>
                                                <div>Portfolio</div>
                                            </Flex>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {   
                                        list?.map((item) => {
                                            return <React.Fragment key={Math?.random()}>
                                                <tr>
                                                    <td>
                                                        <Flex gap={4} justify='space-between'>
                                                            <div>
                                                                <a href={item.URL}><PageTitleHeading text={<TextWithTooltip text={item?.ModelName} characterLimit={25}/>} size={5} className={`my-0`} tooltip={true}/></a>
                                                            </div>
                                                            <div>
                                                                {/* <Link to={item.URL} style={{background : item?.color}} className='px-2 py-1 cu-border-rounded text-nowrap'>{item?.Module}</Link> */}
                                                                <div style={{background : item?.color}} className='px-2 py-1 cu-border-rounded text-nowrap'>{item?.Module}</div>
                                                            </div>
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </React.Fragment>
    )
}

export default RecentlyViewed
