import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

async function getAutocompleteNodes(params) {
    const url = `${API_BASE_URL}etl/getNodes?labelType=`+params;
    let response = await http.get(url);
    return response;
}

async function getProperties(obj) {
    const url = `${API_BASE_URL}propertymaster/getProperties`;
    let response = await http.post(url, obj);
    return response;
}



const CityMapServices = {
    getAutocompleteNodes,
    getProperties,
    API_BASE_URL
}


export default CityMapServices;
