import React from 'react'
import { NotificationItemContent } from './NotificationPage';

const data = [
    {
        shortname: "App",
        displayname: "3D Experience",
        discription: "",
        username: "Ronald Richards",
        datetime: "30/08/2024 - 11:00AM",
        status: "Pending",
    },
    {
        shortname: "App",
        displayname: "3DXCAE",
        discription: "",
        username: "Cody Fisher",
        datetime: "02/09/2024 - 10:00AM",
        status: "Pending",
    },
    {
        shortname: "App",
        displayname: "KeyCloak",
        discription: "Changed checker & OPEX and 2 More Properties",
        username: "Marvin McKinney",
        datetime: "05/09/2024 - 14:00PM",
        status: "Pending",
    },
];

const NotificationRequest = ({tabKey="",notificationDataRequest}) => {
  
    return (
        <React.Fragment>
            <div className='notifications mb-2'>
                <ul>
                    {
                        notificationDataRequest?.length > 0 &&
                        notificationDataRequest?.map(item=>{
                            return <React.Fragment key={Math.random()}>
                                <li>
                                    <NotificationItemContent item={item} tabKey={tabKey}/>
                                </li>
                            </React.Fragment>
                        })|| "No items found"
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default NotificationRequest
