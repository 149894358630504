import React from 'react';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const NoMatch = () => {
  const navigate = useNavigate();
  const [windowDimensions] = useState(getWindowDimensions());

  return (
      <>
          <div className='d-flex justify-content-center align-items-center' style={{height:windowDimensions.height}}>
              <div className='text-center' >
                <h1 className="text-primary">Sorry, the page is under development!</h1>
                <div>
                  <button className='btn btn-sm btn-outline-primary mt-3 px-4 text-center mr-2' onClick={() => navigate(-1)}>Go Back</button>
                  <button className='btn btn-sm btn-outline-primary mt-3 px-4 text-center mr-2' onClick={() => navigate("/")}>Home</button>
                </div>
              </div>
          </div>
      </>
  );
}
  
  export default NoMatch
  