import React from "react";
import { Card, Tooltip, Typography } from "antd";
import { Navigate } from "react-router";

const RelationCardItems = ({ extra = true, relationItems, ...props }) => {
  const LinkTitle = ({ route, title, ...props }) => {
    return (
      <span
        onClick={() => {
          window.open(route, "_blank");
        }}
        {...props}
      >
        {title}
      </span>
    );
  };
  return (
    <React.Fragment>
      {props?.items?.map((item, index) => {
        return (
          <div key={index}>
            <Card
              title={
                <LinkTitle
                  route={item?.route}
                  title={item?.displayname}
                  className={
                    "cursor-pointer text-sm font-semibold hover:text-blue-700"
                  }
                />
              }
              extra={extra ? <span className="cu-text-primary">L1</span> : ""}
              size="small"
              style={{
                width: 300,
                backgroundColor: `${props?.cardColor || "#FF885B"}`,
              }}
              bodyStyle={{ padding: 0 }} // Use bodyStyle to properly set padding
              className="card-item"
            >
              {Object.keys(item?.relations)?.length > 0 ? (
                Object.keys(item?.relations)?.map((relation, relIndex) => (
                  <ul className="list-group list-group-flush" key={relIndex}>
                    {item?.relations[relation]?.map((chItem, chIndex) => {
                      return (
                        <li className="list-group-item d-flex" key={chIndex}>
                          <div
                            className="relationship"
                            style={{
                              "--portfolio-color": chItem?.color
                                ? chItem?.color
                                : "#FFB381",
                            }}
                          >
                            {chItem?.referenceid || "-"}
                          </div>
                          <Tooltip title={chItem?.relationName}>
                            <div>
                              <LinkTitle
                                route={chItem?.route}
                                title={chItem?.displayname}
                                className={
                                  "cursor-pointer text-xs font-semibold hover:text-blue-700"
                                }
                              />
                            </div>
                          </Tooltip>
                        </li>
                      );
                    })}
                  </ul>
                ))
              ) : (
                <>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex">
                      <div className="relationship">
                        Relations not found for{" "}
                        <span className="text-xs font-semibold">
                          {relationItems?.map((item) => item?.name).join(",")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </Card>
          </div>
        );
      })}
    </React.Fragment>
  );
};

RelationCardItems.defaultProps = {
  level: 0,
};

export default RelationCardItems;
