import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL


async function getNotificationData(obj) {
    const url = `${API_BASE_URL}homepage/getnotificationdata`;
    let response = await http.post(url, obj);
    return response;
}

async function UpdateNotificationAsRead(obj) {
    const url = `${API_BASE_URL}homepage/updatenotificationasread`;
    let response = await http.post(url, obj);
    return response;
}

async function UpdateUsernotificationToken(obj) {
    const url = `${API_BASE_URL}user/updateusernotificationtoken`;
    let response = await http.post(url, obj);
    return response;
}

async function getApplicationLifeCycleChartData(obj) {
    const url = `${API_BASE_URL}homepage/getapplicationlifecyclechartdata`;
    let response = await http.post(url, obj);
    return response;
}

async function getScenarioChartData(obj) {
    const url = `${API_BASE_URL}homepage/getscenariochartdata`;
    let response = await http.post(url, obj);
    return response;
}



const HomePageServices = {
    getNotificationData,
    UpdateNotificationAsRead,
    UpdateUsernotificationToken,
    getApplicationLifeCycleChartData, 
    getScenarioChartData
}

export default HomePageServices;
