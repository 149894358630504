import {
  Button,
  Form,
  Select,
  Spin,
  Typography,
  message,
  Table,
  Layout,
} from "antd";
import React, { useEffect, useState } from "react";

import { staticSettingPortfolioList } from "../../../utils/settingCommon";
import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
import TextWithTooltip from "../../../utils/TextWithTooltip";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import IntegrationServices from "../../../services/services/IntegrationServices";
import ScenarioItemsServices from "../../../services/services/ScenarioItemsService";
import Delayed from "../../../utils/Delayed";
import useDebounce from "../../../helper/useDebounce";
import {
  debounceTimeOut,
  deepFindByName,
  default_pageSize,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
} from "../../../utils/Common";
const { Option } = Select;

const styleBar = {
  overflow: "auto",
  minHeight: window.innerHeight / 2,
};

const rotate90 = {
  marginTop: "50px",
  marginLeft: "-10px",
};

const date = new Date();
var deadlines = [];
for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
  for (let ind = 1; ind <= 4; ind++) {
    deadlines.push({
      value: "Q" + ind + "-" + index,
      label: "Q" + ind + "-" + index,
    });
  }
}

const changeTypeDummyValues = [
  { id: 1, label: "Retire", value: 1 },
  { id: 2, label: "Replace", value: 2 },
  { id: 3, label: "Upgrade", value: 3 },
  { id: 4, label: "Retain", value: 4 },
];

const IntegrationList = ({
  heading,
  selectedModuleItems,
  loading: portfolioloading,
  setPage,
  scenarioId,

  showFilter,
  setShowFilter,
  filterData,
  setFilterData,
  selectedFilters,
  setSelectedFilters,

  setselectedItemIDRows,
  filterSubmit,
  setFilterSubmit,
  selectedRelationFilters,
  setSelectedRelationFilters,

  setIntegrationModal,
  page,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [lisOriginal, setListOriginal] = useState([]);
  const [limit, setLimit] = useState(default_pageSize);

  const [changeTypeForm1] = Form.useForm();
  const [columns, setColumns] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [showFilter2, setShowFilter2] = useState(true);
  const formData1 = {};
  const [totalRecords, setTotalRecords] = useState(0);

  const getList = async () => {
    setLoading(true);
    try {
      const list = await IntegrationServices.getAll({ limit: 20000, page: 1 });

      const dataWithKey = list?.data?.data?.map((item) => ({
        ...item,
        key: item.id,
      }));

      setList(dataWithKey);
      setListOriginal(dataWithKey);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchAllPortfolio = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });

      setPortfolioData(response?.data?.data);

      let tempColumns = [];

      const portfolioValues = response?.data?.data.filter(
        (item) => item.moduleName == "integration"
      );

      // Portfolio Items
      portfolioValues?.forEach((row) => {
        row?.portfolioSections
          .sort((a, b) => a.position - b.position)
          ?.forEach((col) => {
            col?.properties
              .sort((a, b) => a.position - b.position)
              ?.forEach((col1) => {
                let colData = {
                  title: (
                    <TextWithTooltip
                      text={col1?.displayName}
                      characterLimit="auto"
                      isTooltip
                    />
                  ),
                  dataIndex: col1?.name,
                  width: 150,
                  key: col1?.name,
                };
                tempColumns.push(colData);
              });
          });
      });

      setColumns(tempColumns);

      await getList();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    setselectedItemIDRows([]);
    setSelectedTableRows([]);
    fetchAllPortfolio();
  }, []);

  const SaveIntegrationData = async (formData) => {
    //try {

    let formValues = [];

    const action = "Add";

    const arrayFormValues = {
      items: formData?.selectedRows,
      scenarioId: scenarioId,
      action: action,
    }; 

    if (!scenarioId) {
      return message.error("Scenario not found.");
    }

    if (formData?.selectedRows?.length) {
      formData?.selectedRows.map((selecteID) => {
        formValues.push({
          ...formData,
          scenarioId,
          itemId: selecteID,
          portfolioSettingsId: 9,
        });
      });
    }

    const arrayFormValues_ScenarioItem = { items: formValues, action: action };

    //Save in Scenario Item
    const response1 = await ScenarioItemsServices.createScenarioItem(
      arrayFormValues_ScenarioItem
    );

    //Save Data  Scenario Integration Table
    const response = await ScenarioItemsServices.createScenarioIntegrationItems(
      arrayFormValues
    );

    if (response?.data?.code === 200) {
      let responseVal = response?.data?.data;
      message.success("Updated Successfully.");
      setSelectedTableRows([]);
    }
    // } catch (error) {
    //   console.error(error);
    //   message.error("Failed to submit form. Please try again.");
    // } finally {
    //   setChangeTypeLoading(false);
    // }
  };

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
  }, [filterSubmit, debouncedFilter]);

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
     
      if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
        // setList([])

        
        const portfolio = await deepFindByName(
          portfolioData,
          "integration",
          false
        );

        const updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          (page = 1),
           limit  
        );
       
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);

            const filterArray =
              response?.data?.data?.map((item) => ({
                ...item,
                moduleName: "Integration",
                key: item.id,
              })) || [];

              
              setList(lisOriginal);
           
            //Filter   Data from main list
             

            const filterIds = filterArray.map((item) => item.id);
            const filterData = list
              .filter((item) => filterIds.includes(item.id))
              .map((item) => item);
            // const data=tmpData.filter()
            setList(filterData);

            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
        
          //message.info("No records found.");
          setList(
            response?.data?.data?.map((item) => ({
              ...item,
              moduleName: "Integration",
              key: item.id,
            })) || []
          );
          setTotalRecords(response?.data?.totalCount || 0);
        }
        console.log("response-filter-result", response);
      } else {
        
     

        setList(list);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setFilterSubmit(false);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Layout className="layout_modelbx2">
        <Layout.Content className="border-right layout-modelbx-lpnl">
          <div className="app-list">
            <div className="popup_headerbx">
              <div className="inputsec_bx">
                <Typography.Title level={4} className="text-start">
                  {heading + " List"}
                </Typography.Title>
              </div>

              <div className="bulk-operation">
                <div className="formctrl">
                  <Form
                    form={changeTypeForm1}
                    // labelCol={{ span: 12 }}
                    // wrapperCol={{ span: 16 }}
                    initialValues={{}}
                    className=" "
                    onFinish={async (formData) => {
                      if (selectedTableRows.length) {
                        const selectedRows = [];
                        selectedTableRows.forEach((itm) => {
                          selectedRows.push(itm.id);
                        });

                        //setselectedItemIDRows(selectedRows);
                        formData.changeType = "";
                        formData.selecteID = scenarioId;
                        formData.selectedRows = selectedRows;

                        SaveIntegrationData(formData);
                        //onFormSubmit(formData);

                        setIntegrationModal(false);
                      } else {
                        message.error("Select atleast one record ");
                      }
                    }}
                  >
                    <div className="inputbx">
                      <Button
                        type={`primary`}
                        htmlType="submit"
                        loading={loading}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <Spin
            spinning={portfolioloading || loading}
            className="loading_bx"
            size="small"
          >
            <div
              className="overflow-y int_listview_bx"
              style={{ height: window.innerHeight / 1.5 }}
            >
              <Delayed>
                <TableFormData
                  columns={columns}
                  list={list}
                  setSelectedTableRows={setSelectedTableRows}
                  selectedTableRows={selectedTableRows}
                  setselectedItemIDRows={setselectedItemIDRows}
                />
              </Delayed>
            </div>
          </Spin>
        </Layout.Content>
        <Layout.Sider
          theme={"light"}
          className="popup_rightpnlbx"
          width={showFilter2 ? 240 : 50}
        >
          <FilterRightSideBar
            activeGridStyle={1}
            showFilter={showFilter2}
            setShowFilter={setShowFilter2}
            portfolioData={filterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={(value) => {
              setPage(1);
              setSelectedFilters(value);
            }}
            optionalLevel={
              selectedModuleItems?.name ===
              staticSettingPortfolioList.businessCapability
            }
            allowSlider={true}
            //Filter relations
            filterSubmit={filterSubmit}
            setFilterSubmit={setFilterSubmit}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
          />
        </Layout.Sider>
      </Layout>
      {/* <div className="d-flex justify-content-between modelpopup_bx">
        <div id="portfolioList" className="w-100 container-fluid border-right modelpopup_listbx" >
          <div className="p-2">
            <div className="app-list">
              <div className="popup_headerbx">
                <div className="inputsec_bx">
                  <Typography.Title level={5} className="text-start py-2 mb-2 step-header header-size">
                    {heading + " List"}
                  </Typography.Title>

                </div>

                <div className="bulk-operation">
                  <div className="formctrl">
                    <Form
                      form={changeTypeForm1}
                      // labelCol={{ span: 12 }}
                      // wrapperCol={{ span: 16 }}
                      initialValues={{}}
                      className=" "
                      onFinish={async (formData) => {
                        if (selectedTableRows.length) {
                          const selectedRows = [];
                          selectedTableRows.forEach((itm) => {
                            selectedRows.push(itm.id);
                          });

                          //setselectedItemIDRows(selectedRows);
                          formData.changeType = "";
                          formData.selecteID = scenarioId;
                          formData.selectedRows = selectedRows;

                          SaveIntegrationData(formData);
                          //onFormSubmit(formData);

                          setIntegrationModal(false);
                        } else {
                          message.error("Select atleast one record ");
                        }
                      }}
                    >
                      <div className="inputbx">

                        <Button
                          type={`primary`}
                          htmlType="submit"
                          loading={loading}
                        >
                          Add
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <Spin spinning={portfolioloading || loading}>
              <div
                className="overflow-y"
                style={{ height: window.innerHeight / 2 }}
                // onScroll={handleScroll}
              >
                <TableFormData
                  columns={columns}
                  list={list}
                  setSelectedTableRows={setSelectedTableRows}
                  selectedTableRows={selectedTableRows}
                  setselectedItemIDRows={setselectedItemIDRows}
                />
              </div>
            </Spin>
          </div>
        </div>
        
        <div className="modelpopup_filterbx">
          <FilterRightSideBar
            activeGridStyle={1}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            portfolioData={filterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={(value) => {
              setPage(1);
              setSelectedFilters(value);
            }}
            optionalLevel={
              selectedModuleItems?.name ===
              staticSettingPortfolioList.businessCapability
            }
            allowSlider={true}
            //Filter relations
            filterSubmit={filterSubmit}
            setFilterSubmit={setFilterSubmit}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
          />
        </div> 
      
      </div> */}
    </React.Fragment>
  );
};

IntegrationList.propTypes = {};

export default IntegrationList;

const TableFormData = ({ columns, list, setSelectedTableRows }) => {
  const [spinning, setSpinning] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedTableRows([]);
    // setSelectedRowKeys([]);
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableRows(selectedRows);
      //setSelectedRowKeys(selectedRows);
    },
  };

  return (
    // <Spin spinning={spinning}>
    <Table
      rowClassName={() => "editable-row"}
      className="mb-2 bulk-entry-table"
      bordered
      dataSource={list}
      columns={columns}
      size="small"
      // scroll={{ y: window?.innerHeight - 200, x: window?.innerWidth - 300 }}
      scroll={{ x: true, y: true }}
      rowSelection={{
        type: selectionType,
        ...rowSelection,
      }}
    />
    // </Spin>
  );
};
