import React, { createContext, useContext, useReducer } from "react";
const DiagramApplicationContext = createContext();
export const useAppndiagram = () => useContext(DiagramApplicationContext);

const initialState = {
  diagrams: [],
  loading: false,
  error: false,
  businessApplicationId: null,
  businessApplicationModel: "",
};

const diagramReducer = (state, action) => {
  switch (action.type) {
    case "SET_DIAGRAM":
      // Filter out duplicate links by id
      const uniqueDiagrams = action.payload.reduce((acc, link) => {
        // Check if the link id already exists in the state
        if (!state.diagrams.some((item) => item.id === link.id)) {
          acc.push(link);
        }
        return acc;
      }, []);
      return {
        ...state,
        diagrams: [...state.diagrams, ...uniqueDiagrams],
      };
    case "ADD_DIAGRAM":
      return {
        ...state,
        diagrams: [...state.diagrams, action.payload],
      };
    case "UPDATE_DIAGRAM":
      return {
        ...state,
        diagrams: state.diagrams.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_DIAGRAM":
     
      return {
        ...state,
        diagrams: state.diagrams.filter((item) => item.id !== action.payload.id),
       
      };
    case "EMPTY_DIAGRAM":
      return {
        ...state,
        diagrams: [],
        loading: false,
        error: false,
        businessApplicationId: null,
        businessApplicationModel: "",
      };
    case "SET_BUSINESSAPPLICATIONID":
      return {
        ...state,
        businessApplicationId: action.payload,
      };
    case "SET_BUSINESS_APPLICATION_MODEL":
      return {
        ...state,
        businessApplicationModel: action.payload,
      };
    default:
      return state;
  }
};

export const DiagramProvider = ({ children }) => {
  const [state, dispatch] = useReducer(diagramReducer, initialState);

  return (
    <DiagramApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </DiagramApplicationContext.Provider>
  );
};
