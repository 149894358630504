import React, { useEffect, useState, useRef } from "react";
import { Spin, message, Pagination, Modal, Checkbox, Button } from "antd";
import {
    UnorderedListOutlined,
    PlusOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useBusinessApplication } from "./../../../Context/businessApplication";
import { usePortfolioSetting } from "./../../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "./../../../hooks/usePortfolioFetcher";
import { staticSettingPortfolioList } from "./../../../utils/settingCommon";
import {
    debounceTimeOut,
    deepFindByName,
    default_pageSize,
    default_pageSizeOptions,
    getSelectProperties,
    removeEmptyArrObjOrval,
    removeEmptyKeys,
} from "./../../../utils/Common";
import { useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import ApplicationCapabilityList from "../../applicationlist/ApplicationCapabilityList";
import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
import useDebounce from "../../../helper/useDebounce";
import useFetchPortfolioTag from "../../../hooks/useFetchPortfolioTag";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import BusinessApplicationServices from "../../../services/services/BusinessApplicationService";
import { UpdateChangeLog } from "../../../utils/ChangeLogs";
import { SaveRecentlyView_Portfolio, RemoveRecentViewed } from "../../../utils/ResentViewedPortfolio";
import { useMenu } from "../../../Context/MenuProvider";
import UpdateGlobalState from "../common/UpdateGlobalState";
import { useLocation } from "react-router";
import EditPortfolioItem from "../common/EditPortfolioItem";



const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortValues = [
    { value: "displayname", title: "Name", },
    { value: "id", title: "ID", },
];

const BusinessApplicationList = ({ HeaderContent, activePage: activeGridStyle, deleteStart, setDeleteStart, setBulkDeleteButton, setFilterOptions, setBulk_Delete_Ids, ...props }) => {
    const ref = useRef(null);
    const location = useLocation();
    const { updateLocale } = UpdateGlobalState();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const navigate = useNavigate();
    const { state, dispatch } = useBusinessApplication();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const [showFilter, setShowFilter] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({...location?.state?.filter,});
    const [swimLaneSelectedFilters, setSwimLaneSelectedFilters] = useState({});
    let { moduleName: moduleNamePath } = useParams();
    const { stateMenu, dispatchMenu } = useMenu();
    // Submit filter state it should work only apply is clicked
    const [filterSubmit, setFilterSubmit] = useState(false);
    const [selectedRelationFilters, setSelectedRelationFilters] = useState({});

    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [editPortfolio, setEditPortfolio] = useState(null);

    useEffect(() => {
        // console.log("testing portfolio two state location", location);
        if (location?.state) {
            // console.log('testing portfolio one', "page", location?.state?.page, "limit", location?.state?.limit);
            setPage(location?.state?.page)
            setLimit(location?.state?.limit)
        }
    }, [location?.state])

    const getPageNumber = () => {
        let paze;
        //console.log("testing location?.state?.page",location?.state?.page );

        if (location?.state?.page) {
            paze = location?.state?.page
        } else {
            paze = page
        }
        return paze
    }

    const getPageLimit = () => {
        let limitz;
        //console.log("testing location?.state?.page",location?.state?.page );

        if (location?.state?.limit) {
            limitz = location?.state?.limit
        } else {
            limitz = limit
        }
        return limitz
    }

    useEffect(() => {
        if (bulkUpdateIds.length > 0) {
            setBulkDeleteButton(true)
        } else {
            setBulkDeleteButton(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkUpdateIds])

    useEffect(()=>{
        props.setLists(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[list]);

    // useEffect(() => {
    //   fetchTagsData();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // Get all tags
    // const fetchTagsData = async () => {
    //   if (ref.current) {
    //     ref.current.continuousStart();
    //   }
    //   try {
    //     setLoading(true);
    //     const response = await BusinessApplicationServices.getAllTagsList();
    //     console.log("response-tags-list", response.data.data);
    //     const newData = response.data.data;
    //     await dispatch({ type: "SET_ALL_TAGS", payload: newData });
    //   } catch (error) {
    //     setLoading(false);
    //     console.error("Error fetching data:", error);
    //   }
    // };

    const setItem = (item, type) => {
        if (type.type === "edit") {
        } else {
            showConfirm(item);
        }
    };

    const showConfirm = (item) => {
        let id = item?.id;
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    const result =
                        await BusinessApplicationServices.deleteBusinessApplication(
                            id,
                        );
                    if (result?.data) {
                        let obj = {
                            id: localStorage.getItem("id"),
                            NameInSourceSystem: "",
                            DisplayName: localStorage.getItem("DisplayName"),
                            Description: "",
                            LevelID: "",
                        };

                        let field_OldData = {
                            DisplayName: item?.displayname || "",
                            Description: item?.description || "",
                            LevelID: item?.levelid || "",
                        };

                        await UpdateChangeLog(
                            obj.id,
                            field_OldData,
                            obj,
                            "Delete",
                            stateMenu?.MenuID?.moduleName,
                        );

                        //Remove From Home Screen 
                        RemoveRecentViewed({ modelName: "", modelID: obj.id });

                        const filterdIds = bulkUpdateIds.filter((value) => value !== id)
                        setBulkUpdateIds(filterdIds)

                        setList([]);
                        fetchFilteredData(selectedFilters, page, limit);
                        setTotalRecords(0);
                        message.success(result?.data?.msg);
                        // setId(null)
                    }
                } catch (error) {
                    message.error(error?.message);
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const fetchFilteredData = async (filter, pageNumber, pageLimit) => {
        try {
            setLoading(true);
            if (ref.current) { ref.current.continuousStart() }
            if (portfolioSettingState?.data?.length > 0) {
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.application,
                    false,
                );
                let updatedFilter = {
                    moduleName: portfolio?.moduleName || "",
                    ...filter,
                };
                updatedFilter = removeEmptyKeys(updatedFilter);
                const response =
                    await PortfolioSettingServices?.getFilteredPortfolios(
                        updatedFilter,
                        pageNumber,
                        pageLimit
                    );
                if (response?.data?.data?.length) {
                    setTotalRecords(response?.data?.totalCount);
                    setList(response?.data?.data);
                    updateLocale({ key: portfolio?.moduleName, items: response?.data?.data })
                } else {
                    //message.info("No records found.");
                    setTotalRecords(response?.data?.totalCount || 0);
                    setList(response?.data?.data);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
            setFilterSubmit(false);
            if (ref.current) { ref.current.complete() }
        }


    };

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

    useEffect(() => {
        const pageNumber = getPageNumber()
        const pageLimit = getPageLimit()
        fetchFilteredData(selectedFilters, pageNumber, pageLimit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    //Get portfolios
    //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(
                portfolioSettingDispatch,
                1,
                100,
            );
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (portfolioSettingState?.data?.length > 0) {
            // console.log('testing comming module name', moduleNamePath);
            const portfolioValues = deepFindByName(
                portfolioSettingState?.data,
                staticSettingPortfolioList?.application,
                false,
            );
            if (portfolioValues?.portfolioSections?.length) {
                if (portfolioValues?.moduleName) {
                    dispatchTag({ type: "EMPTY_TAGS" });
                    dispatchTag({
                        type: "SET_MODULE_NAME",
                        payload: portfolioValues?.moduleName,
                    });
                }
                setPortfolioData(
                    getSelectProperties(
                        portfolioValues?.portfolioSections,
                        true,
                    ),
                );
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    //Fetch tags items
    const fetchAllPortfolioTags = async () => {
        try {
            await fetchTagsData();
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (stateTag?.moduleName) {
            fetchAllPortfolioTags();
        }
    }, [stateTag?.moduleName]);

    useEffect(() => {
        if (filterSubmit) {
            fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter), page, limit);
        }
        setFilterOptions({ ...removeEmptyArrObjOrval(debouncedFilter), page: page, limit: limit });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSubmit, debouncedFilter, page, limit]);

    useEffect(() => {
        if (deleteStart === true && bulkUpdateIds.length > 0) {
            Modal.confirm({
                title: 'Are you sure want to delete?',
                icon: <ExclamationCircleFilled />,
                async onOk() {
                    try {
                        let result;
                        result =
                            await PortfolioSettingServices.delete_bulk_Portfolio_ids(
                                {
                                    data: {
                                        id: bulkUpdateIds,
                                        moduleName: moduleNamePath
                                    }
                                }
                            );
                        console.log('testing results', result);

                        if (result) {
                            // let obj = {
                            //     id: localStorage.getItem("id"),
                            //     NameInSourceSystem: "",
                            //     DisplayName: localStorage.getItem("DisplayName"),
                            //     Description: "",
                            //     LevelID: "",
                            // };

                            // let field_OldData = {
                            //     DisplayName: item?.displayname || "",
                            //     Description: item?.description || "",
                            //     LevelID: item?.levelid || "",
                            // };

                            // await UpdateChangeLog(
                            //     obj.id,
                            //     field_OldData,
                            //     obj,
                            //     "Delete",
                            //     stateMenu?.MenuID?.moduleName,
                            // );

                            //Remove From Home Screen 
                            //RemoveRecentViewed( {modelName:"",  modelID:id});

                            //     setLists([]);

                            setDeleteStart(false)
                            setBulkUpdateIds([])
                            fetchFilteredData(selectedFilters, page, limit);
                            //     setTotalRecords(0);
                            //     message.success(result?.data?.msg);
                            message.success("Deleted Successfully");
                            // setId(null)
                        } else {
                            message.error(result?.data?.msg);
                        }
                    } catch (error) {
                        console.log(error);

                        message.error(error?.message);
                    }
                },
                onCancel() {
                    console.log("Cancel");
                    setDeleteStart(false)
                },
            });
        }
    }, [deleteStart === true])

    const onCheckboxChange = (e) => {
        let { value, checked } = e?.target;
        let ary = [...bulkUpdateIds];
        if (checked) {
            ary.push(value);
        } else {
            ary = ary.filter(f => f?.toString() !== value?.toString())
        }
        setBulkUpdateIds(ary);
        props.setBulkUpdateIds(ary);
    }

    const create_duplicate = async (data) => {
        try {
            setLoading(true)
            //const levelValue = await PortfolioSettingServices.getMaxLevelId({"moduleName":"apqcs_applications","levelid":data.levelid})
            const obj = {
                moduleName: moduleNamePath,
                id: data.id
            }
            let result = await PortfolioSettingServices.duplicatePortfolioItem(obj)
            if (result.status == "201") {
                setTimeout(() => {
                    navigate(`/portfolio/${moduleNamePath}/${result.data.portfolio_item.id}`);
                }, 1000);
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(()=>{
        setBulkUpdateIds(props?.bulkUpdateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.bulkUpdateIds]);

    return (
        <>
            <LoadingBar color="#1f1f1f" ref={ref} />
            <div className="applicationlist-page">
                <div className="left_pnlbx">
                    <div className={`container-fluid portfolio_listview ${editPortfolio?'edit-enabled-list':''}`}>
                        {HeaderContent}
                        <div className="mr-3 mt-2">
                            <Spin className="loading_bx" size="small" spinning={loading}>
                                {/* {
                                    list?.length>0 && 
                                    <Checkbox onChange={(e)=>{onCheckAllChange(e?.target?.checked)}} className="pl-2 d-none" ref={props?.selectAllRef} checked={selectAllListChecked}>
                                        Select all
                                    </Checkbox>
                                } */}
                                <Checkbox.Group style={{ width: '100%' }} value={bulkUpdateIds}>
                                    <ApplicationCapabilityList
                                        setItem={setItem}
                                        data={list || []}
                                        moduleName={moduleNamePath}
                                        lifeCycleStagesColors={
                                            lifeCycleStagesColors
                                        }
                                        showFilter={showFilter}
                                        loading={loading}
                                        basePath={location?.pathname}
                                        isListCheckbox={true}
                                        onCheckboxChange={onCheckboxChange}
                                        page={page}
                                        limit={limit}
                                        create_duplicate={create_duplicate}
                                        setEditPortfolio={setEditPortfolio}
                                    />
                                </Checkbox.Group>
                                {totalRecords > 0 && (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span className="mt-2" style={{ fontSize: 14 }}> {bulkUpdateIds?.length} Selected {`${bulkUpdateIds?.length > 1 ? "Items" : "Item"}`} </span>
                                        <Pagination
                                        simple
                                            className="mt-2 text-right"
                                            total={totalRecords}
                                            showTotal={(total) =>
                                                `Total ${total} Items`
                                            }
                                            defaultPageSize={limit || default_pageSize}
                                            defaultCurrent={page}
                                            pageSizeOptions={
                                                default_pageSizeOptions
                                            }
                                            showSizeChanger
                                            onChange={(p, ps) => {
                                                setFilterSubmit(true);
                                                setLimit(ps);
                                                setPage(p);
                                            }}
                                        />
                                    </div>
                                )}
                            </Spin>
                        </div>
                    </div>
                    <div className={`editerview_bx ${editPortfolio?'w-100':''}`}>
                        {
                            editPortfolio && <React.Fragment>
                                <EditPortfolioItem id={editPortfolio?.id} editDetails={editPortfolio} moduleName={moduleNamePath} />
                            </React.Fragment>
                        }
                    </div>
                </div>
                <FilterRightSideBar
                    activeGridStyle={activeGridStyle}
                    lifeCycleStagesColors={lifeCycleStagesColors}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    portfolioData={portfolioData}
                    selectedFilters={selectedFilters}
                    sortValues={sortValues}
                    // setSelectedFilters={setSelectedFilters}
                    setSelectedFilters={(value) => { setSelectedFilters(value) }}
                    optionalLevel={false}
                    setSwimLaneSelectedFilters={setSwimLaneSelectedFilters}
                    //Filter relations
                    filterSubmit={filterSubmit}
                    setFilterSubmit={(value) => { setFilterSubmit(value) }}
                    selectedRelationFilters={selectedRelationFilters}
                    setSelectedRelationFilters={setSelectedRelationFilters}
                />
            </div>
        </>
    );
};

BusinessApplicationList.defaultProps = {
    HeaderContent: () => { }
}

export default BusinessApplicationList;
