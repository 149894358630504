import { Route, Routes } from "react-router-dom";
import NoMatch from "../components/NoMatch";
import BusinessApplicationLandscapeGrid from "../components/reports/BusinessApplicationLandscapeGrid";

const AnonymousRoutes = () => (
    <>
        <Routes>
            <Route path="*" element={<NoMatch />} />
            <Route path="/iframe-application-landscape" element={<BusinessApplicationLandscapeGrid />} />
        </Routes>
    </>
)

export default AnonymousRoutes