import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Collapse,
  Tooltip,
  Flex,
  Modal,
  message,
  Spin,
  Pagination,
  DatePicker,
  Form,
  Input,
  Checkbox,
  Divider,
  Table,
} from "antd";
import {
  CloseSquareFilled,
  ScheduleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  convertToNormalText,
  dynamicRoutesLinks,
} from "../../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../../utils/navigateUtils";

import DeleteSvg from "../../../assets/Remove.svg";
import EditSvg from "../../../assets/Edit.svg";
import Track from "../../../assets/Track.svg";
import moment from "moment";
import dayjs from "dayjs";
import ScenarioItemsServices from "../../../services/services/ScenarioItemsService";
import {
  getMenuPortfolio,
  staticSettingPortfolioList,
} from "../../../utils/settingCommon";
import { useNavigate, useParams } from "react-router";
import "./../Scenario.scss";
import BusinessApplicationListCards from "../../applicationlist/BusinessApplicationListCards";
import FormFields from "../../../utils/FormFields";

import {
  debounceTimeOut,
  deepFindByName,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  PageTitleHeading,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
  transformText,
} from "../../../utils/Common";

import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
import PortfolioRelationshipServices from "../../../services/services/PortfolioRelationshipService";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import useDebounce from "../../../helper/useDebounce";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
//import ScenarioListSelection from "../portfolio/ScenarioListSelection";
import NewScenarioServices from "../../../services/services/NewScenarioServices";
import ScenarioListSelection from "./ScenarioListSelection";
import ScenarioPortfolioItemImport from "./ScenarioPortfolioItemImport";
import ViewDifferences from "./ViewDifferences";

function ScenarioPlanningList({
  data,
  scenarioId,
  getAllScenariosItems,
  getRemovedPortfolioItems,
  ctrlStatus,
}) {
  const [scenarioItems, setScenarioItems] = useState([]);
  const [activePanel, setactivePanel] = useState([]);
  const [activePanelPortfolio, setactivePanelPortfolio] = useState([]);
  const [collapsedPortfolio, setCollapsedPortfolio] = useState({});
  const [collapsedSections, setCollapsedSections] = useState({});
  const [activeIntegrationPanel, setActiveIntegrationPanel] = useState(1);
  const navigateTo = useDynamicNavigate();
  const { Panel } = Collapse;
  const navigate = useNavigate();

  const [moduleNameTemp, setmoduleNameTemp] = useState("");
  const [moduleName, setmoduleName] = useState();
  const [showApplicationListModal, setShowApplicationListModal] =
    useState(false);
  const [listApplication, setListApplication] = useState([]);

  const [filterApplication, setFilterApplication] = useState([]);
  const [searchTextApplication, setSearchTextApplication] = useState("");
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showListModal, setShowListModal] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [page, setPage] = useState(1);
  const [addedData, setAddedData] = useState([]);
  const [removedData, setRemovedData] = useState([]);
  const [limit, setLimit] = useState(default_pageSize);

  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
  const [freeText, setfreeText] = useState("Data Not Found");
  const [showFilter, setShowFilter] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [initialValue, setInitialValue] = useState({});

  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const [loading, setLoading] = useState(false);
  const [selectedItemIDRows, setselectedItemIDRows] = useState([]);
  const [scenarioModal, setScenarioModal] = useState(false);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [copyScenarioID, setCopyScenarioID] = useState();
  const [scenarioIntegrationImportModal, setScenarioIntegrationImportModal] =
    useState(false);

  const [changeTypeModal, setChangeTypeModal] = useState(false);
  const [changeTypeForm] = Form.useForm();
  const [changeTypeFormData, setChangeTypeFormData] = useState({});
  const [changeTypeLoading, setChangeTypeLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState([]);
  const [portfolioID, setPortfolioID] = useState([]);
  const [editFlag, setEditFlag] = useState(false);

  const [scenarioPortfolioItemModal, setScenarioPortfolioItemModal] =
    useState(false);

  const [scenarioHeader, setScenarioHeader] = useState();
  const [portfolioItemLoading, setPortfolioItemLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [listsScenarioPortfolioItem, setListsScenarioPortfolioItem] = useState(
    []
  );

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [importButtonStatus, setImportButtonStatus] = useState(false);
  const [loadingButtonState, setLoadingButtonState] = useState(false);
  const [showViewDiffenenceModal, setShowViewDiffenenceModal] = useState(false);
  const [viewDiffModelName, setViewDiffModelName] = useState();
  const [scenarioItemId, setScenarioItemId] = useState(0);
  const [sourceID, setSourceID] = useState(0);
  const [id, setID] = useState(0);
  const [scenarioPortfolioItem, setScenarioPortfolioItem] = useState([]);

  const userID = localStorage.getItem("email");

  useEffect(() => {
    //if (data?.length > 0) {
    setScenarioItems(data);
    //}
  }, [data]);

  useEffect(() => {
    if (moduleNameTemp) {
      getBusinessPortfolioList_Modulewise(moduleNameTemp, moduleNameTemp);
    }
  }, [page, limit]);

  const portfolioDataList = useMemo(() => {
    // console.log("portfolioSettingState?.data ", portfolioSettingState?.data);

    if (portfolioSettingState?.data?.length && moduleName) {
      const portfolioValues = deepFindByName(
        portfolioSettingState?.data,
        moduleName,
        false
      );
      if (portfolioValues?.portfolioSections?.length) {
        if (portfolioValues?.moduleName) {
          dispatchTag({ type: "EMPTY_TAGS" });
          dispatchTag({
            type: "SET_MODULE_NAME",
            payload: portfolioValues?.moduleName,
          });
        }
        setSelectedFilters({});
        setShowFilter(false);
        return getSelectProperties(portfolioValues?.portfolioSections, true);
      }
    }

    // setLoading(false);
    // setPortfolioSettingData(portfolioSettingState?.data);
    // setSelectedCollapse();

    return [];
  }, [moduleName, portfolioSettingState?.data]);

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);

      if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
        // setList([])
        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          moduleName,
          false
        );
        const updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          page,
          limit
        );
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setListApplication(
              response?.data?.data?.map((item) => ({
                ...item,
                moduleName:
                  transformText(
                    portfolio?.moduleName.replace("_", " "),
                    "pascal"
                  ) ||
                  transformText(moduleName.replace("_", " "), "pascal") ||
                  moduleName,
              })) || []
            );
            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
          //message.info("No records found.");
          setfreeText("No items found.");
          setListApplication(
            response?.data?.data?.map((item) => ({
              ...item,
              moduleName:
                transformText(
                  portfolio?.moduleName.replace("_", " "),
                  "pascal"
                ) ||
                transformText(moduleName.replace("_", " "), "pascal") ||
                moduleName,
            })) || []
          );
          setTotalRecords(response?.data?.totalCount || 0);
        }
      } else {
        setListApplication(listApplication);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setFilterSubmit(false);
      setLoading(false);
    }
  };

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
  }, [filterSubmit, debouncedFilter]);

  const handlePanelChange = useCallback((activeKeys) => {
    setactivePanel(activeKeys);
    localStorage.setItem("activePanelConnection", activeKeys);
  }, []);

  useEffect(() => {
    if (changeTypeModal) {
      changeTypeForm.setFieldsValue(initialValue);
    }
  }, [changeTypeModal, initialValue, changeTypeForm]);

  //Remove Portfolio from Scenario
  const deletePortfolio = async ({ scenarioid, portfolioid }) => {
    try {
      const response =
        await ScenarioItemsServices.deletescenarioportfoliobyidv3({
          scenarioid,
          portfolioid,
        });

      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        getAllScenariosItems();
        getRemovedPortfolioItems();
        message.success("Portfolio removed successfully.");
      } else {
        message.error("Items not found.");
      }
    } catch (err) {
      console.error("Error while removing portfoliofrom scenario:", err);
    }
  };

  const onDynamicLinkClick = (id = null, moduleName = "", rest = {}) => {
    const redirectLink = dynamicRoutesLinks?.find(
      (item) => item?.type === moduleName
    );

    if (redirectLink?.link && redirectLink?.moduleName && id) {
      const newLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
      navigateTo(newLink, true);
    }
  };

  const onClickNewScenario = ({ portfolioType }) => {
    if (portfolioType) {
      let portfolio = getMenuPortfolio({ moduleName: portfolioType });

      let link = `${portfolio?.route}/${scenarioId}/add`;
      navigate(link, { state: { scenarioId } });
    }
  };

  const onClickEditScenario = ({ portfolioType, id }) => {
    if (portfolioType) {
      let portfolio = getMenuPortfolio({ moduleName: portfolioType });

      let link = `${portfolio?.route}/${scenarioId}/${id}`;
      navigate(link, { state: { scenarioId } });
    }
  };

  const WithChangeType = ({
    action,
    displayname,
    sourceID,
    id,
    moduleName,
  }) => {
    // const checkOperation = action
    //   ? 2
    //   : version
    //   ? 3
    //   : changeTypeId === 1
    //   ? 1
    //   : changeTypeId === 4
    //   ? 4
    //   : 5;
    return (
      <>
        <div className="fw-medium scenario_opt">
          {action === "New" ? (
            <span className="scenario-new">New</span>
          ) : action === "Deleted" ? (
            <span className="scenario-removed">Remove</span>
          ) : action === "Modified" ? (
            <>
              <div className="app-buttons">
                <span className="scenario-modified">Modified</span>
                <span
                  className="scenario-view-diffrenece"
                  onClick={() => {
                    setShowViewDiffenenceModal(true);
                    setViewDiffModelName(displayname);
                    setSourceID(sourceID);
                    setID(id);
                    setmoduleNameTemp(moduleName);
                  }}
                >
                  View Difference
                </span>
              </div>
            </>
          ) : action === "No Change" ? (
            <span className="scenario-nochange">No Change</span>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setselectedItemIDRows([]);
    } else {
      const allIds = [];
      listsScenarioPortfolioItem?.map((row) => {
        const id = row.id?.toString();
        if (id && !scenarioPortfolioItem?.includes(id)) {
          allIds.push(row.id?.toString());
        }
      });
      setSelectedRows(allIds);
      setselectedItemIDRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const onCheckboxChange = (e) => {
    let { value, checked } = e?.target;
    let ary = [...selectedRows];

    if (checked) {
      ary.push(value.toString());
    } else {
      ary = ary.filter((f) => f?.toString() !== value?.toString());
    }
    setSelectedRows(ary);
    setselectedItemIDRows(ary);

    if (ary?.length !== listsScenarioPortfolioItem?.length) {
      setSelectAll(false);
    }
  };

  useEffect(() => {
    // console.log("Selected ", selectedRows);
  }, [selectedRows]);

  const handleAddRelationship = async ({ id, moduleName }) => {
    try {
      setLoadingButtonState(true);

      const obj = {
        moduleName: moduleNameTemp,
        scenarioId: parseInt(scenarioId, 10),
        sourceItemId: parseInt(id, 10),
        Action: "No Change",
        userID: userID,
      };

      const response = await NewScenarioServices.ImportASIS(obj);

      if (response.status === 201) {
        getAllScenariosItems();
        message.success("Imported Successfully");
        setLoadingButtonState(false);
      } else {
        console.log(response.error);
      }
    } catch (err) {}
  };

  const handleRemoveRelationship = async ({ id, moduleName, uniqueid }) => {
    try {
      const obj = {
        moduleName: moduleNameTemp,
        scenarioId: parseInt(scenarioId, 10),
        sourceItemId: parseInt(id, 10),
        Action: "Deleted",
      };

      const response = await NewScenarioServices.ImportASIS(obj);

      if (response.status === 201) {
        getAllScenariosItems();
        message.success("Removed Successfully");
      } else {
        console.log(response.error);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getBusinessPortfolioList_Modulewise = async ({
    moduleName,
    modelName,
  }) => {
    if (!moduleName) {
      moduleName = moduleNameTemp;
    }
    setListApplication([]);
    
    setfreeText("Fetching Data....");
    try {

      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        page: page,
        limit: limit,
      });

      const recordLists = list?.data?.data || [];
      const transformedData =
        recordLists.map((item) => ({
          ...item,
          moduleName:
            transformText(moduleName.replace("_", " "), "pascal") || modelName,
        })) || [];

      setListApplication(transformedData);
      setFilterApplication(transformedData);
      setTotalRecords(list?.data?.totalCount || 0);
      setDataLoading(false);

      if (recordLists.length < 1) {
        setfreeText("No items found.");
      }
    } catch (err) {
      console.error("Error fetching module data:", err);
      message.error("Failed to fetch module data");
    }
  };

  const importItem = ({ moduleName, sourceItemId }) => {
    console.log("importItem", moduleName, sourceItemId);
  };

  const removeItem = () => {};

  const AddUpdateComment = async (formData) => {
    try {
      let formValues = [];

      formValues = [
        {
          ...formData,
          scenarioId,
          id: scenarioItemId || null,
          itemId: selectedItemId,
          portfolioSettingsId: portfolioID,
        },
      ];

      const action = editFlag == false ? "Add" : "Update";

      //const action = "Add";

      const arrayFormValues = { items: formValues, action: action };

      if (!scenarioId) {
        return message.error("Scenario not found.");
      }

      //   const response =
      //     scenarioId === null || scenarioId === undefined
      //       ? await ScenarioItemsServices.createScenarioItem(arrayFormValues)
      //       : await ScenarioItemsServices.updateScenarioItem({
      //           arrayFormValues,
      //           id: scenarioId,
      //         });

      const response = await ScenarioItemsServices.createScenarioItem(
        arrayFormValues
      );
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        message.success("Updated Successfully.");

        setChangeTypeModal(false);

        //Refresh Data
        await getAllScenariosItems();

        setTimeout(() => {
          changeTypeForm.resetFields();
          //   portfolioTypeForm.resetFields();
          //   setOpenPortfolioList(false);

          setChangeTypeModal(false);

          //setOpenPortfolioList_changeType(false);
          //   setOpenChoosePortfolioType(false);
          setChangeTypeLoading(false);
        }, 1000);
      } else {
        message.error("Change type submitting failed.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setChangeTypeLoading(false);
    }
  };

  const deletePortfolioItem = async ({ moduleName, id }) => {
    const obj = {
      moduleName: moduleName,
      portfolioItemId: parseInt(id, 10),
    };

    const response = await NewScenarioServices.Delete(obj);

    if (response.status === 200) {
      getAllScenariosItems();
      message.success("Deleted successfully");
    } else {
      console.log(response.error);
    }
  };

  const ImportFromOtherScenario = async (data) => {
    try {
      const response =
        await NewScenarioServices.BulkcreateOnImportFromOtherScenarioSelectedItems(
          data
        );
      if (response?.status === 201) {
        //message.success(response?.data?.msg);
        message.success("Successfully Imported");
        getAllScenariosItems();

        setScenarioModal(false);

        setSelectedRows([]);
        setselectedItemIDRows([]);
        setSelectAll(false);

        setScenarioPortfolioItemModal(false);
        setImportButtonStatus(false);
      } else {
        message.error(response?.data?.msg);
      }
    } catch (err) {
      console.log("err:-", err);
    } finally {
      setImportButtonStatus(false);
    }
  };

  return (
    <>
      <div className="scenariolistbx">
        {
          //scenarioItems?.sort((a, b) => a.name - b.name).
          scenarioItems
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <Collapse
                key={Math.random()}
                expandIconPosition="start"
                bordered={true}
                className="settings-menus"
                activeKey={activePanelPortfolio}
                onChange={(activeKeys) => {
                  setCollapsedPortfolio(
                    activeKeys.reduce(
                      (acc, key) => ({ ...acc, [key]: false }),
                      {}
                    )
                  );
                  setactivePanelPortfolio(activeKeys);
                  localStorage.setItem("activePanelScenario", activeKeys);
                }}
                style={{
                  background: `${item?.color}`,
                  marginBottom: "20px",
                }}
              >
                <Panel
                  key={item?.id}
                  header={
                    <>
                      <div className="card-header">
                        <div className="d-flex align-items-center">
                          <div>
                            <h3
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                //console.log("item?.ID delete", item?.id);
                              }}
                            >
                              {item?.name} ({item?.Items?.length})
                            </h3>
                          </div>
                        </div>
                        <div className="btnbx">
                          <Tooltip title="Add New">
                            <Button
                              className="new-button"
                              ghost
                              type="primary"
                              disabled={!ctrlStatus}
                              onClick={() => {
                                onClickNewScenario({
                                  portfolioType: item?.moduleName,
                                });
                              }}
                            >
                              New
                            </Button>
                          </Tooltip>

                          <Tooltip title="Import from Master">
                            <Button
                              className="import-as-is-button"
                              ghost
                              type="primary"
                              disabled={!ctrlStatus}
                              onClick={() => {
                                //setScenarioModal(true);
                                setShowApplicationListModal(true);
                                setmoduleNameTemp(item?.moduleName);
                                setmoduleName(item?.name);
                                setPage(1);

                                setAddedData([]);
                                addedData.length = 0;

                                scenarioItems?.map((item1) => {
                                  if (item1.name === item?.name) {
                                    item1?.Items?.filter(
                                      (aa) => aa.sourceItemType !== "Deleted"
                                    )?.map((i) => {
                                      if (i.sourceItemId) {
                                        addedData.push(i.sourceItemId);
                                      }
                                    });
                                  }
                                });
                                setAddedData(addedData);

                                setRemovedData([]);
                                removedData.length = 0;
                                scenarioItems?.map((item1) => {
                                  if (item1.name === item?.name) {
                                    item1?.Items?.filter(
                                      (aa) => aa.sourceItemType == "Deleted"
                                    )?.map((i) => {
                                      if (i.sourceItemId) {
                                        removedData.push(i.sourceItemId);
                                      }
                                    });
                                  }
                                });
                                setRemovedData(removedData);

                                getBusinessPortfolioList_Modulewise({
                                  moduleName: item?.moduleName,
                                  modelName: item?.modelName,
                                });
                              }}
                            >
                              Import from Master
                            </Button>
                          </Tooltip>

                          <Tooltip title="Import from other Scenario">
                            <Button
                              className="import-other-scenario-button"
                              ghost
                              type="primary"
                              disabled={!ctrlStatus}
                              onClick={() => {
                                setmoduleName(item?.name);
                                setmoduleNameTemp(item?.moduleName);
                                setScenarioModal(true);

                                //getting data of clicked portfolio
                                const idArray = item?.Items?.map((item) =>
                                  item?.sourceItemId?.toString()
                                );
                                setScenarioPortfolioItem(idArray);
                              }}
                            >
                              Import from other Scenario
                            </Button>
                          </Tooltip>

                          <Button
                            icon={
                              <img src={DeleteSvg} alt="Delete" width={20} />
                            }
                            style={{ width: "30px" }}
                            shape={`circle`}
                            // type="primary"
                            disabled={!ctrlStatus}
                            onClick={() => {
                              Modal.confirm({
                                centered: true,
                                title: "Do you want to remove this portfolio?",
                                icon: <ExclamationCircleFilled />,
                                // content: 'Some descriptions',
                                onOk() {
                                  deletePortfolio({
                                    scenarioid: scenarioId,
                                    portfolioid: item?.id,
                                  });
                                },
                                onCancel() {
                                  // console.log('Cancel');
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                  style={{
                    background: `${item?.color}`,
                    padding: "5px",
                  }}
                >
                  <React.Fragment key={Math.random()}>
                    <div className="overflow-y-auto">
                      {item?.Items?.map((sItem, index) => {
                        const {
                          portfolioSettings,
                          id,
                          displayname,
                          comment,
                          deadline,
                          replace_with: replaceWith,
                          version,
                          change_type_id: changeTypeId,
                          sourceItemType,
                          scenario_item_id,
                          sourceItemId,
                        } = sItem;

                        return (
                          <Collapse
                            key={Math.random()}
                            expandIconPosition="start"
                            bordered={true}
                            className="settings-menus"
                            activeKey={activePanel}
                            onChange={(activeKeys) => {
                              setCollapsedSections(
                                activeKeys.reduce(
                                  (acc, key) => ({ ...acc, [key]: false }),
                                  {}
                                )
                              );
                              setactivePanel(activeKeys);
                              localStorage.setItem(
                                "activePanelScenario",
                                activeKeys
                              );
                            }}
                          >
                            <Panel
                              key={id}
                              header={
                                <div className="card-header">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <h4
                                        className="mb-0 cursor-pointer"
                                        onClick={() => {
                                          // const payload = {
                                          //   //displayname: portfolios?.displayname,
                                          //   displayname:item?.moduleName
                                          // };
                                          // onDynamicLinkClick(
                                          //   id,
                                          //   moduleName,
                                          //   payload
                                          // );
                                        }}
                                      >
                                        {displayname}
                                      </h4>
                                    </div>
                                    <WithChangeType
                                      action={sourceItemType}
                                      displayname={
                                        item?.name + ": " + displayname
                                      }
                                      sourceID={sourceItemId}
                                      id={id}
                                      moduleName={item?.moduleName}
                                    />
                                  </div>
                                  <div className="btnbx">
                                    <Tooltip title="Edit">
                                      <Button
                                        shape={`circle`}
                                        icon={
                                          <img
                                            src={EditSvg}
                                            alt={`Edit`}
                                            width={`20px`}
                                          />
                                        }
                                        {...(() => {
                                          if (
                                            sourceItemType === "Deleted" ||
                                            !ctrlStatus
                                          ) {
                                            return { disabled: true };
                                          } else {
                                            return { disabled: false };
                                          }
                                        })()}
                                        onClick={async () => {
                                          onClickEditScenario({
                                            portfolioType: item?.moduleName,
                                            id: id,
                                          });
                                        }}
                                      />
                                    </Tooltip>

                                    <Tooltip title="Add/Update Comment">
                                      <Button
                                        disabled={!ctrlStatus}
                                        shape={`circle`}
                                        icon={<ScheduleOutlined />}
                                        onClick={async () => {
                                          setScenarioItemId(scenario_item_id);

                                          if (!scenario_item_id) {
                                            setEditFlag(false);
                                          } else {
                                            setEditFlag(true);
                                          }

                                          setInitialValue({
                                            comment: "",
                                            deadline: null,
                                          });

                                          if (deadline) {
                                            let formattedDate =
                                              moment(deadline).format(
                                                "YYYY-MM-DD"
                                              );
                                            let deadLinetxt =
                                              dayjs(formattedDate);

                                            setInitialValue({
                                              comment: comment,
                                              deadline: deadLinetxt,
                                            });
                                          }

                                          setChangeTypeModal(true);
                                          setSelectedItemId(id);
                                          setPortfolioID(item.id);
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <Button
                                        shape={`circle`}
                                        disabled={!ctrlStatus}
                                        icon={
                                          <img
                                            src={DeleteSvg}
                                            alt={`Delete`}
                                            width={`20px`}
                                          />
                                        }
                                        onClick={async () => {
                                          Modal.confirm({
                                            centered: true,
                                            title:
                                              "Do you want to delete this portfolio item?",
                                            icon: <ExclamationCircleFilled />,
                                            // content: 'Some descriptions',
                                            onOk() {
                                              deletePortfolioItem({
                                                moduleName: item?.moduleName,
                                                id: id,
                                              });
                                            },
                                            onCancel() {
                                              // console.log('Cancel');
                                            },
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              }
                              style={{ width: "100%" }}
                              className="panel"
                            >
                              <div key={index} className="card">
                                <div className="card-body">
                                  <Row>
                                    <Col span={3}>
                                      <span className="fw-medium">
                                        Deadline{" "}
                                      </span>
                                      :
                                    </Col>
                                    <Col span={21}>
                                      {deadline &&
                                        moment(deadline).format("YYYY-MM-DD")}
                                    </Col>

                                    <Col span={3}>
                                      <span className="fw-medium">Comment</span>
                                      :
                                    </Col>
                                    <Col span={21}>
                                      {/* <ExpandableText text={comment} /> */}
                                      {comment}
                                    </Col>
                                  </Row>
                                  <div className="scenario_listbtn d-none">
                                    <div className="btnbx"></div>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        );
                      })}
                    </div>
                  </React.Fragment>
                  {/* </Card> */}
                </Panel>
              </Collapse>
            ))
        }
      </div>

      {/* Import portfolio Item from master  */}
      <Modal
        title={
          <div className="d-flex justify-content-between popup_headerbx">
            <div>
              <PageTitleHeading
                text={moduleName + " List"}
                className={`my-0`}
              />
            </div>
            <div></div>
          </div>
        }
        centered
        open={showApplicationListModal}
        onOk={() => setShowListModal(!showListModal)}
        onCancel={() => {
          setShowListModal(!showListModal);
          setPage(1);
          setLimit(default_pageSize);
          setSelectedFilters({});
          setSelectedRelationFilters({});
          setShowApplicationListModal(!showApplicationListModal);
          setLoadingButtonState(false);
        }}
        width={window.innerWidth - 400}
        footer={false}
        closeIcon={true}
      >
        <div className="d-flex justify-content-between modelpopup_bx">
          <div id="portfolioList" className="container-fluid popup_leftpnlbx">
            <Spin className="loading_bx" size="small" spinning={dataLoading}>
              <div className="overflow-y listview_bx">
                <BusinessApplicationListCards
                  cardModuleName={moduleName?.replace(" ", "")}
                  moduleName={moduleNameTemp}
                  filterLevel={filterLevel}
                  filterText={filterText}
                  filterBy={filterBy}
                  lists={listApplication}
                  addRemove={false}
                  setAddedData={setAddedData}
                  addedData={addedData}
                  handleAddRelationship={handleAddRelationship}
                  handleRemoveRelationship={handleRemoveRelationship}
                  dataLoading={dataLoading}
                  freeText={freeText}
                  addRemoveScenaioPortfolio={true}
                  removedData={removedData}
                  setRemovedData={setRemovedData}
                  importItem={importItem}
                  removeItem={removeItem}
                  loadingButtonState={loadingButtonState}
                />
              </div>
              {totalRecords > 0 && (
                <Pagination
                  className="mt-2 text-right"
                  total={totalRecords}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={default_pageSize}
                  defaultCurrent={page}
                  pageSizeOptions={default_pageSizeOptions}
                  showSizeChanger
                  onChange={(p, ps) => {
                    setFilterSubmit(true);
                    setLimit(ps);
                    setPage(p);
                  }}
                />
              )}
            </Spin>
          </div>
          <div className="popup_rightpnlbx">
            {/* filter  */}
            {/* <FilterRightSideBar
              activeGridStyle={1}
              selectedFilters={selectedFilters}
              portfolioData={portfolioDataList || []}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              allowSlider={false}
              optionalLevel={
                moduleName === staticSettingPortfolioList.businessCapability ||
                moduleName === staticSettingPortfolioList.businessProcess
              }
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setPage(1);
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              isRelationPopup={showApplicationListModal}
            /> */}

            <FilterRightSideBar
              activeGridStyle={1}
              selectedFilters={selectedFilters}
              portfolioData={portfolioDataList || []}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              allowSlider={false}
              // optionalLevel={
              //   moduleName === staticSettingPortfolioList.businessCapability ||
              //   moduleName === staticSettingPortfolioList.businessProcess
              // }
              optionalLevel={optionalLevelPortfoliosModule.includes(
                moduleNameTemp
              )}
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setPage(1);
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              isRelationPopup={showApplicationListModal}
              isRelationModuleName={moduleName}
            />
          </div>
        </div>
      </Modal>

      {/* Scenario List */}
      <Modal
        destroyOnClose
        title={false}
        header={false}
        open={scenarioModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setScenarioModal(false);
          //getAllScenariosItems();
          // setShowFilter(false);
          // setFilterData([]);
          // setOpenPortfolioList(false);
          // setSelectedModuleItems({});
          // setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
        centered
      >
        <div className="m-4 my-2">
          <ScenarioListSelection
            setselectedItemIDRows={setselectedItemIDRows}
            setShowFilter={setShowFilter}
            scenarioId={scenarioId}
            setScenarioModal={setScenarioModal}
            getAllScenariosItems={getAllScenariosItems}
            alreadySelectedRows={alreadySelectedRows}
            setAlreadySelectedRows={setAlreadySelectedRows}
            setCopyScenarioID={setCopyScenarioID}
            setActiveIntegrationPanel={setActiveIntegrationPanel}
            setScenarioIntegrationImportModal={
              setScenarioIntegrationImportModal
            }
            moduleName={moduleNameTemp}
            setScenarioPortfolioItemModal={setScenarioPortfolioItemModal}
            setScenarioHeader={setScenarioHeader}
            setListsScenarioPortfolioItem={setListsScenarioPortfolioItem}
            setPortfolioItemLoading={setPortfolioItemLoading}
          />
        </div>
      </Modal>

      {/* Capture Change Type */}
      <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={changeTypeModal}
        onCancel={() => {
          setInitialValue({
            comment: "",
            deadline: null,
          });

          changeTypeForm?.resetFields();

          // setChangeTypeLoading(false);
          // setChangeTypeName("");
          setChangeTypeModal(false);
          // setSelectedRows([]);
          // setselectedItemIDRows([]);
        }}
      >
        <Form
          form={changeTypeForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValue}
          className="mt-4"
          onFinish={async (formData) => {
            AddUpdateComment(formData);

            // await setChangeTypeLoading(true);
            // const changeTypeId = changeTypeData.find(
            //   (type) => type.name === changeTypeName
            // );
            // formData["changeType"] = changeTypeName;
            // formData["changeTypeId"] = changeTypeId.id;
            // onFormSubmit(formData);
            // setSelectedRows([]);
            // setselectedItemIDRows([]);
          }}
        >
          <FormFields
            type={`textarea`}
            label={`Comment`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />
          <Form.Item
            label={`Deadline`}
            name="deadline"
            formClassName={`mb-2`}
            key={`deadline`}
            rules={[
              {
                required: false,
                message: "Please select deadline",
              },
            ]}
            onChange={(value) => {
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["deadline"]: value,
              });
            }}
          >
            <DatePicker className="mb-2 text-start w-full" />
          </Form.Item>

          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            loading={changeTypeLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Import Scenario portfolio item */}
      <Modal
        destroyOnClose
        title={
          <div className="flex items-center justify-between popup_headerbx">
            <div className="select-all-container">
              <h2>{`Select ${moduleName}`}</h2>
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                style={{ margin: 10 }}
              />
              <span>Select All </span>
            </div>
            <div className="flex gap-2">
              {/* <Input.Search
                //placeholder={`Search ${modalType?.name}`}
                style={{ width: `100%` }}
                disabled={listsScenarioPortfolioItem?.length === 0 && !search}
                onSearch={(value) => setSearch(value)}
                name="search"
              /> */}

              <Button
                type="primary"
                loading={importButtonStatus}
                disabled={listsScenarioPortfolioItem?.length === 0}
                onClick={() => {
                  //handleOk();
                  setImportButtonStatus(true);
                  const objdata = {
                    Action: "No Change",
                    moduleName: moduleNameTemp,
                    scenarioId: parseInt(scenarioId),
                    SourcePortfolioItemIDs: selectedRows,
                    userID: userID,
                  };

                  if (selectedRows.length > 0) {
                    ImportFromOtherScenario(objdata);
                  } else {
                    message.error("Select atleast one item to import");
                    setImportButtonStatus(false);
                  }
                }}
                className="mr-8"
              >
                Submit
              </Button>
            </div>
          </div>
        }
        open={scenarioPortfolioItemModal}
        //onOk={handleOk}
        onCancel={() => {
          setScenarioPortfolioItemModal(false);
          setSelectedRows([]);
          setselectedItemIDRows([]);
          setSelectAll(false);
        }}
        width={window.innerWidth - 650}
        maskClosable={false}
        footer={false}
        zIndex={10000}
      >
        <div className="overflow-y listview_bx">
          <ScenarioPortfolioItemImport
            scenarioId={scenarioId}
            setScenarioPortfolioItemModal={setScenarioPortfolioItemModal}
            heading={scenarioHeader}
            portfolioItemLoading={portfolioItemLoading}
            setPortfolioItemLoading={setPortfolioItemLoading}
            moduleName={moduleNameTemp}
            listsScenarioPortfolioItem={listsScenarioPortfolioItem}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            scenarioPortfolioItem={scenarioPortfolioItem}
          />
        </div>
      </Modal>

      {/* View Differences */}
      <Modal
        title={
          <div className="d-flex justify-content-between popup_headerbx">
            <div>
              <PageTitleHeading
                text={viewDiffModelName + " Differences"}
                className={`my-0`}
              />
            </div>
            <div></div>
          </div>
        }
        centered
        open={showViewDiffenenceModal}
        //onOk={() => setShowListModal(!showListModal)}
        onCancel={() => {
          setShowViewDiffenenceModal(false);
        }}
        width={window.innerWidth - 400}
        footer={false}
        closeIcon={true}
      >
        <div className="d-flex justify-content-between modelpopup_bx">
          <div id="portfolioList" className="container-fluid popup_leftpnlbx">
            <div className="d-flex justify-content-between my-3">
              <div>
                <p>
                  Below is list of all portfolio entities that are showing
                  difference from master
                </p>
              </div>
              <div className="ml-auto"></div>
            </div>
            <ViewDifferences
              sourceID={sourceID}
              id={id}
              moduleName={moduleNameTemp}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ScenarioPlanningList;

const optionalLevelPortfoliosModule = [
  "apqcs_processes",
  "business_capability",
  "business_processes",
];
