import ScenarioServices from "../services/services/ScenarioServices";

export const getScenarioType = (loading, setLoading, setScenarioType) => {
  // setLoading(loading);
  ScenarioServices.getScenarioType()
    .then((response) => {
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        let scenarioType = responseVal?.map((element) => {
          return {
            value: element?.id,
            label: element?.name,
            desc: element?.description,
          };
        });
        setScenarioType(scenarioType);
      }
      // setLoading(loading);
    })
    .catch((err) => {
      // console.log(err.message);
      setLoading(loading);
    });
};

export const getScenarioSubType = (loading, setLoading, setScenarioSubType) => {
  // setLoading(loading);
  ScenarioServices.getScenarioSubType()
    .then((response) => {
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        let scenarioSubType = responseVal?.map((element) => {
          return { value: element?.id, label: element?.name };
        });
        setScenarioSubType(scenarioSubType);
      }
      // setLoading(loading);
    })
    .catch((err) => {
      // console.log(err.message);
      setLoading(loading);
    });
};

export const getInitiatives = async (loading, setLoading, setInitiatives) => {
  // setLoading(true);
  await ScenarioServices.getInitiatives()
    .then((response) => {
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        let initialValues = responseVal?.map((element) => {
          return { value: element?.id, label: element?.displayname };
        });
        setInitiatives(initialValues);
      }
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};

// Change type
export const getChangeType = async (loading, setLoading, setInitiatives) => {
  // setLoading(true);
  await ScenarioServices.getChangeType()
    .then((response) => {
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        let initialValues = responseVal?.map((element) => {
          return { value: element?.id, label: element?.DisplayName };
        });
        setInitiatives(initialValues);
      }
      // setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
};
