import React from 'react'
import { NotificationItemContent } from './NotificationPage';


const NotificationApprovals = ({
    tabKey="",
    notificationDataApprovals,
    getNotificationData,
    ...props
}) => {
 
    return (
        <React.Fragment>
            <div className='notifications mb-2'>
                <ul>
                    {
                        notificationDataApprovals?.length > 0 &&
                        notificationDataApprovals?.map(item=>{
                            return <React.Fragment key={Math.random()}>
                                <li>
                                    <NotificationItemContent item={item} tabKey={tabKey} getNotificationData={getNotificationData}/>
                                </li>
                            </React.Fragment>
                        }) || "No items found"
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default NotificationApprovals
