import { encodeQueryData, removeEmptyKeys } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}portfoliosetting?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function fetchPortfolioNodesById(id) {
  const url = `${API_BASE_URL}portfoliosetting/getnodes/${id}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}portfoliosetting`;
  let response = await http.post(url, data);
  return response;
}

async function updateSectionOrdering(data) {
  const url = `${API_BASE_URL}portfoliosection/updateSectionOrdering`;
  let response = await http.post(url, data);
  return response;
}

async function updatePropertiesOrdering(data) {
  const url = `${API_BASE_URL}portfolioproperty/updatePropertiesOrdering`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}portfoliosetting/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletePortfolioSetting(id) {
  const url = `${API_BASE_URL}portfoliosetting/`;
  let response = await http.delete(url + id);
  return response;
}


async function delete_bulk_Portfolio_ids(data) {
  const url = `${API_BASE_URL}portfoliosetting/bulk_delete`;  
  let response = await http.put(url, data);
  return response;
}

async function getById(id) {
  const url = `${API_BASE_URL}portfoliosetting/`;
  let response = await http.get(url + id);
  return response;
}

async function getFilteredPortfolios(filter,page=1,limit=100) {
  let params = {
    page,
    limit,
  }
  params = removeEmptyKeys(params);
  const url = `${API_BASE_URL}portfoliosetting/filter?`+encodeQueryData(params);
  const response = await http.post(url, filter);
  return response;
}

async function getSwimlaneViewData(filter) {
  filter = removeEmptyKeys(filter);
  //old changes
  // const url = `${API_BASE_URL}portfoliosetting/drawSwimlaneView?`+encodeQueryData(isIntegrationLandscape);
  // const response = await http.post(url, filter);

  //New changes
  const {isIntegrationLandscape,...rest}=filter;
  const url = `${API_BASE_URL}portfoliosetting/drawSwimlaneView?isIntegrationLandscape=${isIntegrationLandscape}`;
  const response = await http.post(url, rest);
  return response;
}

async function getFilteredLevels(moduleName) {
  let filter = {
    "moduleName":moduleName
  }
  const url = `${API_BASE_URL}portfoliosetting/get_distinct`;
  const response = await http.post(url, filter);
  return response;
}

async function getPortfolioSummary() {
  const url = `${API_BASE_URL}portfoliosetting/chart/summary`;
  let response = await http.get(url);
  return response;
}

async function getCompletenessReport(data) {
  const url = `${API_BASE_URL}portfoliosetting/report/completeness`;
  const response = await http.post(url, data);
  return response;
}

async function getMaxLevelId(data) {
  const url = `${API_BASE_URL}portfoliosetting/get_levelid`;
  const response = await http.post(url, data);
  return response;
}

async function duplicatePortfolioItem(data) {
  const url = `${API_BASE_URL}portfoliosetting/duplicate`;
  const response = await http.post(url, data);
  return response;
}

/* Get all relation report landscape with filterd portfolios */
async function getAllRelationReportFilteredPortfolios(filter,page=1,limit=100) {
  let params = {
    page,
    limit,
  }
  params = removeEmptyKeys(params);
  const url = `${API_BASE_URL}portfoliosetting/report/relation-landscape?`+encodeQueryData(params);
  const response = await http.post(url, filter);
  return response;
}

async function gettechRadar() {
  const url = `${API_BASE_URL}portfoliosetting/gettechradar`;
  const response = await http.post(url);
  return response;
}



const PortfolioSettingServices = {
  getList,
  create,
  update,
  deletePortfolioSetting,
  getById,
  fetchPortfolioNodesById,
  getFilteredPortfolios,
  getSwimlaneViewData,
  updateSectionOrdering,
  updatePropertiesOrdering,
  delete_bulk_Portfolio_ids,
  getFilteredLevels,
  getPortfolioSummary,
  getCompletenessReport,
  getMaxLevelId,
  getAllRelationReportFilteredPortfolios,
  duplicatePortfolioItem,
  gettechRadar
};

export default PortfolioSettingServices;
