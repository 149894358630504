import { useEffect } from 'react';
import { useOgma } from '@linkurious/ogma-react';
import { alterAndDownloadCsv } from '../../utils/CityMapCommon';
 const GraphLayout = (props) => {
  const ogma = useOgma(); // hook to get the ogma instance
  const { exportGraphFormat, setExportGraphFormat, setLoading } = props;

  useEffect(() => {
    let exportFor = exportGraphFormat;

    if(exportFor === 'jpeg'){
      ogma.export.jpg({
        download: true,
        background: 'white'
      })
    } else if(exportFor === 'png'){
      ogma.export.png({
        download: true,
        background: 'white'
      })
    } else if(exportFor === 'svg'){
      ogma.export.svg({
        download: true,
        background: 'white'
      })
    }else if(exportFor === 'xlsx'){
      ogma.export.xlsx({
        filename: 'exported_data.xlsx',
        nodeData: (data, allTabs) => {
            return {
              referenceId: data?.neo4jProperties?.referenceId,
              label: data?.label,
              name: data?.neo4jProperties?.name
            };
        }
      })
    }else if(exportFor === 'csv'){
      ogma.export.csv({
        download: false,
        what: "nodes"
      }).then(data => {
        alterAndDownloadCsv(data)
      })
    }

    setExportGraphFormat('')
  }, [props?.exportGraphFormat])
  

  useEffect(() => {
    ogma.events.on('doubleclick', evt => {
      try {
        if (evt.target && evt.target.isNode) {
          props?.setCityMapQuery(getExpandQuery(evt.target.getId()));
          props?.setDblClickEvent(true);
        }
      }
      catch (err) {
        console.log(err?.message);
      }
    });

    ogma.tools.tooltip.onNodeHover(
      n => {
        let htmlString = getNodeTooltip(n);
        if(htmlString && htmlString !== ''){
          return getNodeTooltip(n);
        }
      },
      { className: 'ogma-tooltip', position: "right"}
    );
  
  },[])

  useEffect(() => {
      ogma.addGraph(props?.ogmaDblClickData)
      setTimeout(()=> {
        setGraphLayout()
      },500)
  }, [props?.ogmaDblClickData])
  

  useEffect(() => {
    setTimeout(()=> {
      setGraphLayout()
    },1000)
  }, [props?.graph]);

  
  const getExpandQuery = id => {
    return [
      'MATCH (n)-[r]-(m)',
      'WHERE id(n) = ' + id,
      'RETURN n,r,m;',
      ''
    ].join('\n');
  }


  const getNodeTooltip = (n) => {

    let neo4jObject = n.getData();
    const degree = n.getDegree();
    let labelNode = neo4jObject.label;
    let html = '<div class="arrow"></div>' +
      '<div class="ogma-tooltip-body">' +
      '  <table width="100%" border="1" cellspacing="5" cellpadding="5">';
    if (labelNode) {
      html += '<tr><th>Label</th> <td>' + labelNode + '</td></tr>';
    }
    for (var key in neo4jObject.neo4jProperties) {
      if (neo4jObject.neo4jProperties.hasOwnProperty(key)) {
        if(key=="View Application" || key=="View Process")
        {
          html += '<tr><th>' + key + '</th> <td><a href="' + neo4jObject.neo4jProperties[key] + '" target="_blank">' + neo4jObject.neo4jProperties[key] + '</a></td></tr>';
        }
        else{
          html += '<tr><th>' + key + '</th> <td>' + neo4jObject.neo4jProperties[key] + '</td></tr>';
        }
        
      }
    }
  
    html +=
      '  </table>' +
      '</div>' +
      '<div class="ogma-tooltip-footer">Number of connections: ' +
      degree +
      '</div>';
    return html;
  }

  const setGraphLayout = () => {
    ogma.layouts.force({locate: true, charge: 2, edgeStrength: 0.10, edgeLength: 5})
    .then((data) => {
      setLoading(false)
    });
  }

  return null;
}

export default GraphLayout 