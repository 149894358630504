import React, { createContext, useContext, useReducer } from "react";
const MenuContext = createContext();
export const useMenu = () => useContext(MenuContext);

const initialState = {
  menuDetail: {},
};

const menuReducer = (stateMenu, action) => {
  switch (action.type) {

    case "MenuDetail":    
    sessionStorage.setItem("sessionMenuID", JSON.stringify(action.payload));
    
    const menuDetail= JSON.parse(sessionStorage.getItem("sessionMenuID")); 
    
    
    return {
        stateMenu,
        MenuID: action.payload,
      };
    default:
      return stateMenu;
  }
};

export const MenuProvider = ({ children }) => {
  const [stateMenu, dispatchMenu] = useReducer(menuReducer, initialState);
  return (
    <MenuContext.Provider value={{ stateMenu, dispatchMenu }}>
      {children}
    </MenuContext.Provider>
  );
};
