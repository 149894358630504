import React, { useEffect, useState } from "react";
import { Typography, ConfigProvider, Row, Col, Select, Form, Divider, Input, Button, message, InputNumber, DatePicker, Space, TimePicker } from "antd";

import CreatableSelect from "react-select/creatable";
import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
import { InputNumberForm } from "../../../dynamicForm/DynamicFormFields";
import dayjs from 'dayjs'; // or moment
import locale from 'antd/es/locale/en_US'; // Adjust based on your locale
import moment from 'moment';
const { Option } = Select;


//import type { Dayjs } from 'dayjs';
//const GlobalTags = ({ tagOptions, form, formData, templatename, templatePropName, type, item }) => {







//   return (

//       }
//       {type === "Time" && (()=>{

//         return (

//         )
//       })

//       }
//       {type === "Date" &&

//       }
//       {type === "Single select" &&

//       }
//       {type === "Date time" &&

//       }
//       {type === "Numeric" &&

//       }
// {
//   type === "Multi select" &&
//     <>
//       <Row gutter={[16, 24]} className="mb-2">
//         <Col span={8} className="gutter-row">
//           <Typography.Title level={5} className="mb-2">
//             {templatename}
//           </Typography.Title>
//         </Col>
//         <Col span={16} className="gutter-row">
//           <Form.Item
//             name={templatePropName}
//             rules={[
//               {
//                 required: false,
//                 message: `Tag is required!`,
//               },
//             ]}
//             wrapperCol={{ span: 16 }}
//             className="mb-2 text-start"
//           >
//             {type === "Multi select" &&
//               <Select
//                 mode="multiple"
//                 // value={selectedTags}
//                 options={options}
//                 onChange={handleChange}
//                 placeholder="Select Global Properties"
//                 className="react-select-container"
//               // classNamePrefix="react-select"
//               // components={{ Option: CustomOption }}
//               />
//             }
//           </Form.Item>
//         </Col>
//       </Row>
//     </>
// }
//   <Divider />
// </>
//   );
// };
// 



const GetLongTextField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props
  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >
            <Input.TextArea rows={4} value={formData && formData[templatePropName] ? moment(formData[templatePropName]) : null} placeholder="maxLength is 6" onChange={(e) => form.setFieldValue(templatePropName, e.target.value)} />
          </Form.Item>

        </Col >
      </Row>
    </>
  )
}

const GetFreeTextField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;

  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >

            <Input placeholder="Basic usage" value={formData && formData[templatePropName] ? moment(formData[templatePropName]) : null} onChange={(e) => form.setFieldValue(templatePropName, e.target.value)} />
          </Form.Item>

        </Col >
      </Row>
    </>

  )
}

const GetMultiSelectField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;
  const [options, setOptions] = useState([]);


  useEffect(() => {
    setOptions(
      tagOptions.map((option) => {

        return { ...option, value: option?.id, label: option?.option };
      })
    );
  }, [tagOptions]);

  const handleChange = (tags) => {
    form.setFieldValue(templatePropName, tags);
  };


  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>
        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >

            <Select
              mode="multiple"
              // value={selectedTags}
              options={options}
              onChange={handleChange}
              placeholder="Select Global Properties"
              className="react-select-container"
            />
          </Form.Item>
        </Col>
      </Row>
    </>

  )
}

const GetTimeField = ({ ...props }) => {
  const { formData, form, templatename, templatePropName } = props;

  const initialTime = formData[templatePropName] ? dayjs(formData[templatePropName]) : null;
  const [fieldValue, setFieldValue] = useState(initialTime);

  useEffect(() => {
    const value = form.getFieldValue(templatePropName);
    setFieldValue(value ? dayjs(value) : null);
  }, [formData, templatePropName]);

  const handleChange = (time, timeString) => {
    const timeValue = dayjs(timeString, 'HH:mm:ss'); // Convert time string to dayjs object
    console.log('Time from TimePicker:', timeValue); // Log the time for debugging

    setFieldValue(timeValue);

    // Check if time is valid before formatting
    const formattedTime = timeValue.isValid() ? timeValue.format() : null; // Check validity
    form.setFieldsValue({ [templatePropName]: formattedTime }); // Save formatted time
  };

  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>
        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item name={templatePropName}>
            <ConfigProvider locale={locale}>
              <TimePicker
                value={fieldValue}
                onChange={handleChange}
                format="HH:mm:ss" // Show hours, minutes, and seconds
                style={{width:"50%"}} 
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

const GetDateField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;

  const initialDate = formData[templatePropName] ? dayjs(formData[templatePropName]) : null;
  const [fieldValue, setFieldValue] = useState(initialDate);

  useEffect(() => {
    const value = form.getFieldValue(templatePropName);
    setFieldValue(value ? dayjs(value) : null);
  }, [formData, templatePropName]);

  const handleChange = (dateString) => {
    const date = dayjs(dateString); // Convert string to dayjs object
    console.log('Date from DatePicker:', date); // Log the date for debugging

    setFieldValue(date);

    // Check if date is valid before formatting
    const formattedDate = date.isValid() ? date.format() : null; // Check validity
    form.setFieldsValue({ [templatePropName]: formattedDate }); // Save formatted date
  };

  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item name={templatePropName}>

            <ConfigProvider locale={locale}>
              <DatePicker
                value={fieldValue}
                onChange={(date, dateString) => handleChange(dateString)}
                style={{width:"50%"}} 
              />
            </ConfigProvider>
          </Form.Item>

        </Col >
      </Row>
    </>

  )
}

const GetSingleSelectField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;
  const [options, setOptions] = useState([]);
  const [memorisedData, setMemorisedData] = useState([])

  useEffect(() => {
    const aa = tagOptions.map((option) => ({
      value: option?.id,
      label: option?.option,
    }))
    if(aa !== options){
      setOptions(aa);
    }
    
  }, [tagOptions]);

  const handleChange = (val) => {
    form.setFieldValue(templatePropName, val);
  };


  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
            initialValue={formData && formData[templatePropName] }
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="label"
              onChange={handleChange}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={options}

            />
          </Form.Item>

        </Col >
      </Row>
    </>

  )
}

const GetDateAndTimeField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;
  const initialDate = formData[templatePropName] ? dayjs(formData[templatePropName]) : null;
  const [fieldValue, setFieldValue] = useState(initialDate);

  useEffect(() => {
    const value = form.getFieldValue(templatePropName);
    setFieldValue(value ? dayjs(value) : null);
  }, [formData, templatePropName]);

  const handleChange = (dateString) => {
    const date = dayjs(dateString); // Convert string to dayjs object
    console.log('Date from DatePicker:', date); // Log the date for debugging

    setFieldValue(date);

    // Check if date is valid before formatting
    const formattedDate = date.isValid() ? date.format() : null; // Check validity
    form.setFieldsValue({ [templatePropName]: formattedDate }); // Save formatted date
  };

  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item name={templatePropName}>

            <ConfigProvider locale={locale}>
              <DatePicker
                showTime
                value={fieldValue}
                onChange={(date, dateString) => handleChange(dateString)}
                style={{width:"100%"}} 
              />
            </ConfigProvider>
          </Form.Item>

        </Col >
      </Row>
    </>

  )
}

const GetNumericField = ({ ...props }) => {
  const { tagOptions, form, formData, templatename, templatePropName, type, item } = props;
  const [fieldValue, setFieldValue] = useState(null)

  const handleChange = (value) => {
    setFieldValue(value)
    form.setFieldValue(templatePropName, value)
  }

  useEffect(() => {
    setFieldValue(formData && formData[templatePropName] ? moment(formData[templatePropName]) : null)
  }, [formData])


  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>

        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >
            <InputNumber style={{width:"100%"}} value={fieldValue} min={1} onChange={(value) => handleChange(value)} changeOnWheel />

          </Form.Item>

        </Col >
      </Row>
    </>

  )
}

const GlobalTags = ({ type, ...props }) => {
  const components = {
    "Long text": GetLongTextField,
    "Multi select": GetMultiSelectField,
    "Free text": GetFreeTextField,
    "Time": GetTimeField,
    "Date": GetDateField,
    "Single select": GetSingleSelectField,
    "Date time": GetDateAndTimeField,
    "Numeric": GetNumericField,
  };

  const Component = components[type];
  return Component ? <Component {...props} /> : null;
};


export default GlobalTags;
