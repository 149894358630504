import React, { useEffect, useRef, useState } from 'react';
import TabChangeLogAlerts from '../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts';
import TabComments from '../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments';
import TabDiagrams from '../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams';
import TabLinks from '../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks';
import TabConnections from '../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections';
import LoadingBar from 'react-top-loading-bar';
import { useLocation } from 'react-router';
import { PageTitleHeading } from '../../../utils/Common';
import { Spin, Tabs } from 'antd';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import TabBasicInformationEdit from './TabBasicInformationEdit';

const EditPortfolioItem = ({id,editDetails,moduleName:routeModuleName,actionType,...props}) => {
    const ref = useRef(null);
    const [activeTabKey, setActiveTabKey] = useState("");
    const [portfolioId,setPortfolioId] = useState(null);
    const [portfolioItemData, setPortfolioItemData] = useState({});
    const [portfolioData, setPortfolioData] = useState({});
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    if(!location?.state){
        location.state = JSON.parse(sessionStorage.getItem('editData-'+routeModuleName)) || null;
    }else{
        sessionStorage.setItem('editData-'+routeModuleName,JSON.stringify(location?.state))
    }    
    
    useEffect(() => {
        setLoading(true);
        let menuData = getMenuPortfolio({routeModuleName});
        setPortfolioData(menuData);
        setPortfolioItemData(editDetails);
        setActiveTabKey("tab_information");
        
        if(actionType==="add"){
            setPortfolioId(null);
        }else if(actionType==="update"){
            if(id){
                setPortfolioId(id);
            }
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id,actionType,routeModuleName,editDetails]);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    const tabsProps = {
        itemData:portfolioItemData,
        setActiveTabKey,
        handleTabChange, 
        setFormItems:setPortfolioItemData,
        // toggleTabDisabled,
        // tabDisabled,
        id:portfolioId,
        moduleName:routeModuleName,
    };

    const PortfolioSingleTabs = [
        {
            label: `Information`,
            key: 'tab_information',
            children: <TabInformationsEdit 
                location={location} 
                {...tabsProps}
            />,
        },
        {
            label: `Relations`,
            key: 'tab_connections',
            disabled:portfolioId?false:true,
            children: <TabConnections {...tabsProps}/>,
        },
        {
            label: `External Links`,
            key: 'tab_links',
            disabled:portfolioId?false:true,
            children: <TabLinks {...tabsProps}/> ,
        },
        {
            label: `Diagram`,
            key: 'tab_diagram',
            disabled:portfolioId?false:true,
            children: <TabDiagrams {...tabsProps} titleName = {portfolioItemData?.DisplayName}/>,
        },
        {
            label: `Comments`,
            key: 'tab_comments',
            disabled:portfolioId?false:true,
            children: <TabComments {...tabsProps}/>,
        },
        {
            label: `Change log/ Alerts`,
            key: 'tab_changelogAlert',
            disabled:portfolioId?false:true,
            children: <TabChangeLogAlerts {...tabsProps}/>,
        },
    ];
    
    return (
        <React.Fragment>
            <div className="container-fluid appitem_editablebx border-left overflow-y" style={{maxHeight:window?.innerHeight}}>
                <Spin spinning={loading}>
                    <LoadingBar color="blue" ref={ref} />
                    <div className="business-application-entry-page mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <PageTitleHeading
                                text={(() => {
                                    if (editDetails?.displayname) {
                                        return portfolioData?.name+": " + portfolioItemData?.displayname;
                                    } else {
                                        return `Add ${portfolioData?.name}`;
                                    }
                                })()}
                            />
                            <div className="my-3">
                                {/* <GoBack path={location?.state?.from} state={location?.state} back/> */}
                            </div>
                        </div>
                        <Tabs
                            type="card"
                            className=""
                            items={PortfolioSingleTabs}
                            activeKey={activeTabKey}
                            onChange={handleTabChange}
                            // destroyInactiveTabPane={true}
                        />
                    </div>
                </Spin>
            </div>
        </React.Fragment>
    )
}

EditPortfolioItem.defaultProps = {
    actionType:"update",
}

export default EditPortfolioItem;

const TabInformationsEdit = ({moduleName,...props}) =>{
    switch (moduleName) {
        case 'Portfolio': return "Hello";
        default: return <TabBasicInformationEdit 
            {...props} 
            moduleName={moduleName}
        />;
    }
}