import http from "./httpService";
import { encodeQueryData } from "../../utils/Common";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getTemplateList() {
    const url = `${API_BASE_URL}template`;
    let response = await http.get(url);
    return response;
  }

  
async function createTemplate(data) {
    const url = `${API_BASE_URL}template`;
    let response = await http.post(url, data);
    return response;
  }

  
async function updateTemplate( id,data) { 


   // const {id, linkTypeName, description} = data;
    const url = `${API_BASE_URL}template/${id}`;
    let response = await http.put(url, data);
    return response;
  }
   
async function getTemplateListById(id) {
    const url = `${API_BASE_URL}template/`;
    let response = await http.get(url + id);
    return response;
  }

  
async function deleteTemplate(id,params={}) {
  const url = `${API_BASE_URL}template/`;
  let response = await http.delete(url + id+"?"+ encodeQueryData(params));
  return response;
}

  export default {
    getTemplateList,
    createTemplate,
    updateTemplate,
    getTemplateListById ,
    deleteTemplate
  };