import React, { createContext, useContext, useReducer } from "react";
const NotificationApplicationContext = createContext();
export const useNotificationProvider = () => useContext(NotificationApplicationContext);

const initialState = {
  data: [],
  loading: false,
  error: false 
};

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        state,
        data: action.payload,
      };
     
    
    default:
      return state;
  }
};

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationReducer, initialState);

  return (
    <NotificationApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationApplicationContext.Provider>
  );
};
