import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from "antd";
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import ScenarioServices from "../../services/services/ScenarioServices";
import "./Scenario.scss";
import { useNavigate } from "react-router";
import { getScenarioType, getScenarioSubType, getInitiatives } from "../../utils/ScenarioCommon";
import { PageTitleHeading } from "../../utils/Common";
import LoadingBar from 'react-top-loading-bar'
const { Title } = Typography;
const { TextArea } = Input;


const StartNewScenario = (props) => {
  const ref = useRef(null)
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [scenarioType, setScenarioType] = useState([]);
  const [scenarioSubType, setScenarioSubType] = useState([]);
  const [initiatives, setInitiatives] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [openInitiativeModal, setOpenInitiativeModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openExistingScenario, setOpenExistingScenario] = useState(false);
  const [selectedScenarioAttr, setSelectedScenarioAttr] = useState({
    typeId: "1",
    subTypeId: "",
  });
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedScenarioId, setSelectedScenarioId] = useState("");

  const [activeSelectedCol, setActiveSelectedCol] = useState([]);
  const [activeAvailableCol, setActiveAvailableCol] = useState([]);
  const [newColValue, setNewColValue] = useState("");
  const [proceed, setProceed] = useState(false);
  const [newColValueCount, setNewColValueCount] = useState(0);
  const tooltipColor = "#9ba894";

  const [initiativeForm] = Form.useForm();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const formInitialValues = {
    scenarioName: "",
    scenarioTypeID: selectedScenarioAttr?.typeId,
    scenarioSubtypeID: null,
    objective: "",
    intiativeId: null,
    create_from: "scratch",
    copy_from: null,
    displayType: "tabular",
  };

  useEffect(() => {
    getScenarioType(loading, setLoading, setScenarioType)
    getScenarioSubType(loading, setLoading, setScenarioSubType);
    getInitiatives(loading, setLoading, setInitiatives);
  }, []);

  useEffect(() => {
    getScenarioList();
    getAvailableScenarioColumns();

    if(form.getFieldValue('scenarioSubtypeID')){
      let f1 = form.getFieldsValue();
      form.setFieldsValue({
        ...f1,
        // eslint-disable-next-line no-useless-computed-key
        ['copy_from']:null,
      })
      setSelectedScenarioId("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScenarioAttr.subTypeId]);

  useEffect(() => {
    if(selectedScenarioId)
      getScenarioColumns();
  }, [selectedScenarioId]);


  const getScenarioList = async () => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    let obj = {};
    obj.scenarioSubtypeID = selectedScenarioAttr?.subTypeId;
    setLoading(true);
    await ScenarioServices.getScenarioList(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          let scenarioList = responseVal?.map((element) => {
            return { value: element?.id, label: element?.name };
          });
          setScenarioList(scenarioList);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
      if (ref.current) {
        ref.current.complete();
      }
  };

  const getAvailableScenarioColumns = async () => {
    setLoading(true);
    let obj = {};
    obj.scenarioTypeID = selectedScenarioAttr?.typeId;
    obj.scenarioSubtypeID = selectedScenarioAttr?.subTypeId;
    await ScenarioServices.getAvailableScenarioColumns(obj)
      .then(async(response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          let allColumns = await responseVal?.map((element) => {
            return {
              value: element?.id,
              label: element?.name,
              is_mandatory: element?.is_mandatory,
              visibility: element?.visibility,
              referenceTable: element?.referenceTable,
            };
          });
          setSelectedColumns(allColumns);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getScenarioColumns = async () => {
    setLoading(true);
    let obj = {};
    obj.scenarioId = selectedScenarioId;
    await ScenarioServices.getScenarioColumns(obj)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          let allColumns = await Promise.all(responseVal?.map((element) => {
            return {
              value: element?.availableColumnId,
              label: element?.columnName,
              is_mandatory: element?.is_mandatory,
              referenceTable: element?.referenceTable,
              visibility: element?.visibility,
              prevColumnId: element?.id,
            };
          }));

          let modifiedColumns = await Promise.all(selectedColumns.map((element) => {
            let currentSelectedCol = allColumns.filter(
              (newCol) => newCol?.value === element?.value
            )[0];
            return currentSelectedCol ? currentSelectedCol : element;
          }));
          console.log(modifiedColumns,"===modifiedColumnsmodifiedColumns")
          if(modifiedColumns.length){
            allColumns.map((element) => {
              if(element.value == 0){
                modifiedColumns.push(element);
              }
            });
            setSelectedColumns(modifiedColumns);
          }

        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onAddInitiativeSubmit = (formData) => {
    setConfirmLoading(true);
    let obj = {};
    obj.DisplayName = formData?.name;
    obj.tableName = 'initiatives';

    let newInitiativesLength = initiatives?.filter(f=>f.label?.toLowerCase()?.trim() === formData?.name?.toLowerCase()?.trim())?.length
    if(newInitiativesLength>0){
      setConfirmLoading(false);
      return message.error("Initiative "+formData?.name+" already exist");
    }

    ScenarioServices.addInitiative(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          getInitiatives(loading, setLoading, setInitiatives);
          initiativeForm.resetFields();
        }
        setConfirmLoading(false);
        setOpenInitiativeModal(false);
      })
      .catch((err) => {
        message.error(err.message);
        setConfirmLoading(false);
      });
  };

  const handleScenarioOption = (e) => {
    if (e?.target?.value === "existing") {
      setOpenExistingScenario(true);
    } else {
      setOpenExistingScenario(false);
      setSelectedScenarioId("");
    }
  };

  const handleToggleListClick = (value, clickTo) => {
    if (clickTo === "selected") {
      if (!activeSelectedCol.includes(value)) {
        activeSelectedCol.push(value);
        setActiveSelectedCol([...activeSelectedCol]);
      } else {
        let arrayIndex = activeSelectedCol.indexOf(value);
        activeSelectedCol.splice(arrayIndex, 1);
        setActiveSelectedCol([...activeSelectedCol]);
      }
    } else if (clickTo === "available") {
      if (!activeAvailableCol.includes(value)) {
        activeAvailableCol.push(value);
        setActiveAvailableCol([...activeAvailableCol]);
      } else {
        let arrayIndex = activeAvailableCol.indexOf(value);
        activeAvailableCol.splice(arrayIndex, 1);
        setActiveAvailableCol([...activeAvailableCol]);
      }
    }
  };

  const handleColumnShift = (moveTo) => {
    if (moveTo === "right") {
      activeAvailableCol.forEach((val) => {
        availableColumns.forEach(function (elem, index) {
          if (elem?.value === val) {
            availableColumns.splice(index, 1);
            selectedColumns.push(elem);
          }
        });
      });
      setSelectedColumns([...selectedColumns]);
      setAvailableColumns([...availableColumns]);
      setActiveAvailableCol([]);
    } else if (moveTo === "left") {
      activeSelectedCol.forEach((val) => {
        selectedColumns.forEach(function (elem, index) {
          if (elem?.value === val) {
            selectedColumns.splice(index, 1);
            availableColumns.push(elem);
          }
        });
      });
      setSelectedColumns([...selectedColumns]);
      setAvailableColumns([...availableColumns]);
      setActiveSelectedCol([]);
    }
  };

  const handleAddNewColumn = () => {
    if (newColValue === "") {
      message.error("Please enter column name to add new column.");
    } else {
      let newColumnsLength = selectedColumns?.filter(f=>f.label?.toLowerCase()?.trim() === newColValue?.toLowerCase()?.trim())?.length
      if(newColumnsLength>0){
        return message.error("Column "+newColValue+" already exist");
      }
      selectedColumns.push({
        value: `new ${newColValueCount}`,
        label: newColValue,
        is_mandatory: 0,
        referenceTable: null,
        prevColumnId: "",
        visibility: 1,
      });
      setSelectedColumns([...selectedColumns]);
      setNewColValue("");
      setNewColValueCount(newColValueCount + 1);
    }
  };
  const onFormSubmit = async (formData) => {
    if (proceed) {
      setLoading(true);
      const { displayType, ...newFormData } = formData;
      newFormData.intiativeId =
        formData?.intiativeId === null ? "" : formData?.intiativeId;
      newFormData.copy_from =
        selectedScenarioId === "" ? "" : formData?.copy_from;
      const selectCol = selectedColumns?.map((val) => {
        if (val?.is_mandatory === 0) {
          return {
            id: 0,
            name: val?.label,
            is_mandatory: val?.is_mandatory,
            referenceTable: val?.referenceTable,
            visibility: val?.visibility,
            prev_column_id: val?.prevColumnId || null,
          };
        } else {
          return {
            id: val?.value,
            name: val?.label,
            is_mandatory: val?.is_mandatory,
            referenceTable: val?.referenceTable,
            visibility: val?.visibility,
            prev_column_id: val?.prevColumnId || null,
          };
        }
      });

      newFormData.selectColumns = selectCol;
      newFormData.userEmail = localStorage.getItem('email');
      await ScenarioServices.createScenario(newFormData)
        .then((response) => {
          if (response?.data?.code === 200) {
            let responseVal = response?.data?.data;
            form.resetFields();
            setLoading(false);
            navigate(`/edit-scenario/${responseVal?.id}`)
          }
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    }
  };

  const handleBlurName = (e) => {
    let value = e?.target?.value;

    let obj = {};
    obj.columnName = value;
    ScenarioServices.checkUniqueScenario(obj)
    .then((response) => {
      if(response.data.code == 200){
        form.resetFields(["scenarioName"]);
        message.error("Scenario Name already exist.");
      }
    })
    .catch((err) => {
      message.error(err.message);
    });
  }

  const handleNext = () => {
    form
      .validateFields()
      .then(async (values) => {
        setActiveTab(activeTab + 1);
        setProceed(false);

      })
      .catch((err) => {
        // console.log('error',err);
      });
  };

  return (
    <React.Fragment>
      <LoadingBar color='#1f1f1f' ref={ref} />
      <PageTitleHeading text={`Start New Scenario`} />
      <Steps
        size="default"
        type="navigation"
        current={activeTab}
        items={[
          {
            title: "Landscape Configuration",
          },
          {
            title: "Define Landscape Attributes",
          },
        ]}
      />

      <Spin className="loading_bx" size="small" spinning={loading}>
        <Card className="my-4">
          <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFormSubmit}
            layout="horizontal"
            initialValues={formInitialValues}
            requiredMark={false}
          >
            {/* Scenario Configuration section  */}
            <div className={activeTab === 0 ? "" : "d-none"}>
              <div className=" position-relative">
                <Form.Item
                  label="Initiative"
                  name="intiativeId"
                  wrapperCol={{ span: 16 }}
                  rules={[
                    { required: false, message: "Please select Initiative" },
                  ]}
                  className="mb-2 text-start"
                >
                  <Select
                    placeholder="Select"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.children.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      );
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.children ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.children ?? "").toLowerCase())
                    }
                  >
                    {initiatives?.map((e) => {
                      return (
                        <React.Fragment key={Math.random()}>
                          <Select.Option value={e?.value?.toString()}>
                            {e?.label}
                          </Select.Option>
                        </React.Fragment>
                      );
                    })}
                  </Select>
                </Form.Item>

                <div className="initiative-add-btn">
                  <PlusCircleOutlined
                    onClick={() => setOpenInitiativeModal(true)}
                  />
                </div>
              </div>
              
              <Form.Item
                label={
                  <>
                    <span>Type</span>
                    <span className="text-danger">*</span>
                  </>
                }
                name="scenarioTypeID"
                rules={[
                  { required: true, message: "Please enter scenario Type" },
                ]}
                className="mb-2 text-start"
              >
                <Radio.Group
                  onChange={(e) =>
                    setSelectedScenarioAttr({
                      ...selectedScenarioAttr,
                      typeId: e.target.value,
                    })
                  }
                >
                  <Space direction="vertical" className="text-start">
                    {scenarioType?.map((e, i) => {
                      return (
                        <React.Fragment key={Math.random()}>
                          <Radio value={e.value}>
                            {e.label}
                            {e.desc && (
                              <Tooltip
                                title={e.desc}
                                color={tooltipColor}
                                placement="right"
                                className="ms-2 text-primary"
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            )}
                          </Radio>
                        </React.Fragment>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={
                  <React.Fragment>
                    <span>Sub Type</span>
                    <span className="text-danger">*</span>
                  </React.Fragment>
                }
                name="scenarioSubtypeID"
                rules={[
                  { required: true, message: "Please enter scenario Sub Type" },
                ]}
                className="mb-2 text-start"
                // initialValue={'1'}
              >
                <Select
                  allowClear
                  placeholder="Select"
                  onChange={(value) => {
                    setSelectedScenarioAttr({
                      ...selectedScenarioAttr,
                      subTypeId: value,
                    });
                  }}
                >
                  {scenarioSubType?.map((e) => {
                    return (
                      <React.Fragment key={Math.random()}>
                        <Select.Option value={e.value}>{e.label}</Select.Option>
                      </React.Fragment>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <span>Name</span>
                    <span className="text-danger">*</span>
                  </>
                }
                name="scenarioName"
                rules={[
                  { required: true, message: "Please enter scenario name" },
                ]}
                className="mb-2 text-start"
              >
                <Input placeholder="Scenario Name" autoComplete="off" onBlur={handleBlurName} />
              </Form.Item>

              <Form.Item
                label="Description"
                name="objective"
                rules={[
                  {
                    required: false,
                    message: "Please enter scenario description",
                  },
                ]}
                className="mb-2 text-start"
              >
                <TextArea rows={4} />
              </Form.Item>
			        <Form.Item
                label="Visibility"
                name="visibility"
                // initialValue="1"
                rules={[
                  { required: false, message: "Please select visibility" },
                ]}
                className="mb-2 text-start"
              >
                <Select
                  options={[
                    { value: '1', label: "Public" },
                    { value: '2', label: "Private" }
                  ]}
                >
                </Select>
              </Form.Item>
              <Form.Item
                label="Display Type"
                name="display_type"
                rules={[
                  { required: false, message: "Please select display type" },
                ]}
                className="mb-2 text-start d-none"
                // initialValue="tabular"
              >
                <Select 
                  // defaultValue="tabular"
                  options={[
                    { value: 'tabular', label: "Tabular" }
                  ]}
                >
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <span>Scenario Options</span>
                    <span className="text-danger">*</span>
                  </>
                }
                name="create_from"
                rules={[
                  { required: true, message: "Please select scenario option" },
                ]}
                className="mb-2 text-start"
              >
                <Radio.Group onChange={handleScenarioOption}>
                  <Radio value="scratch">Start from scratch</Radio>
                  <Radio value="existing">Copy from Existing</Radio>
                </Radio.Group>
              </Form.Item>

              {/* Scenario List */}

              <Form.Item
                name="copy_from"
                wrapperCol={{ span: 11 }}
                className={`mb-2 text-start ${openExistingScenario ? "" : "d-none"
                  }`}
              >
                <Select
                  placeholder="Select Scenario"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => setSelectedScenarioId(value)}
                  filterOption={(input, option) => {
                    return (option?.children.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    );
                  }}
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.children ?? "").toLowerCase())
                  }
                >
                  {scenarioList?.map((e) => {
                    return (
                      <React.Fragment key={Math.random()}>
                        <Select.Option value={e?.value?.toString()}>
                          {e?.label}
                        </Select.Option>
                      </React.Fragment>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            {/* Define Scenario Attributes section  */}
            <div className={activeTab !== 0 ? "" : "d-none"}>
              <Row className="d-flex justify-content-between">
                {/* Available Columns */}
                <Col md={10}>
                  <List
                    header={<>Available Columns</>}
                    size="small"
                    bordered
                    dataSource={availableColumns}
                    renderItem={(item) => (
                      <List.Item
                        value={item.value}
                        className={`${activeAvailableCol.includes(item?.value)
                          ? "active"
                          : ""
                          } ${item?.is_mandatory === 0 ? "pointer" : ""}`}
                        onClick={() =>
                          item?.is_mandatory === 0 &&
                          handleToggleListClick(item?.value, "available")
                        }
                      >
                        {item?.label}
                      </List.Item>
                    )}
                  />
                </Col>

                {/* Columns Changer */}
                <Col md={4} className="m-auto column-arrange-icon">
                  <DoubleRightOutlined
                    onClick={() => handleColumnShift("right")}
                  />
                  <br />
                  <DoubleLeftOutlined
                    onClick={() => handleColumnShift("left")}
                  />
                </Col>

                {/* Selected columns */}
                <Col md={10}>
                  <List
                    header={<>Selected Columns</>}
                    size="small"
                    className="selected-col-list"
                    bordered
                    dataSource={selectedColumns}
                    renderItem={(item) => (
                      <List.Item
                        value={item?.value}
                        className={`${activeSelectedCol.includes(item?.value)
                          ? "active"
                          : ""
                          } ${item?.is_mandatory === 0 ? "pointer" : ""}`}
                        onClick={() =>
                          item?.is_mandatory === 0 &&
                          handleToggleListClick(item.value, "selected")
                        }
                      >
                        {item?.label}
                      </List.Item>
                    )}
                  />
                  {/* Add New Column section */}
                  <Card className="mt-2 add-new-column">
                    <Typography.Text className="me-auto">
                      Add New Column
                    </Typography.Text>
                    <div className="d-flex mt-2 ">
                      <Input
                        placeholder="Column Name"
                        allowClear
                        className="me-4"
                        value={newColValue}
                        onChange={(e) => setNewColValue(e.target.value)}
                      />
                      <Button onClick={handleAddNewColumn}>Add</Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>

            <div className="text-end mt-5">
              {activeTab > 0 && (
                <Button
                  className="me-2"
                  size="middle"
                  onClick={() => setActiveTab(activeTab - 1)}
                >
                  Previous
                </Button>
              )}
              {activeTab === 0 ? (
                <Button size="middle" onClick={handleNext}>
                  Next
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  onClick={() => setProceed(true)}
                  size="middle"
                >
                  Proceed
                </Button>
              )}
            </div>
          </Form>
        </Card>
      </Spin>

      <Modal
        title={"Add Initiative"}
        open={openInitiativeModal}
        footer={false}
        destroyOnClose
        closable={true}
        maskClosable={false}
        onCancel={() => setOpenInitiativeModal(false)}
      >
        <Form
          form={initiativeForm}
          onFinish={onAddInitiativeSubmit}
          requiredMark={false}
          // initialValues={{}}
        >
          <Form.Item
            label={
              <React.Fragment>
                <span>Name</span>
                <span className="text-danger">*</span>
              </React.Fragment>
            }
            name="name"
            rules={[
              { required: true, message: "Please enter initiative name" },
            ]}
            className="my-4 text-start"
          >
            <Input
              placeholder="Initiative name"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="ms-auto d-block"
              htmlType="submit"
              disabled={confirmLoading}
            >
              {confirmLoading ? "Please Wait" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default StartNewScenario;
