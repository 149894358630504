import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import CityMapServices from "../../services/services/CityMapServices";
import AdminLayout from "../layouts/AdminLayout";
import Filter from "./Filter";
import GraphContainer from "./GraphContainer";
import "./CityMap.scss";
import { useReadCypher } from "use-neo4j";
import { getQueryForCityMap } from "../../utils/CityMapCommon";
import GraphContainerNode1 from "./twoNodes/GraphContainerNode1";
import GraphContainerNode2 from "./twoNodes/GraphContainerNode2";
import { PageTitleHeading } from "../../utils/Common";

const { Title } = Typography;

const CityMap = (props) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [allNodes, setAllNodes] = useState([]);
  const [allLabels, setAllLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const [showCompareNode, setShowCompareNode] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [showTwoNodeGraph, setShowTwoNodeGraph] = useState(false); 
  const [showTwoNodeGraph1, setShowTwoNodeGraph1] = useState(false); 
  const [showTwoNodeGraph2, setShowTwoNodeGraph2] = useState(false); 
  const [showFilter, setshowFilter] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const [query, setQuery] = useState("Matrix");
  const [cityMapQuery, setCityMapQuery] = useState("");
  const [cityMapNode1Query, setCityMapNode1Query] = useState("");
  const [cityMapNode2Query, setCityMapNode2Query] = useState("");
  const { error, records, run } = useReadCypher(
    "MATCH (n) RETURN distinct labels(n) AS name",
    { query }
  );
  const [filterNodes, setFilterNodes] = useState([]);
  const [filterNodes1, setFilterNodes1] = useState([]);
  const [filterNodes2, setFilterNodes2] = useState([]);
  const [selectedFilterNodes, setSelectedFilterNodes] = useState([]);
  const [filterRelations, setFilterRelations] = useState([]);
  const [legendsLabel, setlegendsLabel] = useState([]);


  useEffect(() => {
    run({ query })
      .then((data) => {
        if (data) {
          let allLabels = [];
          data?.records?.forEach((record) => {
            let label = record.get("name")[0];
            allLabels.push(label);
          });
          // set lables
          setAllLabels(allLabels);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, [query]);

  useEffect(() => {
    getAllLabels();
  }, []);

  useEffect(() => {
    getAllLabels();
  }, [selectedLabel]);

  useEffect(() => {
    const formData1 = form.getFieldsValue();
    setFormData(formData1);
    setLoading(true);
    setShowGraph(false);
    setShowTwoNodeGraph(false);
    setShowTwoNodeGraph1(false);
    setShowTwoNodeGraph2(false);
    if (formData1?.usecase === "1") {
      let cypherQuery = getQueryForCityMap(
        formData1?.city_map_node,
        formData1?.city_map_label,
        false,
        selectedFilterNodes
      );
      if (cypherQuery && cypherQuery != "") {
        setCityMapQuery(cypherQuery);
        setShowGraph(true);
      }
    }
    else if(formData?.usecase === "2") {
      let cypherQueryNode1 = getQueryForCityMap(
        formData?.comparison_node_1,
        formData?.city_map_label,
        false,
        selectedFilterNodes
      );

      let cypherQueryNode2 = getQueryForCityMap(
        formData?.comparison_node_2,
        formData?.city_map_label,
        false,
        selectedFilterNodes
      );
      if ((cypherQueryNode1 && cypherQueryNode1 != "") || (cypherQueryNode2 && cypherQueryNode2 != "")) {
        setCityMapNode1Query(cypherQueryNode1);
        setCityMapNode2Query(cypherQueryNode2);
        setShowTwoNodeGraph(true)
        setShowTwoNodeGraph1(true)
        setShowTwoNodeGraph2(true)
      }
    }
  }, [selectedFilterNodes]);

  const getAllLabels = () => {
    setLoading(true);
    CityMapServices.getAutocompleteNodes(selectedLabel || "")
      .then(async (response) => {
        if (response?.status === 200) {
          let responseVal = response?.data;
          let allLabels = [];
          if (selectedLabel === "") {
            Promise.all(
              responseVal?.map((element) => {
                if (!allLabels.includes(element?.label)) {
                  allLabels.push(element?.label);
                }
              })
            );
            // setAllLabels(allLabels);
          } else {
            setShowSubmit(true);
            let allNodes = responseVal?.map((element) => {
              return {
                label: element?.label,
                options: [
                  {
                    value: element?.id,
                    label: element?.text,
                  },
                ],
              };
            })?.sort((a, b) => a?.label?.localeCompare(b?.label));
            setAllNodes(allNodes);

          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFormSubmit = (formData) => {
    setFormData(formData);
    setLoading(true);
    setShowGraph(false);
    setShowTwoNodeGraph(false);
    setShowTwoNodeGraph1(false)
    setShowTwoNodeGraph2(false)
    if(selectedFilterNodes.length > 0 ){
      message.error('Please reset filter to render new City Map');
      setLoading(false);
    } else {
      if (formData?.usecase === "1") {
        let cypherQuery = getQueryForCityMap(
          formData?.city_map_node,
          formData?.city_map_label,
          false,
          selectedFilterNodes
        );
        if (cypherQuery && cypherQuery != "") {
          setCityMapQuery(cypherQuery);
          setShowGraph(true);
        }
      } else if(formData?.usecase === "2") {
        let node1 = formData?.comparison_node_1
        let node2 = formData?.comparison_node_2
          if(node1 === node2){
            message.error("Node 1 and Node 2 should be different")
            setLoading(false);
          } else if (node1 === undefined) {
            message.error("Please select node 1")
            setLoading(false);
          } else if (node2 == undefined) {
            message.error("Please select node 2")
            setLoading(false);
          } else {
            let cypherQueryNode1 = getQueryForCityMap(
              formData?.comparison_node_1,
              formData?.city_map_label,
              false,
              selectedFilterNodes
            );
      
            let cypherQueryNode2 = getQueryForCityMap(
              formData?.comparison_node_2,
              formData?.city_map_label,
              false,
              selectedFilterNodes
            );
            if ((cypherQueryNode1 && cypherQueryNode1 != "") || (cypherQueryNode2 && cypherQueryNode2 != "")) {
              setCityMapNode1Query(cypherQueryNode1);
              setCityMapNode2Query(cypherQueryNode2);
              setShowTwoNodeGraph(true)
              setShowTwoNodeGraph1(true)
              setShowTwoNodeGraph2(true)
            }
          }
        }
    }

  };

  const handleUseCaseChange = (value) => {
    setSelectedUseCase(value);
    setShowSubmit(false);
    setShowLabel(true);
    setShowCompareNode(false);
    setShowTwoNodeGraph(false);
    form.resetFields([
      "city_map_label",
      "city_map_node",
      "comparison_node_1",
      "comparison_node_2",
    ]);
    setShowGraph(false);
    setFilterNodes([])
  };

  return (
      <Spin className="loading_bx" size="small" spinning={loading}>
        <PageTitleHeading className={``} text={`City Map`}/>
        <Card>
          <Form
            form={form}
            onFinish={onFormSubmit}
            layout="vertical"
            initialValues={{}}
            requiredMark={false}
          >
            <Row className="justify-content-start">
              <Col md={4} className="me-4">
                <Form.Item
                  label={
                    <>
                      <span>Use Cases</span>
                      <span className="text-danger">*</span>
                    </>
                  }
                  name="usecase"
                  rules={[
                    { required: true, message: "Please select use case" },
                  ]}
                  className="mb-2 text-start"
                >
                  <Select
                    allowClear
                    placeholder="Select"
                    defaultValue={null}
                    onChange={(value) => {
                      handleUseCaseChange(value);
                    }}
                  >
                    <Select.Option value="1">
                      City Map - Single Node
                    </Select.Option>
                    <Select.Option value="2">
                      Comparison - Two Nodes
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={4} className={showLabel ? `me-2` : "d-none"}>
                <Form.Item
                  label={
                    <>
                      <span>Search Label</span>
                      <span className="text-danger">*</span>
                    </>
                  }
                  name="city_map_label"
                  rules={[
                    { required: true, message: "Please select Search Label" },
                  ]}
                  className="mb-2 text-start"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select"
                    defaultValue={null}
                    onChange={(value) => {
                      if(value !== undefined){
                        if (selectedUseCase === "1") {
                          setShowCompareNode(false);
                        } else {
                          setShowCompareNode(true);
                        }
                        setSelectedLabel(value);
                        setShowSubmit(true);
                        setShowGraph(false);
                        setShowTwoNodeGraph(false)
                        form.resetFields([
                          "city_map_node",
                          "comparison_node_1",
                          "comparison_node_2",
                        ]);
                      }
                    }}
                  >
                    {allLabels?.sort((a, b) => a?.localeCompare(b))?.map((e) => {
                      return (
                        <>
                          <Select.Option value={e}>{e}</Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                md={4}
                className={
                  selectedUseCase === "1" && showLabel ? "me-2" : "d-none"
                }
              >
                <Form.Item
                  label={
                    <>
                      <span>&nbsp;</span>
                    </>
                  }
                  name="city_map_node"
                  className="mb-2 text-start"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Node"
                    defaultValue={null}
                    options={allNodes}
                    filterOption={(input, option) =>{
                         return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col md={6} className={showCompareNode ? "me-2" : "d-none"}>
                <Form.Item
                  label={
                    <>
                      <span>{`Select 1st ${selectedLabel || ""}`}</span>
                    </>
                  }
                  name="comparison_node_1"
                  className="mb-2 text-start"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Node"
                    defaultValue={null}
                    options={allNodes}
                  ></Select>
                </Form.Item>
              </Col>

              <Col md={6} className={showCompareNode ? "me-2" : "d-none"}>
                <Form.Item
                  label={
                    <>
                      <span>{`Select 2nd ${selectedLabel || ""}`}</span>
                    </>
                  }
                  name="comparison_node_2"
                  className="mb-2 text-start"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Node"
                    defaultValue={null}
                    options={allNodes}
                  ></Select>
                </Form.Item>
              </Col>

              <Col md={3} className={showSubmit ? "" : "d-none"}>
                <Form.Item className="py-4 mb-0">
                  <Button
                    className="mx-auto d-block cityMap-submit"
                    htmlType="submit"
                    disabled={confirmLoading}
                  >
                    {confirmLoading
                      ? "Please Wait"
                      : showCompareNode
                      ? "Compare"
                      : "City Map"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

        {showGraph && (
          <GraphContainer
            setShowGraph={setShowGraph}
            cityMapQuery={cityMapQuery}
            formData={formData}
            setLoading={setLoading}
            selectedFilterNodes={selectedFilterNodes}
            setFilterNodes={setFilterNodes}
            setCityMapQuery={setCityMapQuery}
          />
        )}

        {showTwoNodeGraph && (
          <Row>
            <Col sm={12} md={12}>
                {showTwoNodeGraph1 && (
                  <GraphContainerNode1
                    setShowTwoNodeGraph={setShowTwoNodeGraph}
                    setShowTwoNodeGraph1={setShowTwoNodeGraph1}
                    cityMapNode1Query={cityMapNode1Query}
                    formData={formData}
                    setLoading={setLoading}
                    selectedFilterNodes={selectedFilterNodes}
                    setFilterNodes1={setFilterNodes1}
                    setCityMapNode1Query={setCityMapNode1Query}
                    legendsLabel={legendsLabel}
                  />
                )
              }
              
            </Col>
            <Col sm={12} md={12}>
            {showTwoNodeGraph2 && (
              <GraphContainerNode2
                setShowTwoNodeGraph={setShowTwoNodeGraph}
                setShowTwoNodeGraph2={setShowTwoNodeGraph2}
                cityMapNode2Query={cityMapNode2Query}
                formData={formData}
                setLoading={setLoading}
                selectedFilterNodes={selectedFilterNodes}
                setFilterNodes2={setFilterNodes2}
                setCityMapNode2Query={setCityMapNode2Query}
              />
            )}
            </Col>
          </Row>
        )}

        {/* City Map Filter */}
        <Button
          className="filter-btn btn-outline"
          onClick={() => setshowFilter(true)}
        >
          Filters
        </Button>
        <Filter
          showFilter={showFilter}
          setshowFilter={setshowFilter}
          setLoading={setLoading}
          filterNodes={filterNodes}
          filterNodes1={filterNodes1}
          filterNodes2={filterNodes2}
          selectedFilterNodes={selectedFilterNodes}
          setSelectedFilterNodes={setSelectedFilterNodes}
          setCityMapQuery={setCityMapQuery}
          formData={formData}
          setShowGraph={setShowGraph}
          filterRelations={filterRelations}
          setlegendsLabel={setlegendsLabel}
        />
      </Spin>
  );
};

export default CityMap;
