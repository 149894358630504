import './Home.scss';
import { Col, Flex, Layout, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import HomeNotifications from './home-v2/HomeNotifications';
import HomeCharts from './home-v2/HomeCharts';
import RecentlyViewed from './home-v2/RecentlyViewed';
import HomeQuickLink from './HomeQuickLink';
import HomeShortVideoGuide from './home-v2/HomeShortVideoGuide';
import HomeQuickStart from './HomeQuickStart';
import HomePageServices from '../../services/services/HomePageServices';
import { useNotificationProvider } from '../../Context/NotificationProvider';
import HomeWelocmeVideo from './home-v2/HomeWelocmeVideo';
import GlobalSearchServices from '../../services/services/GlobalSearchServices';

const HomeV2 = ({...props}) => {

    const [notificationData, setNotificationData] = useState([]);
    const [lifeCyclechartData, setLifeCycleChartData] = useState([]);
    const [scenarioChartData, setScenarioChartData] = useState([]);
    const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);
    const [welcomeVideoURL, setWelcomeVideoURL] = useState("");

    const { state: notificatioDataProvider, dispatch: dispatchNotificatioData } = useNotificationProvider();

    const getNotificationData = async () => {
        //asda
        const email = localStorage.getItem('email')
        if (email) {
            const data = {
                "userid": email
            }
            const notificationResult = await HomePageServices.getNotificationData(data);
            if (notificationResult.status === 200) {
                setNotificationData(notificationResult?.data)
                await dispatchNotificatioData({ type: "SET_DATA", payload: notificationResult?.data });
            }
            else {
                message.error(notificationResult.message);
            }
        }
    }
 
    const getApplicationLifeCycle_ChartData = async () => {
        const response = await HomePageServices.getApplicationLifeCycleChartData({});

        if (response?.data?.length) {
            setLifeCycleChartData(response?.data);
        } else {

        }
    }
 
    const getScenario_ChartData = async () => {
        const response = await HomePageServices.getScenarioChartData({});

        if (response?.data?.length) {
            setScenarioChartData(response?.data);
        } else {

        }
    } 

    const getwelcomeVideo=async()=>{

        const response = await GlobalSearchServices.FetchWelcomeVideo_ForHome({});
 
        if (response?.data?.length) {
            setWelcomeVideoURL(response?.data[0].videourl);
        } else {

        }
    }

    useEffect(() => {
        getNotificationData();
        getApplicationLifeCycle_ChartData();
        getScenario_ChartData();
        if(sessionStorage.getItem("showWelcomeVideo")==="true"){
            //sessionStorage.setItem("showWelcomeVideo",false);            
        }else{
            setShowWelcomeVideo(false);
        }
        //Welcome Video
        getwelcomeVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Layout className='home-v2'>
                <Layout.Content>
                    <div className="container-fluid pt-2">
                        <Flex gap={0} vertical>
                            <Row gutter={[8,8]}>
                                { ( showWelcomeVideo) && 
                                    <HomeWelocmeVideo welcomeVideoURL={welcomeVideoURL}/>}
                                <HomeNotifications notificationData={notificationData} getNotificationData={getNotificationData} />
                                <RecentlyViewed />
                                <HomeCharts lifeCyclechartData={lifeCyclechartData} scenarioChartData={scenarioChartData} />
                            </Row>
                        </Flex>
                    </div>
                </Layout.Content>
                <Layout.Sider theme='light' className='border-left px-4 pt-2' style={{ minHeight: window?.innerHeight - 50 }} width={300}>
                    <HomeQuickLink />
                    <HomeShortVideoGuide />
                    {/* <HomeQuickStart /> */}
                </Layout.Sider>
            </Layout>
        </React.Fragment>
    )
}

HomeV2.defaultProps = {

}

export default HomeV2
