import React, { useEffect, useState } from 'react'
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import {getMenuPortfolio} from '../../../utils/settingCommon'
import "./Integration.scss";
import IntegrationGrid from './IntegrationGrid';
import IntegrationList from './IntegrationList';
import { Button,Flex,Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import bulkSvg from './../../../assets/Add-multiple.svg';
import { FormOutlined } from '@ant-design/icons';
import { ListSelectAllCheckboxComponent, showViewType } from '../common/PortfolioCommon';
import { checkRole } from '../../../helper/useUserData';

const Integration = ({moduleName}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activePage,setActivePage] = useState(0);
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});   
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectAllChecked,setSelectAllChecked] = useState(false);
    
    useEffect(()=>{
        setActivePage(1)
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const heading = moduleData?.name || moduleName;

    useEffect(()=>{
        setLoading(true);
        setSelectAllChecked(false);
        setLoading(false);
    },[lists])

    const onCheckAllChange = (checked) => {
        setLoading(true);
        setTimeout(() => {
            if(checked){
                setBulkUpdateIds([...bulkUpdateIds,...lists?.map(v=>v.id)])
            }else{
                setBulkUpdateIds([])
            }
            setSelectAllChecked(checked);
            setLoading(false);
        }, lists?.length*5);
    }

    const Buttons = ({bulkUpdateIds}) => {
        return <React.Fragment>
            <ListSelectAllCheckboxComponent onCheckAllChange={onCheckAllChange} selectAllChecked={selectAllChecked}/>
            {checkRole('add') && <Tooltip title="Bulk Entry">
                <Button type='default' icon={<img src={bulkSvg} alt="Bulk" width={26} />} onClick={()=>{ navigate(location?.pathname+'/bulkentry',{ state: { from:location?.pathname,defaultActiveKey:1}}) }}></Button>
            </Tooltip>}
            {
                ((()=>{
                    return checkRole('update') && <Tooltip title="Bulk Update">
                            <Button disabled={bulkUpdateIds?.length>0 ? false : true} type='default' icon={<FormOutlined style={{color:'#2a609d'}}/>} onClick={()=>{ navigate(location?.pathname+'/bulkentry',{ state: { from:location?.pathname,defaultActiveKey:1,bulkUpdateIds}}) }}></Button>
                        </Tooltip>
                })())
            }
        </React.Fragment>
    };

    const Headers = ({bulkUpdateIds}) =>{
        return <BusinessProcessHeader 
            activePage={activePage}
            setActivePage={setActivePage}
            heading={heading}
            addBtnTooltip={`Add New`}
            Buttons={<Buttons bulkUpdateIds={bulkUpdateIds}/>}
            grid={false}
            list={false}
            filterOptions={filterOptions}                   
            bulk_delete={bulkDeleteButton}
            setDeleteStart={setDeleteStart}
        />
    }

    return (
        <div className='integration-page'>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <IntegrationGrid 
                HeaderContnet = {Headers}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <IntegrationList
                    HeaderContnet = {Headers}
                    moduleName={moduleName}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}                    
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                    bulkUpdateIds={bulkUpdateIds}
                    setBulkUpdateIds={setBulkUpdateIds}
                    setLists={setLists}
                    loading={loading}
                />
            }
        </div>
    )
}

export default Integration