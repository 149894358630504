import React, { createContext, useContext, useReducer } from "react";
const StateContext = createContext();
export const useSaveState = () => useContext(StateContext);

const initialState = {
  menuDetail: {},
};

const stateReducer = (state, action) => {
  let tempState = {...state}
  if(action?.key){
    tempState[action?.key] = action?.value
  }else if(action?.removeKey){
    delete tempState[action?.removeKey];
  }else{
    tempState = {...state,...action}
  }

  return tempState;
};

export const StateProviderData = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};
