import { Col, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import shortVideo from './../../../assets/CA TOOL 2.mp4'
import { PageTitleHeading } from '../../../utils/Common';
const { Panel } = Collapse;

const HomeWelocmeVideo = ({welcomeVideoURL}) => {
    const [ collapsed, onCollapsed ] = useState([1]);
    const [videoUrl, setVideoUrl]=useState();

    useEffect(()=>{
        if (welcomeVideoURL)
        { 
            setVideoUrl(welcomeVideoURL);
        }
    },[welcomeVideoURL]);
 
    return (
        <React.Fragment>
            <Col span={24}>
                <Collapse defaultActiveKey={['1']} size="small" bordered={true}  expandIconPosition="end" onChange={(v)=>{ onCollapsed(v); }}>
                    <Panel header={<PageTitleHeading text={"Welcome to  Connected Architecture"} className={`my-0`}/>} key="1" showArrow={true}  style={{height:collapsed?.length>0?320:35}}>
                     { videoUrl &&(     <div className="embed-responsive embed-responsive-16by9">
                            <video width="320" height="240" loop={true} autoPlay={false} muted={true} controls style={{height:300}}>
                                <source src={videoUrl} type="video/mp4" className="embed-responsive-item mt-0 pt-0"/>
                                <source src={ videoUrl} type="video/ogg" className="embed-responsive-item mt-0 pt-0"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>)
                    }
                    </Panel>
                </Collapse>
            </Col>
        </React.Fragment>
    )
}

export default HomeWelocmeVideo
