import React, { createContext, useContext, useReducer } from "react";
const ChangeLogsApplicationContext = createContext();
export const useApplicationChangeLogs = () => useContext(ChangeLogsApplicationContext);

const initialState = {
  data: [],
  loading: false,
  error: false,
  businessApplicationId: null,
};

const changeLogsReducer = (state, action) => {
  switch (action.type) {
    case "SET_LINK":
      return {
        state,
        data: action.payload,
      };
     
    case "EMPTY_LINK":
      return {
        ...state,
        data: [],
        loading: false,
        error: false,
        businessApplicationId: null,
      };
    case "SET_BUSINESSAPPLICATIONID":
      return {
        ...state,
        businessApplicationId: action.payload,
      };
    default:
      return state;
  }
};

export const ChangeLogsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(changeLogsReducer, initialState);

  return (
    <ChangeLogsApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </ChangeLogsApplicationContext.Provider>
  );
};
