import { Col, Collapse } from 'antd';
import React,{ useState } from 'react';
 
import LifeCycleStatusChart from './LifeCycleStatusChart';
import ScenarioChart from './ScenarioChart';
import HomePortfolioCharts from './HomePortfolioCharts';
import { PageTitleHeading } from '../../../utils/Common'

const HomeCharts = ({ lifeCyclechartData, scenarioChartData,...props }) => {
    const { Panel } = Collapse;
    const [expandIconPosition] = useState('end');
  
    return (
        <React.Fragment>
            <Col span={24}>
                <div className='homePortfolioSummary'>
                    <Collapse defaultActiveKey={['1']} expandIconPosition={expandIconPosition} size="small" bordered>
                        <Panel header={<PageTitleHeading text={"Portfolio Summary"} className={`my-0`}/>} key="1">
                            <HomePortfolioCharts />
                        </Panel>
                    </Collapse>
                </div>
            </Col>
            <Col span={12}>
                <div className='homelifecyclestatus'>
                    <Collapse defaultActiveKey={['1']} expandIconPosition={expandIconPosition} size="small" bordered>
                        <Panel header={<PageTitleHeading text={"Application Status Summary"} className={`my-0`}/>} key="1">
                            <LifeCycleStatusChart lifeCyclechartData={lifeCyclechartData} />
                        </Panel>
                    </Collapse>
                </div>
            </Col>
            <Col span={12}>

                <div className='homescenarios'>
                    <Collapse defaultActiveKey={['1']} expandIconPosition={expandIconPosition} size="small" bordered>
                        <Panel header={<PageTitleHeading text={"Scenarios Status Summary"} className={`my-0`}/>} key="1" >
                            <ScenarioChart scenarioChartData={scenarioChartData} />
                        </Panel>
                    </Collapse>
                </div>
            </Col>
        </React.Fragment>
    )
}

export default HomeCharts