import HomeLayout from "../components/home/Home";
import HomeV2 from "../components/home/HomeV2";

function HomePage(props) {

    return (
        <div className="vp">
            {/* <HomeLayout props={props} /> */}
            <HomeV2 {...props} />
        </div>
    );
}

export default HomePage;