import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL


async function getChangeLogsList({ moduleName, modelId }) {
    
     const url = `${API_BASE_URL}changelogs?module_name=${moduleName}&modelID=${modelId}`;
  
    let response = await http.get(url);
    return response;
}

async function createChangeLogs(data) {
    const url = `${API_BASE_URL}changelogs`;
    let response = await http.post(url, data);
    
    return response;
}


const ChnageLogsServices = {
    getChangeLogsList,
    createChangeLogs 
}


export default ChnageLogsServices;
