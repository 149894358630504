import React from 'react';

const BpmnMxGraph = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  const poolMap = {};
  const nodes = [];
  const edges = [];
  let nodeIdCounter = 1;
  let edgeIdCounter = 1;
  const poolNodeData = {};

  // Define pool names and their order
  const poolOrder = ['Events', 'Data', 'Intake', 'Main'];

  // Initialize poolNodeData for Data and Events
  poolOrder.forEach((poolName, index) => {
    const poolId = `pool_${poolName.replace(/\s+/g, '_')}`;
    poolMap[poolName] = poolId;
    poolNodeData[poolId] = [];

    nodes.push({
      id: poolId,
      label: poolName,
      style: 'swimlane;startSize=20;horizontal=0;html=1;',
      geometry: { x: 0, y: index * 180, width: 0, height: 180 }
    });
  });

  // Generate nodes and pools
  data.forEach(item => {
    const poolId = item?.swimline;
    const nodeId = `node_${nodeIdCounter++}`;
    const poolNodeId = poolMap[poolId] || `pool_${poolId.replace(/\s+/g, '_')}`;

    // Add pool if not exists and is neither Data nor Events
    if (!poolMap[poolId] && poolId !== 'Data' && poolId !== 'Events') {
      poolMap[poolId] = poolNodeId;
      poolNodeData[poolNodeId] = [];
      const poolIndex = poolOrder.indexOf(poolId);
      nodes.push({
        id: poolNodeId,
        label: poolId,
        style: 'swimlane;startSize=20;horizontal=0;html=1;',
        geometry: { x: 0, y: (poolIndex !== -1 ? poolIndex : poolOrder.length) * 180, width: 0, height: 180 }
      });
    }

    if (poolId !== 'Data' && poolId !== 'Events') {
      const nodeData = {
        id: nodeId,
        label: item?.stepname,
        style: 'shape='+item?.shape+';'+item?.mxcelldetails,
        parent: poolNodeId,
        geometry: { x: (nodeIdCounter - 1) * 150, y: 60, width: item?.shapewidth, height: item?.shapeheight }
      };
      nodes.push(nodeData);
      poolNodeData[poolNodeId].push(nodeData);
    }
    
    if (item?.datavalue) {
      const dataPoolId = poolMap['Data'];
      const dataValues = item?.datavalue.split(',').map(d => d.trim()).filter(d => d);

      dataValues.forEach(value => {
        const dataNodeId = `node_${nodeIdCounter++}`;
        const dataNodeData = {
          id: dataNodeId,
          label: value,
          style: 'shape='+item?.datashape+';'+item?.datavaluecell,
          parent: dataPoolId,
          geometry: { x: (nodeIdCounter - 1) * 150, y: 60, width: item?.datashapewidth, height: item?.datashapeheight }
        };
        nodes.push(dataNodeData);
        poolNodeData[dataPoolId].push(dataNodeData);
      });
    }

    if (item.events) {
      const eventsPoolId = poolMap['Events'];
      const eventsValues = item?.events.split(',').map(d => d.trim()).filter(d => d);

      eventsValues.forEach(value => {
        const eventNodeId = `node_${nodeIdCounter++}`;
        const eventNodeData = {
          id: eventNodeId,
          label: value,
          links: item?.eventlinks,
          style: 'shape='+item?.eventshape+';'+item?.eventvaluecell,
          parent: eventsPoolId,
          geometry: { x: (nodeIdCounter - 1) * 150, y: 60, width: item?.eventshapewidth, height: item?.eventshapeheight }
        };
        nodes.push(eventNodeData);
        poolNodeData[eventsPoolId].push(eventNodeData);
      });
    }
  });

  // Calculate and update pool widths
  const poolWidths = {};
  Object.keys(poolNodeData).forEach(poolId => {
    const poolNodes = poolNodeData[poolId];
    const maxWidth = poolNodes.reduce((max, node) => Math.max(max, node.geometry.x + node.geometry.width), 0);
    poolWidths[poolId] = maxWidth + 30; // Adding some padding
  });

  const maxPoolWidth = Math.max(...Object.values(poolWidths));

  Object.keys(poolNodeData).forEach(poolId => {
    const pool = nodes.find(n => n.id === poolId);
    if (pool) {
      pool.geometry.width = maxPoolWidth;
    }
  });

  // Generate edges
  data.forEach(item => {
    const fromId = nodes.find(n => n.label === item.stepname)?.id;
    const toIds = (item?.followingsteps || "").split(';').map(step => step.trim()).filter(step => step).map(step => {
      return nodes.find(n => n.label === step)?.id;
    }).filter(id => id);

    toIds.forEach(toId => {
      // Get the source and target nodes
      const sourceNode = nodes.find(n => n.id === fromId);
      const targetNode = nodes.find(n => n.id === toId);

      // Calculate sourcePoint and targetPoint
      const sourceX = sourceNode.geometry.x + sourceNode.geometry.width / 2;
      const sourceY = sourceNode.geometry.y + sourceNode.geometry.height / 2;
      const targetX = targetNode.geometry.x + targetNode.geometry.width / 2;
      const targetY = targetNode.geometry.y + targetNode.geometry.height / 2;

      edges.push({
        id: `edge_${edgeIdCounter++}`,
        source: fromId,
        target: toId,
        style: 'edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;endArrow=block;endFill=1;',
        sourcePoint: { x: sourceX, y: sourceY },
        targetPoint: { x: targetX, y: targetY }
      });
    });

    // Handle edges between data pool values and other nodes
    const dataPoolIds = (item?.datavalue || "").split(',').map(d => d.trim()).filter(d => d).map(value => {
      return nodes.find(n => n.label === value && n.parent === poolMap['Data'])?.id;
    }).filter(id => id);

    dataPoolIds.forEach(dataPoolId => {
      const sourceNode = nodes.find(n => n.id === dataPoolId);
      const targetIds = item?.stepname.split(';').map(step => step.trim()).filter(step => step).map(step => {
        return nodes.find(n => n.label === step)?.id;
      }).filter(id => id);

      targetIds.forEach(targetId => {
        const targetNode = nodes.find(n => n.id === targetId);

        // Calculate sourcePoint and targetPoint
        const sourceX = sourceNode.geometry.x + sourceNode.geometry.width / 2;
        const sourceY = sourceNode.geometry.y + sourceNode.geometry.height / 2;
        const targetX = targetNode.geometry.x + targetNode.geometry.width / 2;
        const targetY = targetNode.geometry.y + targetNode.geometry.height / 2;

        edges.push({
          id: `edge_${edgeIdCounter++}`,
          source: targetId,
          target: dataPoolId,
          style: 'edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;endArrow=block;endFill=1;',
          sourcePoint: { x: sourceX, y: sourceY },
          targetPoint: { x: targetX, y: targetY }
        });
      });
    });

    // Handle edges involving nodes in the Events pool
    const eventsPoolId = poolMap['Events'];
    const eventIds = (item?.events || "").split(',').map(d => d.trim()).filter(d => d).map(value => {
      return nodes.find(n => n.label === value && n.parent === eventsPoolId)?.id;
    }).filter(id => id);

    eventIds.forEach(eventId => {
      const sourceNode = nodes.find(n => n.id === eventId);
      const targetIds = item?.stepname.split(';').map(step => step.trim()).filter(step => step).map(step => {
        return nodes.find(n => n.label === step)?.id;
      }).filter(id => id);

      targetIds.forEach(targetId => {
        const targetNode = nodes.find(n => n.id === targetId);

        // Calculate sourcePoint and targetPoint
        const sourceX = sourceNode.geometry.x + sourceNode.geometry.width / 2;
        const sourceY = sourceNode.geometry.y + sourceNode.geometry.height / 2;
        const targetX = targetNode.geometry.x + targetNode.geometry.width / 2;
        const targetY = targetNode.geometry.y + targetNode.geometry.height / 2;

        edges.push({
          id: `edge_${edgeIdCounter++}`,
          source: targetId,
          target: eventId,
          style: 'edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;endArrow=block;endFill=1;',
          sourcePoint: { x: sourceX, y: sourceY },
          targetPoint: { x: targetX, y: targetY }
        });
      });
    });
  });

  // Create XML for mxGraphModel
  let xml = `<?xml version="1.0" encoding="UTF-8"?><mxGraphModel dx="794" dy="454" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="850" pageHeight="1100" math="0" shadow="0"><root>`;

  // Add the default cells
  xml += `<mxCell id="0"/><mxCell id="1" parent="0"/>`;

  // Add nodes (including pools) in the correct order
  nodes.forEach(node => {
    if (node.links && node.links != '') {
      xml += `<UserObject label="${node.label}" link="${node.links}" id="${node.id}">`;
      xml += `\t<mxCell style="${node.style}" parent="${node.parent || '1'}" vertex="1">`;
      xml += `\t\t<mxGeometry x="${node.geometry.x}" y="${node.geometry.y}" width="${node.geometry.width}" height="${node.geometry.height}" as="geometry"/>`;
      xml += `\t</mxCell>`;
      xml += `</UserObject>`;
    } else {
      xml += `<mxCell id="${node.id}" value="${node.label}" style="${node.style}" vertex="1" parent="${node.parent || '1'}">`;
      xml += `\t<mxGeometry x="${node.geometry.x}" y="${node.geometry.y}" width="${node.geometry.width}" height="${node.geometry.height}" as="geometry"/>`;
      xml += `</mxCell>`;
    }
  });

  // Add edges
  edges.forEach(edge => {
    xml += `<mxCell id="${edge.id}" style="${edge.style}" edge="1" parent="1" source="${edge.source}" target="${edge.target}">`;
    xml += `\t<mxGeometry relative="1" as="geometry">`;
    xml += `\t\t<mxPoint as="sourcePoint" x="${edge.sourcePoint.x}" y="${edge.sourcePoint.y}"/>`;
    xml += `\t\t<mxPoint as="targetPoint" x="${edge.targetPoint.x}" y="${edge.targetPoint.y}"/>`;
    xml += `\t</mxGeometry>`;
    xml += `</mxCell>`;
  });

  xml += '</root></mxGraphModel>';
  return xml;
};

export default BpmnMxGraph;
