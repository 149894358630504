import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Modal,
  Tooltip,
  message,
  Button,
  Select,
  Space,
} from "antd";
import {
  getScenarioType,
  getScenarioSubType,
  getInitiatives,
} from "../../utils/ScenarioCommon";
import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ScenarioServices from "../../services/services/ScenarioServices";
import FormFields from "../../utils/FormFields";
import { useNavigate, useParams } from "react-router";
import {
  PageTitleHeading,
  convertObjectToArray,
  groupedData,
} from "../../utils/Common";
import useUserData from "../../helper/useUserData";
import useScenarioFetch from "../../hooks/useScenarioFetch";
import { useScenarioList } from "../../Context/ScenarioListProvider";
import ScenarioItemsServices from "../../services/services/ScenarioItemsService";
import CopyExistingScenario from "./copyExisting/CopyExistingScenarioItems";
import LoadingBar from "react-top-loading-bar";
import { SaveRecentlyView_Portfolio } from "../../utils/ResentViewedPortfolio";
import { useMenu } from "../../Context/MenuProvider";
import CreatableSelect from "react-select/creatable";
import FormItem from "antd/es/form/FormItem";
import { SaveRecentlyView_Scenario } from "../../utils/Common";

const formLayout = { labelCol: { span: 10 }, wrapperCol: { span: 14 } };

const initialValues = {
  scenarioName: "",
  scenarioTypeID: "1",
  scenarioSubtypeID: null,
  description: "",
  intiativeId: null,
  visibility: 1,
  copyFrom: 1,
  displayType: "tabular",
};

const StartNewScenarioV2 = (props) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [newScenarioForm] = Form.useForm();
  const [initiativeForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedScenarioAttr, setSelectedScenarioAttr] = useState({
    typeId: "1",
    subTypeId: "",
  });
  const [scenarioType, setScenarioType] = useState([]);
  const [scenarioSubType, setScenarioSubType] = useState([]);
  const [initiatives, setInitiatives] = useState([]);
  const [openExistingScenario, setOpenExistingScenario] = useState(false);
  const [selectedScenarioId, setSelectedScenarioId] = useState("");
  const [openInitiativeModal, setOpenInitiativeModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const userData = useUserData();
  const { id: paramsId = null } = useParams();

  const { dispatch: scenarioDispatch, state: scenarioState } =
    useScenarioList();
  const [filterOption, setFilterOption] = useState({
    search: "",
    page: 1,
    limit: 200,
    isScenarioType: true,
    isUser: true,
  });
  const [copyFromExist, setCopyFromExist] = useState([]);
  const [showExistingScenario, setShowExistingScenario] = useState(false);
  const [existingScenarioItems, setExistingScenarioItems] = useState({});
  const [openExScenarioModal, setOpenExScenarioModal] = useState(false);
  const [scenarioItem, setScenarioItem] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [scenarioItemLoading, setScenarioItemLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  const { stateMenu, dispatchMenu } = useMenu();
  // Memoize the result of usePortfolioRelationshipFetch
  const loadingState = useScenarioFetch(filterOption);

  let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
  openedScenarios = JSON.parse(openedScenarios);

  const [recentlyOpenScenarios] = useState(openedScenarios || []);
  const [userDataList, setUserDataList] = useState([]);
  const [userDataSelectOption, setUserDataSelectOption] = useState([]);
  const [collaborateList, setCollaborateList] = useState([]);
  const [approverList, setApproverList] = useState([]);
  //const [defaultCollaborateList, setDefaultCollaborateList] = useState([]);
  //const [defaultApproverList, setdefaultApproverList] = useState([]);

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    getScenarioType(loading, setLoading, setScenarioType);
    getScenarioSubType(loading, setLoading, setScenarioSubType);
    getInitiatives(loading, setLoading, setInitiatives);
    userList();
  }, []);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  //useEffect (()=>{
      //console.log("asdasdasd", defaultCollaborateList,defaultApproverList)
  //},[defaultCollaborateList,defaultApproverList])

  const getScenarioById = async () => {
    try {
      setLoading(true);

      const paramsObj = {
        id: paramsId,
        isScenarioType: true,
        isUser: true,
        isInitiative: true,
      };

      const response = await ScenarioServices.getOneScenarioById(paramsObj);
      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || {};

        const {
          intiativeId,
          name,
          visibility,
          scenarioTypeID,
          description,
          user,
          scenarioType,
          initiative,
          approvers,
          collaborators,
        } = responseData;
 

        //setDefaultCollaborateList(collaborators);
        //setdefaultApproverList(approvers);

        setCollaborateList(collaborators);
        setApproverList(approvers);

        
        const resolvedInitiativeId =
          initiative?.id?.toString() === "0" || intiativeId?.toString() === "0"
            ? null
            : initiative?.id?.toString() || intiativeId?.toString();

        const newValues = {
          scenarioName: name,
          scenarioTypeID:
            scenarioType?.id?.toString() || scenarioTypeID?.toString(),
          intiativeId: resolvedInitiativeId,
          description: description,
          visibility: visibility,
        };

        console.log(newValues);
        setInitialFormValues((prevValues) => ({
          ...prevValues,
          ...newValues,
        }));
        console.log("responseData", responseData);
      } else {
        message.error("Scenario not found or deleted.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario :", error);
    } finally {
      setLoading(false);
      if (ref.current) {
        ref.current.complete();
      }
    }
  };

  useEffect(() => {
    if (paramsId) {
      console.log("paramsId", paramsId);
      getScenarioById();
    } else {
      setInitialFormValues(initialValues);
      setCollaborateList([]);
      setApproverList([]);
    }
    if (ref.current) {
      ref.current.complete();
    }
  }, [paramsId]);

  useEffect(() => {
    newScenarioForm?.setFieldsValue({ ...initialFormValues });
  }, [initialFormValues]);

  useEffect(() => {
    if (scenarioState?.data?.length > 0) {
      setCopyFromExist(
        paramsId
          ? scenarioState?.data?.filter((item) => item?.id !== paramsId)
          : scenarioState?.data || []
      );
    }
    if (ref.current) {
      ref.current.complete();
    }
  }, [scenarioState]);

  const userList = async () => {
    const userList = await ScenarioServices.userList();
    if (userList?.data?.code === 200) {
      setUserDataList(userList?.data?.data);

      let options = [];
      userList?.data?.data?.map((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });

      setUserDataSelectOption(options);
    } else {
    }
  };

  const handleBlurName = (e) => {
    let value = e?.target?.value;
    let obj = {};
    obj.columnName = value;
    ScenarioServices.checkUniqueScenario(obj)
      .then((response) => {
        if (response.data.code === 200) {
          newScenarioForm.resetFields(["scenarioName"]);
          message.error("Scenario Name already exist.");
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
    if (ref.current) {
      ref.current.complete();
    }
  };
  const handleScenarioOption = (e) => {
    if (e?.target?.value === "existing") {
      setOpenExistingScenario(true);
    } else {
      setOpenExistingScenario(false);
      setSelectedScenarioId("");
    }
  };
  const onAddInitiativeSubmit = (formData) => {
    setLoading(true);
    let obj = {};
    obj.DisplayName = formData?.name;
    obj.tableName = "initiatives";
    let newInitiativesLength = initiatives?.filter(
      (f) =>
        f.label?.toLowerCase()?.trim() === formData?.name?.toLowerCase()?.trim()
    )?.length;
    if (newInitiativesLength > 0) {
      setLoading(false);
      return message.error("Initiative " + formData?.name + " already exist");
    }
    ScenarioServices.addInitiative(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          getInitiatives(loading, setLoading, setInitiatives);
          initiativeForm.resetFields();
          newScenarioForm.setFieldsValue({ intiativeId: "" });
        }
        setLoading(false);
        setOpenInitiativeModal(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
    if (ref.current) {
      ref.current.complete();
    }
  };

  //   const onFormSubmit = async (formData) => {
  //     const formValues = { ...formData, createdBy: userData?.name || "" };
  //     console.log("formData", formValues);
  //     return;
  //     return navigate(`/scenario-list-v2/1`);

  //     setLoading(true);
  //     const { displayType, ...newFormData } = formData;
  //     newFormData.intiativeId =
  //       formData?.intiativeId === null ? "" : formData?.intiativeId;
  //     newFormData.copy_from =
  //       selectedScenarioId === "" ? "" : formData?.copy_from;
  //     const selectCol = selectedColumns?.map((val) => {
  //       if (val?.is_mandatory === 0) {
  //         return {
  //           id: 0,
  //           name: val?.label,
  //           is_mandatory: val?.is_mandatory,
  //           referenceTable: val?.referenceTable,
  //           visibility: val?.visibility,
  //           prev_column_id: val?.prevColumnId || null,
  //         };
  //       } else {
  //         return {
  //           id: val?.value,
  //           name: val?.label,
  //           is_mandatory: val?.is_mandatory,
  //           referenceTable: val?.referenceTable,
  //           visibility: val?.visibility,
  //           prev_column_id: val?.prevColumnId || null,
  //         };
  //       }
  //     });

  //     newFormData.selectColumns = selectCol;
  //     newFormData.userEmail = localStorage.getItem("email");
  //     await ScenarioServices.createScenario(newFormData)
  //       .then((response) => {
  //         if (response?.data?.code === 200) {
  //           let responseVal = response?.data?.data;
  //           newScenarioForm.resetFields();
  //           setLoading(false);
  //           navigate(`/edit-scenario/${responseVal?.id}`);
  //         }
  //       })
  //       .catch((err) => {
  //         message.error(err.message);
  //         setLoading(false);
  //       });
  //   };
  const setRecentOpenScenario = (scenario) => {
    if (recentlyOpenScenarios?.length === 10) {
      recentlyOpenScenarios.shift();
    }
    let tempScenarios = recentlyOpenScenarios.filter(
      (f) => f?.id?.toString() !== scenario?.id?.toString()
    );
    tempScenarios.push(scenario);
    tempScenarios = JSON.stringify(tempScenarios);
    localStorage.setItem("recentlyOpenScenarios", tempScenarios);
  };

  const onFormSubmit = async (formData) => {
    try {
      
      const userID = localStorage.getItem("email");

      setLoading(true);
      const selectedValues = convertObjectToArray(selectedItems);
      if (
        selectedValues?.length === 0 &&
        formData?.copyFrom === 2 &&
        formData?.existingScenarioId
      ) {
        return message.info("Please select at least one portfolio item.");
      }
      const formValues = {
        ...formData,
        selectedScenarioItems: selectedValues,
        createdBy: userData?.email || null,
        collaborateList: collaborateList,
        approverList: approverList,
      };

      //console.log("formValues",formValues);return;

      const response =
        paramsId === null || paramsId === undefined
          ? await ScenarioServices.createScenarioV2(formValues)
          : await ScenarioServices.editScenarioV2({
              ...formValues,
              id: paramsId,
            });

      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;

        setRecentOpenScenario(response?.data?.data);

        message.success(`${paramsId ? "Updated" : "Created"} Successfully.`);
        await localStorage.setItem("scenarioName", formData?.scenarioName);

        SaveRecentlyView_Scenario(responseVal?.id, formData?.scenarioName);

        // Update Recent Open
        if (paramsId) {
          let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
          openedScenarios = JSON.parse(openedScenarios);
          openedScenarios = openedScenarios?.map((sce) => {
            if (paramsId?.toString() === sce?.id?.toString()) {
              return responseVal;
            }
            return sce;
          });
          let tempScenarios = JSON.stringify(openedScenarios);
          sessionStorage.setItem("recentlyOpenScenarios", tempScenarios);
        }

        setTimeout(async () => {
          newScenarioForm.resetFields();
          setLoading(false);
          setExistingScenarioItems({});
          setOpenExScenarioModal(false);
          setScenarioItem([]);
          return navigate(`/scenario-list-v2/${responseVal?.id}`);
        }, 500);
      } else {
        message.error(
          response?.data?.msg || "Failed to submit form. Please try again."
        );
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setLoading(false);
    }
    if (ref.current) {
      ref.current.complete();
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    if ("copyFrom" in changedValues) {
      setExistingScenarioItems({});
      if (allValues.copyFrom !== 2) {
        setSelectedItems([]);
        newScenarioForm.resetFields(["existingScenarioId"]);
      }
      setShowExistingScenario(allValues.copyFrom === 2);
    }
    if (ref.current) {
      ref.current.complete();
    }
  };

  const handleSelectChange = (value, name) => {
    const copiedItem =
      copyFromExist?.find((item) => item?.id.toString() === value.toString()) ||
      {};
    setExistingScenarioItems(copiedItem);
    setScenarioItem([]);
    setSelectedItems({});
    newScenarioForm.setFieldsValue({ [name]: value });
  };

  const getAllScenariosItems = async (scenarioId) => {
    try {
      setScenarioItemLoading(true);
      const response =
        await ScenarioItemsServices.getScenariosItemsByScenarioId(scenarioId);
      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        setScenarioItem(responseData);

        // Initialize selected items with all options selected by default
        if (responseData?.length > 0) {
          const groupedItems = groupedData(responseData);
          const initialSelectedItems = {};
          Object.keys(groupedItems).forEach((name) => {
            initialSelectedItems[name] = groupedItems[name].map(
              (item) => item.id
            );
          });
          setSelectedItems(initialSelectedItems);
        } else {
          setSelectedItems([]);
          message.error(
            `Scenario items not found for ${existingScenarioItems?.name}`
          );
        }
      } else {
        setScenarioItem([]);
      }
      setScenarioItemLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
    } finally {
      setScenarioItemLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(existingScenarioItems)?.length > 0) {
      console.log("paramsId", paramsId);
      if (!paramsId) {
        console.log("paramsId-hello", paramsId);
        getAllScenariosItems(existingScenarioItems?.id);
      }
    }
  }, [existingScenarioItems?.id]);

  useEffect(() => {
    if (scenarioItem && existingScenarioItems?.id) {
      setOpenExScenarioModal(scenarioItem?.length > 0);
    }
  }, [scenarioItem]);

  const handleSelectedItemsChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

  const handleChange = (tags) => { 
    setCollaborateList(tags);
    //setDefaultCollaborateList(tags);
    newScenarioForm.setFieldsValue({ collaborator: tags });
  };

  const handleChangeApprover = (tags) => {
    setApproverList(tags);
   // setdefaultApproverList(tags);
  };
 
  return (
    <>
      <LoadingBar color="#1f1f1f" ref={ref} />
      <div className="start-new-scenario-v2">
        <PageTitleHeading
          text={`${
            paramsId ? initialFormValues?.scenarioName : "New Scenario"
          }`}
        />
        <Spin className="loading_bx" size="small" spinning={loading}>
          <Form
            form={newScenarioForm}
            {...formLayout}
            requiredMark={false}
            className="px-5 py-3"
            initialValues={initialFormValues}
            onFinish={onFormSubmit}
            style={{ width: "80%" }}
            onValuesChange={handleValuesChange}
          >
            <FormFields
              type={`input`}
              label={
                <>
                  Name <span className="text-danger">*</span>
                </>
              }
              name={`scenarioName`}
              rules={[
                {
                  required: true,
                  message: "Please enter scenario name",
                },
              ]}
              placeholder={`Scenario Name`}
              // onBlur={handleBlurName}
              formClassName={`text-start mb-1`}
            />
            {/* <FormFields
              type={`radio`}
              label={
                <>
                  Type <span className="text-danger">*</span>
                </>
              }
              name={`scenarioTypeID`}
              rules={[
                {
                  required: true,
                  message: "Please select scenario type",
                },
              ]}
              formClassName={`text-start mb-1`}
              initialValue={"1"}
              options={scenarioType
                ?.sort((a, b) =>
                  a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: "base",
                  })
                )
                ?.map((e, i) => ({
                  ...e,
                  value: e?.value,
                  label: (
                    <span>
                      {e.label}
                      {e.desc && (
                        <Tooltip
                          title={e.desc}
                          color="#9ba894"
                          placement="right"
                          className="ms-2 text-primary"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      )}
                    </span>
                  ),
                }))}
            /> */}

            <hr className="mt-2 mb-3" />
          
            <FormFields
              type={`textarea`}
              label={
                <>
                  Description <span className="text-danger">*</span>
                </>
              }
              name={`description`}
              rules={[
                {
                  required: true,
                  message: "Please enter scenario description",
                },
              ]}
              formClassName={`text-start mb-1 pb-1`}
              initialValue={""}
              rows={3}
            />
            <hr className="mt-2 mb-3" />
            <Form.Item
              label={<>Initiative/Project</>}
              //name={`intiativeId`}
              className={`text-start mb-0`}
            >
              <Row gutter={8} className="mx-0 my-0">
                <Col span={24}>
                  <FormFields
                    type={`select`}
                    name={`intiativeId`}
                    placeholder="Select"
                    rules={[
                      {
                        required: false,
                        message: "Please select initiative",
                      },
                    ]}
                    formClassName={`text-start mb-0`}
                    optionFilterProp="label" // <--- Change this line
                    options={initiatives?.map((e) => {
                      return {
                        label: e?.label,
                        value: e?.value?.toString(),
                      };
                    })}
                    filterOption={(input, option) => {
                      return (option?.label?.toLowerCase() ?? "").includes(
                        input?.toLowerCase()
                      );
                    }}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        ?.localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
            <hr className="mt-2 mb-3" />
            
            {/* <FormFields
            type={`input`}
            label={`Linked to Initiate`}
            name={`linkedToInitiate`}
            rules={[{ required: true, message: "Please enter name" }]}
            className={`text-start`}
            formClassName={`text-start mb-1`}
            placeholder={`Linked to Initiate`}
            onBlur={handleBlurName}
          />
          <hr className="mt-2 mb-3" /> */}
            {/* <FormFields
              type={`Radio`}
              label={
                <>
                  Visibility <span className="text-danger">*</span>
                </>
              }
              name={`visibility`}
              rules={[
                {
                  required: true,
                  message: "Please select visibility",
                },
              ]}
              formClassName={`text-start mb-1`}
              initialValue={1}
              options={[
                { value: 1, label: `Public` },
                { value: 2, label: `Private` },
              ]}
            /> */}

            <Form.Item
              label={<>Collaborator</>}
              // rules={[
              //   {
              //     required: true,
              //     message: `Tag is required!`,
              //   },
              // ]}
              className={`text-start mb-0`}
            >
              <Row gutter={8} className="mx-0 my-0">
                <Col span={24}>
                  <Select
                    name={"collaborator"}
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                    value={collaborateList}
                    onChange={handleChange}
                    options={userDataSelectOption}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label={<>Approver</>}
              //name={`intiativeId`}
              className={`text-start mb-0`}
            >
              <Row gutter={8} className="mx-0 my-0">
                <Col span={24}>
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <Select
                      name={"approver"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      value={approverList}
                      onChange={handleChangeApprover}
                      options={userDataSelectOption}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Space>
                </Col>
              </Row>
            </Form.Item>

            <hr className="mt-2 mb-3" />

            {!paramsId && (
              <FormFields
                type={`Radio`}
                label={
                  <>
                    Scenario Options <span className="text-danger">*</span>
                  </>
                }
                name={`copyFrom`}
                rules={[
                  {
                    required: true,
                    message: "Please select scenario option",
                  },
                ]}
                formClassName={`text-start mb-1 hide-div`}
                initialValue={1}
                options={[
                  { value: 1, label: `Start from scratch` },
                  { value: 2, label: `Copy from existing` },
                ]}
              />
            )}
            {(showExistingScenario && (
              <Form.Item
                label="Pick Existing Scenario"
                //name={`existingScenarioId`}
                className={`text-start mb-0`}
              >
                <Row gutter={8} className="mx-0 my-0">
                  <Col span={scenarioItemLoading ? 20 : 24}>
                    <FormFields
                      type={`select`}
                      name={`existingScenarioId`}
                      placeholder="Select"
                      rules={[
                        {
                          required: showExistingScenario,
                          message: "Please select existing scenario",
                        },
                      ]}
                      formClassName={`text-start mb-0`}
                      optionFilterProp="label"
                      options={copyFromExist?.map((e) => {
                        return {
                          label: e?.name,
                          value: e?.id,
                        };
                      })}
                      disabled={scenarioItemLoading}
                      onChange={(value) => {
                        handleSelectChange(value, "existingScenarioId");
                      }}
                    />
                  </Col>
                  {scenarioItemLoading && (
                    <Col span={4}>
                      <Spin
                        indicator={
                          <LoadingOutlined className="font-[10px]" spin />
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Form.Item>
            )) || <div></div>}
            <hr className="mt-2 mb-3" />
            <Button
              type="primary"
              htmlType="submit"
              className="pull-left mt-4"
              size="large"
              loading={loading}
              disabled={scenarioItemLoading}
              iconPosition={"start"}
            >
              <span className="px-5">
                <span>{paramsId ? "Update" : "Submit"}</span> & Next
              </span>
            </Button>
          </Form>
        </Spin>
        {/* Initiative */}
        <Modal
          title={"Add Initiative"}
          open={openInitiativeModal}
          footer={false}
          destroyOnClose
          closable={true}
          maskClosable={false}
          onCancel={() => setOpenInitiativeModal(false)}
        >
          <Form
            form={initiativeForm}
            onFinish={onAddInitiativeSubmit}
            requiredMark={false}
            initialValues={{}}
          >
            <FormFields
              type={`input`}
              label={`Name`}
              name={`name`}
              rules={[
                {
                  required: true,
                  message: "Please enter initiative name",
                },
              ]}
              formClassName={`text-start mb-1`}
              placeholder="Initiative name"
            />
            <Form.Item>
              <Button
                className="ms-auto d-block pt-2"
                htmlType="submit"
                disabled={loading}
              >
                {loading ? "Please Wait" : "Save"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Copy existing scenario */}
        <Modal
          title={
            <span className="ml-3">{`Copy item from "${existingScenarioItems?.name}"`}</span>
          }
          open={openExScenarioModal}
          footer={
            <div className="mr-3">
              <Button
                type="primary"
                onClick={() => {
                  const selectedValues = convertObjectToArray(selectedItems);
                  console.log("selectedValues", selectedValues);
                  if (selectedValues?.length > 0) {
                    setOpenExScenarioModal(false);
                  } else {
                    message.info("Please select at least one portfolio item.");
                  }
                }}
              >
                Submit
              </Button>
            </div>
          }
          destroyOnClose
          closable={true}
          maskClosable={false}
          width={window.innerWidth / 2.2}
          onCancel={() => {
            setSelectedItems({});
            setOpenExScenarioModal(false);
            setExistingScenarioItems({});
            newScenarioForm.resetFields(["existingScenarioId"]);
          }}
        >
          <div className="p-3">
            <CopyExistingScenario
              setOpenExScenarioModal={setOpenExScenarioModal}
              openExScenarioModal={openExScenarioModal}
              data={scenarioItem}
              scenarioItem={existingScenarioItems}
              setSelectedItems={handleSelectedItemsChange}
              selectedItems={selectedItems}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};
export default StartNewScenarioV2;
