import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PageTitleHeading } from "../../../utils/Common";
import listSvg from "./../../../assets/List.svg";
import gridSvg from "./../../../assets/Two columns layout.svg";
import {
  Button,
  Card,
  Modal,
  Form,
  message,
  Spin,
  Flex,
  Pagination,
  Select,
  DatePicker,
  Tooltip,
  Row,
  Col,
  Tabs,
  Steps,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import ScenarioItemsServices from "../../../services/services/ScenarioItemsService";
import ScenarioServices from "../../../services/services/ScenarioServices";
import ScenarioPlanningList from "./ScenarioPlanningList";
import { getRecentlyView_ScenarioName } from "../../../utils/Common";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import {
  DownloadOutlined,
  NodeExpandOutlined,
  ExclamationCircleFilled,
  SendOutlined,
  UndoOutlined,
  CloseCircleOutlined,
  DislikeOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import FishboneIntegrationChart from "../portfolio/FishboneIntegrationChart";
import { elementToSVG, inlineResources } from "dom-to-svg";
//import htmlToSvg from "htmlsvg";
import PlusSvg from "../../../assets/Plus.svg";
import { RemoveRecentViewed } from "../../../utils/ResentViewedPortfolio";
import StepNavigator from "./StepNavigator";
import FormFields from "../../../utils/FormFields";

function ScenarioPlanning() {
  const navigate = useNavigate();
  const { id: scenarioId = null } = useParams();
  const [recordData, addRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scenarioName, setScenarioName] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  const [addPortfolioModel, setAddPortfolioModel] = useState(false);
  const [savePortfoloiLoading, setSavePortfoloiLoading] = useState(false);
  const [portfolioItem, setPortfolioItem] = useState([]);
  const { Option } = Select;
  const [PortfolioForm] = Form.useForm();
  const [applyModificationToASIS, setApplyModificationToASIS] = useState(false);
  const [steps, setSteps] = useState([]);

  const [portfolioItemLength, setPortfolioItemLength] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [sendForApproval, setSendForApproval] = useState(false);
  const [resetToInprogress, setResetToInprogress] = useState(false);
  const [approval, setApproval] = useState(false);
  const [applyASIS, setApplyASIS] = useState(false);
  const [ctrlStatus, setCtrlStatus] = useState(false);
  const [rejectedModel, setRejectedModel] = useState(false);
  const [approvedModel, setApprovedModel] = useState(false);
  const [addPortfolioVisible, setAddPortfolioVisible] = useState(true);
  const [addApproverBtn, setaddApproverBtn] = useState(false);
  const [sendAction, setSendAction] = useState(false);

  const [changeTypeForm] = Form.useForm();
  const [sendForApprovalBtn, setSendForApprovalBtn] = useState(false);
  const [addApproverModel, setAddApproverModel] = useState(false);
  const [userDataList, setUserDataList] = useState([]);
  const [userDataSelectOption, setUserDataSelectOption] = useState([]);
  const [approverList, setApproverList] = useState([]);

  const scenarioLifecycleStage = [
    "In Progress",
    "Approval Pending",
    "Approved",
  ];

  useEffect(() => {
    getAllScenariosItems();
    getScenarioName();
    getRemovedPortfolioItems();
    setSteps(scenarioLifecycleStage);
    userList();
  }, [scenarioId]);

  const getScenarioName = async () => {
    const scenario = await getRecentlyView_ScenarioName(scenarioId);
    if (scenario) {
      setScenarioName(scenario[0]?.scenarioName);
    }
  };

  const userList = async () => {
    const userList = await ScenarioServices.userList();
    if (userList?.data?.code === 200) {
      setUserDataList(userList?.data?.data);

      let options = [];
      userList?.data?.data?.map((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });

      setUserDataSelectOption(options);
    } else {
    }
  };

  // const getScenarioById = async () => {
  //   try {
  //     const paramsObj = {
  //       id: scenarioId,
  //       isScenarioType: true,
  //       isUser: true,
  //       isInitiative: true,
  //     };

  //     const response = await ScenarioServices.getScenarioById(paramsObj);
  //     if (response && response?.data?.code === 200) {
  //       const responseData = response?.data?.data || [];

  //       if (responseData.length) {
  //         //const approvalstatus = "New";
  //         //const approvalstatus="In Progress";
  //         const approvalstatus="Approval Pending";
  //         //const approvalstatus="Approved";
  //         //const approvalstatus="Rejected";

  //         //const approvalstatus="Cancel";

  //         const { collaborators_email, approver_email } = responseData[0];

  //         setSendForApproval(false);
  //         if (approvalstatus === "New") {
  //           setCurrentStep(0);
  //         } else if (approvalstatus === "In Progress") {
  //           setCurrentStep(1);
  //           setSendForApproval(true);
  //         } else if (approvalstatus === "Approval Pending") {
  //           setCurrentStep(2);
  //         } else if (approvalstatus === "Approved") {
  //           //Remove  "Approval Pending", "Approved" and  Cancelled
  //           const updatedSteps = steps.slice(0, 2);
  //           updatedSteps.push("Approved");
  //           setSteps(updatedSteps);
  //           setCurrentStep(3);
  //         }
  //         else if (approvalstatus === "Rejected") {
  //           //Remove  "Approval Pending", "Approved" and  Cancelled
  //           const updatedSteps = steps.slice(0, 2);
  //           updatedSteps.push("Rejected");
  //           setSteps(updatedSteps);
  //           setCurrentStep(3);
  //         }
  //         else if (approvalstatus === "Cancel") {
  //           //Remove  "Approval Pending", "Approved" and  Cancelled
  //           const updatedSteps = steps.slice(0, 2);
  //           updatedSteps.push("Cancelled");
  //           setSteps(updatedSteps);
  //           setCurrentStep(3);
  //         }
  //       }
  //     } else {
  //       message.error("Scenario not found or deleted.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Scenario :", error);
  //   } finally {
  //   }
  // };

  const getAllScenariosItems = async () => {
    try {
      setLoading(true);
      const response = await ScenarioServices.getScenarioPlanningPortfolio(
        scenarioId
      );

      if (response && response?.status === 200) {
        //const responseData = response?.data?.data || [];
        const responseData = response?.data || [];
        await addRecordData(responseData);

        //handle scenariolife cycle
        handleScenarioLifeCycle(responseData);
      } else {
        message.error("Scenario items not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      // message.error("Something went wrong.");
      setLoading(false);
    }
  };

  const getRemovedPortfolioItems = async () => {
    try {
      const response = await ScenarioServices.fetchRemovedPortfolioToAdd({
        id: scenarioId,
      });

      if (response && response?.status === 200) {
        const responseData = response?.data.data || [];
        setPortfolioItem(responseData);
      } else {
        message.error("Portfolio items not found.");
      }
    } catch (error) {
      console.error("Error fetching Portfolio items:", error);
      // message.error("Something went wrong.");
      setLoading(false);
    }
  };

  const updateportfolioaddToscenario = async (data) => {
    try {
      setSavePortfoloiLoading(true);
      const response = await ScenarioServices.updateportfolioaddToscenario({
        scenarioid: scenarioId,
        portfolioid: data?.PortfolioId,
      });

      if (response && response?.status === 200) {
        message.success("Portfolio items added successfully.");
        PortfolioForm?.resetFields();
        getAllScenariosItems();
        getRemovedPortfolioItems();
        setAddPortfolioModel(false);
      } else {
        message.error("Portfolio items not found.");
      }
    } catch (err) {
      console.log("Error:" + err);
    } finally {
      setSavePortfoloiLoading(false);
    }
  };

  const handleScenarioLifeCycle = (data) => {
    if (data?.length) {
      //const approvalstatus = "New";
      //const approvalstatus = "In Progress";
      //const approvalstatus="Approval Pending";
      //const approvalstatus="Approved";
      //const approvalstatus = "Rejected";
      //const approvalstatus="Cancel";

      let totalItemsCount = data.reduce(
        (sum, obj) => sum + obj.Items.length,
        0
      );

      const userID = localStorage.getItem("email") || "";
      const {
        approvalstatus,
        collaborators_emails,
        approvers_emails,
        createdBy,
      } = data[0];
      const collArray = collaborators_emails?.includes(userID);
      const appArray = approvers_emails?.includes(userID);

      if (collArray || appArray) {
        setCtrlStatus(true);
      } else {
        setCtrlStatus(false);
      }

      setApplyASIS(false);
      setResetToInprogress(false);
      setSendForApproval(false);
      setApproval(false);
      setAddPortfolioVisible(true);

      // if (approvalstatus === "New") {
      //   setSteps(scenarioLifecycleStage);
      //   setCurrentStep(0);
      // } else

      if (approvalstatus === "In Progress") {
        setSteps(scenarioLifecycleStage);
        setCurrentStep(0);
        if (collArray || createdBy === userID) {
          setSendForApproval(true);
          setCtrlStatus(true);
        }else{
          setCtrlStatus(false);
        }
      } else if (approvalstatus === "Approval Pending") {
        setSteps(scenarioLifecycleStage);

        if (appArray) {
          setApproval(true);
          setCtrlStatus(false);
        }

        if (collArray) {
          setCtrlStatus(false);
        }
        setAddPortfolioVisible(false);

        setCurrentStep(1);
      } else if (approvalstatus === "Approved") {
        setSteps(scenarioLifecycleStage);
        if (collArray || createdBy === userID) {
          setResetToInprogress(true);
          setApplyASIS(true);
        }
        setAddPortfolioVisible(false);

        setCurrentStep(2);
        setCtrlStatus(false);
      } else if (approvalstatus === "Rejected") {
        // console.log(steps)
        setSteps(scenarioLifecycleStage);
        const updatedSteps = scenarioLifecycleStage.slice(0, 2);
        updatedSteps.push("Rejected");

        if (collArray || createdBy === userID) {
          setResetToInprogress(true);
        }
        setAddPortfolioVisible(false);

        setSteps(updatedSteps);
        setCurrentStep(2);
        setCtrlStatus(false);
      } else if (approvalstatus === "Cancel") {
        //Remove  "Approval Pending", "Approved" and  Cancelled
        setSteps(scenarioLifecycleStage);
        const updatedSteps = scenarioLifecycleStage.slice(0, 1);
        updatedSteps.push("Cancelled");

        if (collArray || createdBy === userID) {
          setResetToInprogress(true);
        }
        setAddPortfolioVisible(false);

        setSteps(updatedSteps);
        setCurrentStep(3);
        setCtrlStatus(false);
      }

      //check Portfolio items is zero  then disable all buttons

      if (totalItemsCount == 0) {
        setApplyASIS(false);
        setResetToInprogress(false);
        setSendForApproval(false);
        setApproval(false);
        setAddPortfolioVisible(true);
      }
    }
  };

  const sendforApproval = async () => {
    try {
      setSendForApprovalBtn(true);

      const url = window.location.href;

      const userData = localStorage.getItem("userData");
      const userDataJson = JSON.parse(userData);

      if (recordData[0]?.approvers_emails.length) {
        const paramsObj = {
          id: scenarioId,
          approverEmail: recordData[0]?.approvers_emails,
          collaboratorEmail: userDataJson?.email, //recordData[0]?.collaborators_emails,
          pageUrl: url,
          collaboratorName: userDataJson?.name,
          scenarioName: scenarioName,
        };

        const response = await ScenarioServices.updateScenarioV2SendForApproval(
          paramsObj
        );

        if (response.data.code === 200) {
          await getAllScenariosItems();
          setSendForApprovalBtn(false);
          setAddApproverModel(false);
          message.success("Scenario send for approval successfully");
        } else {
          message.error(response.data.msg);
          setSendForApprovalBtn(false);
        }
      } else {
        setAddApproverModel(true);
        setSendForApprovalBtn(false);
      }
    } catch (err) {}
  };

  const updateScenarioV2Stage = async (status1, RejectedReason) => {
    try {
      const url = window.location.href;
      const userData = localStorage.getItem("userData");
      const userDataJson = JSON.parse(userData);

      const paramsObj = {
        id: scenarioId,
        Status: status1,
        Reason: RejectedReason,
        approverEmail: recordData[0]?.approvers_emails,
        collaboratorEmail: recordData[0]?.collaborators_emails,
        pageUrl: url,
        ApproverName: userDataJson?.name,
        scenarioName: scenarioName,
      };

      const response = await ScenarioServices.updateScenarioV2LifecycleStage(
        paramsObj
      );

      if (response.data.code === 200) {
        await getAllScenariosItems();
        setSendAction(false);
        message.success("Updated successfully");
        setRejectedModel(false);
        setApprovedModel(false);
      } else {
        message.error(response.data.msg);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  // Filter portfolios items start
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  const exportContentImg = async () => {
    try {
      let heading = `Timeline View of Scenario ${scenarioName}`;
      // setShowFullContent(true)
      let svgString;
      await new Promise((resolve) => setTimeout(resolve, 500));
      // const svgConfig = {
      //   downloadSvg: true,
      //   // downloadPng: true,
      //   // convertDataUrl: true, // you need to convert images to dataurl if you wanna download png image
      //   filename: "htmltosvg",
      // };
      // const htmlElement = document.querySelector('.export-tab-'+activeTab)
      // const svg = await htmlToSvg(htmlElement, svgConfig);
      const svgDocument = elementToSVG(
        document.querySelector(".export-tab-" + activeTab)
      );

      // Inline external resources (fonts, images, etc) as data: URIs
      await inlineResources(svgDocument.documentElement);

      // Get SVG string
      // svgString = svg;
      svgString = new XMLSerializer().serializeToString(svgDocument);

      var svgBlob = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8",
      });
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = heading + ".svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // setShowFullContent(false)
    } catch (error) {
      // setShowFullContent(false)
    } finally {
      // setShowFullContent(false)
    }
  };

  const ApplyModificationsToASIS = async () => {
    setApplyModificationToASIS(true);
    try {
      setTimeout(() => {}, 2000);
      const userID = localStorage.getItem("email") || "";

      const data = await ScenarioServices.applymodificationas({
        scenario_id: scenarioId,
        UserID: userID,
      });

      if (data.status === 200) {
        message.success("Modification applied successfully");

        ////////////////////////////////////////////////////////////////////////////////////////////////
        //Remove  from Scenario  Recent View Tab
        // let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
        // openedScenarios = JSON.parse(openedScenarios);

        // let updatedScenarios = openedScenarios.filter(
        //   (scenario) => scenario.id !== scenarioId
        // );

        // updatedScenarios = JSON.stringify(updatedScenarios);
        // localStorage.setItem("recentlyOpenScenarios", updatedScenarios);

        // //Remove From Home Screen
        // RemoveRecentViewed({ modelName: "Scenario", modelID: scenarioId });

        await getAllScenariosItems();

        //Back to Scenario List
        // setTimeout(() => {
        //   navigate(`/list-scenario`);
        // }, 2000);
      } else {
      }
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setApplyModificationToASIS(false);
    }
  };

  const handleChangeApprover = (tags) => {
    setApproverList(tags);
  };

  const saveAndSendtoApproval = async () => {
    if (approverList && approverList.length > 0) {
      setaddApproverBtn(true);

      const obj = {
        approverList: approverList,
        id: scenarioId,
      };
      const response = await ScenarioServices.updateapprovaerscenariov2(obj);
      if (response.data.code === 200) {
        console.log("Success", response);
       
        await getAllItemsAndSendForApproval();
      } else {
        console.log(response);
        setaddApproverBtn(false);
      }
    } else {
      message.error("Select Approver for selected scenario ");
    }
  };

  const getAllItemsAndSendForApproval = async () => {
    try {
      setLoading(true);
      const response = await ScenarioServices.getScenarioPlanningPortfolio(
        scenarioId
      );

      if (response && response?.status === 200) {
        //const responseData = response?.data?.data || [];
        const responseData = response?.data || [];

        const url = window.location.href;

        const userData = localStorage.getItem("userData");
        const userDataJson = JSON.parse(userData);

        if (responseData[0]?.approvers_emails.length) {
          const paramsObj = {
            id: scenarioId,
            approverEmail: responseData[0]?.approvers_emails,
            collaboratorEmail: userDataJson?.email, //recordData[0]?.collaborators_emails,
            pageUrl: url,
            collaboratorName: userDataJson?.name,
            scenarioName: scenarioName,
          };

          const response =
            await ScenarioServices.updateScenarioV2SendForApproval(paramsObj);

          if (response.data.code === 200) {
            await getAllScenariosItems();
            setSendForApprovalBtn(false);
            setAddApproverModel(false);
            setaddApproverBtn(false);

            message.success("Scenario send for approval successfully");
          } else {
            message.error(response.data.msg);
            setSendForApprovalBtn(false);
          }
        } else {
          setAddApproverModel(true);
          setSendForApprovalBtn(false);
        }
      } else {
        message.error("Scenario items not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      // message.error("Something went wrong.");
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="my-2 text-left scenario-list-v2">
        <Flex gap={16} justify={`space-between`} align={`center`}>
          <div>
            <PageTitleHeading
              text={(() => {
                if (scenarioName) {
                  if (activeTab === 1) {
                    return `Timeline View of Scenario : ${scenarioName}`;
                  } else {
                    return scenarioName;
                  }
                } else {
                  return `Scenario`;
                }
              })()}
              width={50}
              wordBreak={true}
            />
          </div>
          <div>
            <Link
              to="/list-scenario"
              className="btn btn-sm btn-outline-secondary"
            >
              <i className="fa fa-arrow-left"></i> Back
            </Link>
          </div>
        </Flex>

        <Flex gap="small" wrap justify="space-between">
          <Flex gap="small" wrap>
            <Tooltip title="Item List">
              <Button
                type={"default"}
                icon={<img src={listSvg} alt="Item List" width={`20px`} />}
                onClick={() => {
                  setActiveTab(0);
                }}
                {...(activeTab === 0 && { type: "primary", ghost: true })}
              />
            </Tooltip>
            <Tooltip title="Timeline View">
              <Button
                type="default"
                icon={<NodeExpandOutlined style={{ color: "#2A609D" }} />}
                onClick={() => {
                  setActiveTab(1);
                }}
                {...(activeTab === 1 && { type: "primary", ghost: true })}
              />
            </Tooltip>
            {/* <Tooltip title="Item Grid">
              <Button type="default" icon={<img src={gridSvg} alt="Grid" width={`20px`}/>} onClick={()=>{ setActiveTab(2) }} {...(activeTab===2 && {type:"primary",ghost:true})}/>
            </Tooltip> */}
            {applyASIS && (
              <Tooltip title="Apply Modifications to AS IS">
                <Button
                  type="default"
                  icon={false}
                  loading={applyModificationToASIS}
                  onClick={() => {
                    Modal.confirm({
                      centered: true,
                      title: "Do you want to apply modification to master data?",
                      icon: <ExclamationCircleFilled />,
                      // content: 'Some descriptions',
                      onOk() {
                        ApplyModificationsToASIS();
                      },
                      onCancel() {
                        // console.log('Cancel');
                      },
                    });
                  }}
                >
                  Apply Modifications to AS IS
                </Button>
              </Tooltip>
            )}

            {approval && (
              <>
                <Tooltip title="Approve">
                  <Button
                    // shape={`circle`}
                    type="primary"
                    ghost
                    icon={<CheckOutlined />}
                    onClick={async () => {
                      setApprovedModel(true);

                      // Modal.confirm({
                      //   centered: true,
                      //   title: "Do you want to Approve this scenario?",
                      //   icon: <ExclamationCircleFilled />,
                      //   onOk() {
                      //     updateScenarioV2Stage("Approved", "");

                      //   },
                      //   onCancel() {
                      //   },
                      // });
                    }}
                  >
                    Approve
                  </Button>
                </Tooltip>
                <Tooltip title="Reject">
                  <Button
                    // shape={`circle`}
                    // type="primary"
                    //ghost
                    danger
                    icon={<DislikeOutlined />}
                    onClick={async () => {
                      setRejectedModel(true);

                      // Modal.confirm({
                      //   centered: true,
                      //   title: "Do you want to Reject this scenario?",
                      //   icon: <ExclamationCircleFilled />,
                      //   // content: 'Some descriptions',
                      //   onOk() {
                      //     updateScenarioV2Stage("Rejected");
                      //   },
                      //   onCancel() {
                      //     // console.log('Cancel');
                      //   },
                      // });
                    }}
                  >
                    Reject
                  </Button>
                </Tooltip>
              </>
            )}
          </Flex>
          <Flex>
            {/* <Steps current={0}  percent={60} labelPlacement="vertical" items={items} /> */}
            <StepNavigator steps={steps} currentStep={currentStep} />
          </Flex>
        </Flex>
        <hr className="my-2" />
        <section
          className={`${activeTab === 0 ? `active` : `d-none`} export-tab-0`}
        >
          <div className="d-flex justify-content-between my-3">
            <div>
              <PageTitleHeading text={`Item List`} size={4} className={`my-0`} />
              <p>
                Below is list of all portfolio entities that are part of this
                scenario
              </p>
            </div>
            <div className="ml-auto btnbx">
              {resetToInprogress && (
                <Tooltip title="Reset to In Progress">
                  <Button
                    // shape={`circle`}
                    type="primary"
                    ghost
                    icon={<UndoOutlined />}
                    onClick={async () => {
                      Modal.confirm({
                        centered: true,
                        title:
                          "Do you want to reset life cycle stage to In Progress?",
                        icon: <ExclamationCircleFilled />,
                        // content: 'Some descriptions',
                        onOk() {
                          updateScenarioV2Stage("In Progress", "");
                        },
                        onCancel() {
                          // console.log('Cancel');
                        },
                      });
                    }}
                  >
                    Reset to In Progress
                  </Button>
                </Tooltip>
              )}

              {sendForApproval && (
                <>
                  <Flex gap="small" wrap>
                    <Tooltip title="Send for Approval">
                      <Button
                        // shape={`circle`}
                        loading={sendForApprovalBtn}
                        type="primary"
                        ghost
                        icon={<SendOutlined />}
                        onClick={async () => {
                          sendforApproval();

                          // Modal.confirm({
                          //   centered: true,
                          //   title: "Do you want to send for approval?",
                          //   icon: <ExclamationCircleFilled />,
                          //   // content: 'Some descriptions',
                          //   onOk() {
                          //     sendforApproval();
                          //   },
                          //   onCancel() {
                          //     // console.log('Cancel');
                          //   },
                          // });
                        }}
                      >
                        Send for Approval
                      </Button>
                    </Tooltip>
                    <Tooltip title="Cancel Scenario">
                      <Button
                        // shape={`circle`}
                        //type="primary"
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={async () => {
                          Modal.confirm({
                            centered: true,
                            title: "Do you want to cancel this scenario?",
                            icon: <ExclamationCircleFilled />,
                            // content: 'Some descriptions',
                            onOk() {
                              updateScenarioV2Stage("Cancel", "");
                            },
                            onCancel() {
                              // console.log('Cancel');
                            },
                          });
                        }}
                      >
                        Cancel Scenario
                      </Button>
                    </Tooltip>
                  </Flex>
                </>
              )}

              {addPortfolioVisible && portfolioItem?.length > 0 && (
                <>
                  <Tooltip title="Add Portfolio Item">
                    <Button
                      // shape={`circle`}
                      type="primary"
                      ghost
                      icon={
                        <img
                          src={PlusSvg}
                          alt={`Add Portfolio Item`}
                          width={`20px`}
                        />
                      }
                      onClick={async () => {
                        setAddPortfolioModel(true);
                      }}
                    >
                      Portfolio Item
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="p-3">
              <Spin className="loading_bx" spinning={loading}>
                <ScenarioPlanningList
                  data={recordData}
                  scenarioId={scenarioId}
                  getAllScenariosItems={getAllScenariosItems}
                  getRemovedPortfolioItems={getRemovedPortfolioItems}
                  ctrlStatus={ctrlStatus}
                />
              </Spin>
            </div>
          </div>
        </section>
        <section
          className={`${activeTab === 1 ? `active` : `d-none`} export-tab-1`}
        >
          {activeTab === 1 && (
            <React.Fragment>
              <FishboneIntegrationChart scenarioId={scenarioId} />
            </React.Fragment>
          )}
        </section>
        <section className={`${activeTab === 2 ? `active` : `d-none`}`}></section>

        <Modal
          destroyOnClose
          header={false}
          footer={false}
          requiredMark={true}
          maskClosable={false}
          open={addPortfolioModel}
          onCancel={() => {
            PortfolioForm?.resetFields();
            setAddPortfolioModel(false);
          }}
        >
          <Form
            form={PortfolioForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //initialValues={initialValue}
            className="mt-4"
            onFinish={async (formData) => {
              //Add Portfolio Item
              updateportfolioaddToscenario(formData);
            }}
          >
            <Form.Item
              //{...formItemLayout}
              className="my-1"
              label="Select Portfolio"
              name="PortfolioId"
              rules={[{ required: true, message: "Please Select portfolio!" }]}
              initialValue={portfolioItem[0]?.value || ""}
            >
              <Select>
                {portfolioItem?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button
              type={`primary`}
              htmlType={`submit`}
              className="w-100"
              loading={savePortfoloiLoading}
            >
              Add
            </Button>
          </Form>
        </Modal>

        <Modal
          destroyOnClose
          header={false}
          footer={false}
          requiredMark={true}
          maskClosable={false}
          open={rejectedModel}
          onCancel={() => {
            setRejectedModel(false);
          }}
        >
          <Form
            form={changeTypeForm}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            // initialValues={initialValue}
            className="mt-4"
            onFinish={async (formData) => {
              //Update Rejected
              updateScenarioV2Stage("Rejected", formData?.comment);
            }}
          >
            <FormFields
              type={`textarea`}
              label={`Reject Reason`}
              name={`comment`}
              formClassName={`mb-2`}
              rows={3}
            />

            <Button
              type={`primary`}
              htmlType={`submit`}
              className="w-100"
              //loading={changeTypeLoading}
            >
              Submit
            </Button>
          </Form>
        </Modal>

        <Modal
          destroyOnClose
          header={false}
          footer={false}
          requiredMark={true}
          maskClosable={false}
          open={approvedModel}
          onCancel={() => {
            setApprovedModel(false);
          }}
        >
          <Form
            form={changeTypeForm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            // initialValues={initialValue}
            className="mt-4"
            onFinish={async (formData) => {
              //Update Rejected
              setSendAction(true);
              updateScenarioV2Stage("Approved", formData?.comment);
            }}
          >
            <FormFields
              type={`textarea`}
              label={`Remarks`}
              name={`comment`}
              formClassName={`mb-2`}
              rows={3}
            />

            <Button
              type={`primary`}
              htmlType={`submit`}
              className="w-100"
              loading={sendAction}
            >
              Submit
            </Button>
          </Form>
        </Modal>

        <Modal
          destroyOnClose
          header={false}
          footer={false}
          requiredMark={true}
          maskClosable={false}
          open={addApproverModel}
          onCancel={() => {
            setSendForApprovalBtn(false);
            setAddApproverModel(false);
          }}
        >
          <Form
            form={PortfolioForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //initialValues={initialValue}
            className="mt-4"
            onFinish={async (formData) => {
              //Add Portfolio Item
              saveAndSendtoApproval();

              setSendForApprovalBtn(false);
            }}
          >
            <Form.Item
              //{...formItemLayout}
              className="my-1"
              label="Select Approver"
              name="PortfolioId"
              initialValue={portfolioItem[0]?.value || ""}
            >
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
              >
                <Select
                  name={"approver"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  //value={approverList}
                  onChange={handleChangeApprover}
                  options={userDataSelectOption}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Space>
            </Form.Item>

            <Button
              type={`primary`}
              htmlType={`submit`}
              className="w-100"
              loading={addApproverBtn}
            >
              Send for Approval
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ScenarioPlanning;
