/* eslint-disable no-loop-func */
import React from "react";
import { Input, Modal, Form, message, Spin } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { UpdateChangeLog } from "../../../utils/ChangeLogs.js";
import { usePlant } from "../../../Context/plant.js";
import { useApplicationlink } from "../../../Context/linkProvider";
import DynamicForm from "../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { addIsTextToProperties, isCamelCase, isPascalCase, transformText, } from "../../../utils/Common";
import { getMenuPortfolio } from "../../../utils/settingCommon";
import { useMenu } from "../../../Context/MenuProvider";
import { useApplicationChangeLogs } from "../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../services/services/ChangeLogsServices";
import { getDynamicRoutesLink } from "../../../utils/portfolioRelationshipCommon";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../hooks/useFetchPortfolioTag";
import { useGlobalTag } from "../../../Context/GlobalTagProvider";
import useFetchGlobalProp from "../../../hooks/useFetchGlobalProp";
import { SaveRecentlyView_Portfolio } from "../../../utils/ResentViewedPortfolio.js";
import ExtraFormFields from "../plant/tabs/ExtraFormFields.jsx";
import IntegrationExtraForm from "../integration/tabs/IntegrationExtraForm.js";
// import ExtraFormFields from "./ExtraFormFields.jsx";

  

const TabBasicInformationEdit = ({
  setActiveTabKey,
  toggleTabDisabled,
  basePath,
  moduleName,
  ...props
}) => {
    const [form] = Form.useForm();
    const location = useLocation();
    const leveldata = location.state || null;
    const [spinning, setSpinning] = useState(false);
    const [list, setList] = useState([]);
    const [portfolioItemData, setPortfolioItemData] = useState(null);
    const [formData, setFormData] = useState({});
    const [modelId, setModelId] = useState(null);
    const [level, setLevel] = useState();
    const [parentLevel, setParentLevel] = useState();
    const [levelID, setLevelID] = useState();
    const { state, dispatch } = usePlant();
    const { dispatch: dispatchChangeLog } = useApplicationChangeLogs();
    const [formItems, setFormItems] = useState({});
    const [allTagsList, setAllTagsList] = useState([]);
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading } = stateTag;
    const { fetchTagsData } = useFetchPortfolioTag();
    const { dispatch: businessLinkDispatch } = useApplicationlink();
    const [globalTagsList, setGlobalTagsList] = useState([]);
    const { state: stateGlobalTag } = useGlobalTag();
    const { fetchGlobalPropsData } = useFetchGlobalProp();
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(()=>{
		let m = getMenuPortfolio({routeModuleName:moduleName});
		setPortfolioItemData(m);
        setModelId(props?.id || null);
		fetchData({moduleName, modelId:props?.id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.id,moduleName]);    
	
	const fetchData = async ({ moduleName, modelId }) => {
		try {
			const response = await ChangeLogsServices?.getChangeLogsList({moduleName,modelId,});
			const newData = response.data.data;
			await dispatchChangeLog({ type: "SET_LINK", payload: newData });
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	

	const updateBussinessModuleId = async (id) => {
		await businessLinkDispatch({
			type: "SET_BUSINESSAPPLICATIONID",
			payload: id,
		});
		await businessLinkDispatch({
			type: "SET_BUSINESS_APPLICATION_MODEL",
			payload: moduleName,
		});
		await dispatch({ type: "SET_IS_EDIT", payload: true });
	};

	//Fetch tags items
	const fetchGlobalProp = async () => {
		try {
			await fetchGlobalPropsData();
		} catch (error) {
		console.error("Error fetching Global Prop:", error);
			message.error("Something went wrong.");
		}
	};

	useEffect(() => {
		fetchGlobalProp();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setGlobalTagsList(stateGlobalTag.data);
	}, [stateGlobalTag?.data,moduleName, modelId]);

	//Fetch tags items
	const fetchAllPortfolioTags = async () => {
		try {
			await fetchTagsData();
		} catch (error) {
			console.error("Error fetching portfolio:", error);
			message.error("Something went wrong.");
		}
	};

  	// Tag useEffect start
	useEffect(() => {
		if(stateTag?.moduleName){
			if(stateTag?.moduleName){
				fetchAllPortfolioTags();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateTag?.moduleName]);

	useEffect(() => {
		setAllTagsList(stateTag?.data);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateTag?.data,stateTag?.moduleName]);

	useEffect(() => {
		if (portfolioItemData?.moduleName) {
			if (portfolioItemData?.moduleName) {
				dispatchTag({ type: "EMPTY_TAGS" });
				dispatchTag({
					type: "SET_MODULE_NAME",
					payload: portfolioItemData?.moduleName,
				});
			}
			setFormItems(addIsTextToProperties(portfolioItemData, "Level"));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [portfolioItemData]);

	useEffect(() => {
		if (modelId != null) {
			updateBussinessModuleId(modelId);
		} else {
			dispatch({ type: "SET_IS_EDIT", payload: false });

			//Cheking Level Data to Populate the Parent Detail
			if (leveldata != null) {
				console.log("Level Data", leveldata);
				if (leveldata?.Level == "1") {
				setLevel("Level 1");
				setParentLevel("");
				setLevelID("");
				} else if (leveldata?.Level == "2") {
				setLevel("Level 2");
				setParentLevel(leveldata.underLevel);
				setLevelID(leveldata.levelID);
				} else if (leveldata?.Level == "3") {
				setLevel("Level 3");
				setParentLevel(leveldata?.underLevel);
				setLevelID(leveldata?.levelID);
				}
			} else {
				setLevel("Level 1");
				setParentLevel("");
				setLevelID("");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelId, state.isEdit,moduleName]);

	useEffect(() => {
		if (modelId && state.isEdit && portfolioItemData?.services) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelId, state.isEdit, portfolioItemData,moduleName]);

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const createAndUpdate = () => {
		form.validateFields()
		.then(async (values) => {
			setSpinning(true);
			const oldValues = updateValuesForChangeLog(list, formItems?.portfolioSections);
			const newValues = updateValuesForChangeLog(values, formItems?.portfolioSections);
			
			try {
				if(moduleName==="Integration"){
					values.relationships = getRelationships();
				}
				if(values?.tags){
					values.tags = values?.tags?.map(f=>f?.id);
				}

				if(state.isEdit){
					values.id = modelId;
					props?.setFormItems(values);
					let response = await updatePortfolioItem({portfolioItemData,formData:values});	
					if(response){
						//Update Change Log
						await UpdateChangeLog(
							modelId,
							oldValues,
							newValues,
							"Update",
							moduleName,
							formItems?.portfolioSections
						);

						await fetchData({ moduleName, modelId });
					}
				} else{
					let response = await createPortfolioItem({ portfolioItemData, formData: values });
					if(response){
						//Add Change Log
						await UpdateChangeLog(
							values.id,
							oldValues,
							newValues,
							"Add",
							moduleName,
							formItems?.portfolioSections
						);

						await fetchData({ moduleName, modelId });
					}
				
				}
			} catch (error) {
				console.log("error",error);
			}
			// getData();
			setSpinning(false);
		})
		.catch((info) => {
			console.log("Validate Failed:", info);
		});
	};

    const getData = async () => {
        let response = (await portfolioItemData?.services?.getListByID(modelId)) || {};
        response = await response?.data; 
        if(response){
			form.resetFields();
            setList(response);
            setFormData(response);
			props?.setFormItems(response);
			
			if(response?.tags?.length>0){
				let fTags = allTagsList?.filter(f=>response?.tags?.includes(f.id))?.map(option=>{
					option.value = option?.id;
					option.label = option?.name;
					return option;
				});
				form.setFieldValue("tags",fTags);
			}

			if(response?.level){
				let levelTemp = response?.level?.replace("L", "");
				setLevel("Level "+levelTemp);
				if(response?.parentid){
					let parentResponse = (await portfolioItemData?.services?.getListByID(response?.parentid)) || {};
					parentResponse = parentResponse?.data;
					if(parentResponse){
						setLevelID(parentResponse?.levelid);
						setParentLevel(parentResponse?.displayname);	
					}
				}
				
			}
            
			SaveRecentlyView_Portfolio(
				portfolioItemData.name,
				window.location.href,
				response?.displayname,
				response?.id,
				portfolioItemData.color,
			);
        }
    };

	const setFormData2 = (name,value)=>{
        form.setFieldValue(name,value);
    }

	const dynamicFormProps = (moduleName) => {
		switch (moduleName) {
			case `integration`:
				return {
					ExtraFormFields:<IntegrationExtraForm formData={formData} setFormData={setFormData2} form={form}/>
				}
			case `plant`:
				return {
					ExtraFormFields2:<ExtraFormFields formData={formData} form={form} moduleName={moduleName || null}/>
				}
			default:
				return {};
		}
	}

	const getRelationships = () => {
		let newFormValues = form.getFieldsValue();
		let oldFormValues = formData;
		let oldValues;
		let newValues;
	
		let relationships = {};
		// Source App RelationShips:---------------------------------------------
		oldValues = oldFormValues["sourceApp"];
		newValues = newFormValues["sourceApp"];
		relationships["sourceApp"] = {
		  moduleName: "BusinessApplication",
		  oldValues: oldValues,
		  newValues: newValues,
		  deleted: oldValues?.filter((n) => !newValues?.includes(n)) || [],
		  added: newValues?.filter((n) => !oldValues?.includes(n)) || [],
		};
	
		// Target App RelationShips:---------------------------------------------
		oldValues = oldFormValues["targetApp"];
		newValues = newFormValues["targetApp"];
		relationships["targetApp"] = {
		  moduleName: "BusinessApplication",
		  oldValues: oldValues,
		  newValues: newValues,
		  deleted: oldValues?.filter((n) => !newValues?.includes(n)) || [],
		  added: newValues?.filter((n) => !oldValues?.includes(n)) || [],
		};

		return relationships;
	};

	return (
		<Spin spinning={spinning}>
			<DynamicForm
				data={formItems?.portfolioSections || []}
				form={form}
				formData={formData}
				labelCol={8}
				wrapperCol={16}
				createAndUpdate={createAndUpdate}
				level={level}
				levelID={levelID}
				parentLevel={parentLevel}
				showSaveButton={true}
				tagOptions={allTagsList}
				tagLoading={tagLoading}
				moduleName={formItems?.moduleName || null}
				GlobalTagOptions={globalTagsList}
				{...dynamicFormProps(portfolioItemData?.moduleName)}
			/>
			<Modal
				title=""
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form
				form={form}
				labelCol={{ span: 12 }}
				wrapperCol={{ span: 12 }}
				className="mt-5"
				>
				<Form.Item
					label="Additional Information Caption"
					name="AdditionalInfoCaption"
					rules={[
					{
						required: true,
						message: "Please input the additional information caption",
					},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="AdditionalInfoValue"
					label="Additional Information Value"
					rules={[
					{
						required: true,
						message: "Please input the additional information caption",
					},
					]}
				>
					<Input />
				</Form.Item>
				</Form>
			</Modal>
		</Spin>
	);
};

TabBasicInformationEdit.defaultProps ={
    // setPortfolioData:()=>{},
}

export default TabBasicInformationEdit;



export const updateCreateItemLink = ({ basePath, id }) => {
  return getDynamicRoutesLink({
    path: basePath?.replace("/add", ""),
    id,
  });
};

export const createPortfolioItem = async ({portfolioItemData,formData}) =>{
	try {
		let result = null;
		result = await portfolioItemData?.services?.create(formData);

		if(result?.data?.code?.toString()==="400"){
			message.error(result?.data?.msg);
			return false;
		}else if(result?.data?.error){
			message.error(result?.data?.error);
			return false;
		}else if(result?.data?.code?.toString()==="200"){
			message.success(result?.data?.msg || "Created Successfully");
			return true;
		}else if(result?.data?.id){
			message.success("Created Successfully");
			return true;
		}
		
		return result?.data || {};
	} catch (error) {
		console.log("error",error);
	}
}

export const updatePortfolioItem =async ({portfolioItemData,formData}) =>{
	try {
		let result = null;
		if(portfolioItemData?.moduleName==="business_application"){
			result = await portfolioItemData?.services?.update(formData?.id,formData);
		}else{
			result = await portfolioItemData?.services?.update(formData);
		}
		if(result?.data?.code?.toString()==="400"){
			message.error(result?.data?.msg);
			return false;
		}else if(result?.data?.error){
			message.error(result?.data?.error);
			return false;
		}else if(result?.data?.code?.toString()==="200"){
			message.success(result?.data?.msg || "Updated Successfully");
			return true;
		}else if(result?.data?.id){
			message.success("Updated Successfully");
			return true;
		}
		
		return true;
	} catch (error) {
		console.log("error",error);
	}
	
}

const checkTextCase = (name) => {
	if (isPascalCase(name) || isCamelCase(name)) {
		return name; // If already in PascalCase or camelCase, return unchanged
	} else {
		return transformText(name, "camel"); // Convert to camelCase otherwise
	}
};

const formatPropertyName = (name) => {
	// Convert name to lower case and remove leading/trailing spaces
	let formattedName = name.toLowerCase().trim();
	// Replace hyphens and underscores with spaces
	formattedName = formattedName.replace(/[_-]/g, " ");
	// Convert camelCase to Camel Case
	formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
	// Capitalize the first letter of each word
	formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
	return formattedName;
};

const updateValuesForChangeLog = (data, propss) => {
    let oldList = {};
    Object.keys(data).map((dataItem) => {
		propss.map((section, index) => {
			section?.properties?.length > 0 && section?.properties
			?.sort((a, b) =>formatPropertyName(a.name).localeCompare(formatPropertyName(b.name)))
			?.map((property, index) => {
				if (dataItem === property.name) {
					if (property?.type === "Single select") {
						property?.propertyOptions.map((propvalue) => {
							if (data[dataItem] == null) {
								oldList[dataItem] = "";
							} else if (propvalue?.id === data[dataItem]) {
								oldList[dataItem] = propvalue?.name;
							}
						});
					} else if (property?.type === "Multi select") {
						let lst = data[dataItem];
					if (lst?.length) {
						let propValue = "";
						for (let i = 0; i < lst?.length; i++) {
							property?.propertyOptions.map((propvalue) => {
								if (propvalue?.id === lst[i]) {
									if (propValue === "") {
										propValue = propvalue?.name;
									} else {
										propValue = propValue + ", " + propvalue?.name;
									}
								}
							});
						}
						oldList[dataItem] = propValue;
					} else {
						oldList[dataItem] = "";
					}
					} else {
						oldList[dataItem] = data[dataItem];
					}
				}
			});
		});
    });
    return oldList;
  };