import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import BusinessProcessGrid from '../../businessProcess/BusinessProcessGrid';
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import BusinessProcessList from '../../businessProcess/BusinessProcessList';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { ListSelectAllCheckboxComponent, showViewType } from '../common/PortfolioCommon';
import { Spin } from 'antd';

const BusinessProcesses = (props) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(1);
    const {moduleName} = useParams();
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});
    const [uniquelevelid,setuniquelevelid] = useState([]);      
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAllChecked,setSelectAllChecked] = useState(false);

    useEffect(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1)
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        setLoading(true);
        setSelectAllChecked(false);
        setLoading(false);
    },[lists])

    const onCheckAllChange = (checked) => {
        setLoading(true);
        setTimeout(() => {
            if(checked){
                setBulkUpdateIds([...bulkUpdateIds,...lists?.map(v=>v.id)])
            }else{
                setBulkUpdateIds([])
            }
            setSelectAllChecked(checked);
            setLoading(false);
        }, lists?.length*5);
    }

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={moduleData?.name || moduleName}
        addBtnTooltip={`Level 1`}
        filterOptions={filterOptions}
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
        lists={uniquelevelid}
        Buttons={<ListSelectAllCheckboxComponent onCheckAllChange={onCheckAllChange} selectAllChecked={selectAllChecked}/>}
    />

    return (
        <div className='business-process-master'>
            <Spin spinning={loading}>
                { 
                    activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                    <BusinessProcessGrid 
                        HeaderContnet = {HeaderContnet}
                        moduleName={moduleName}
                    />
                }
                { 
                    activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                    <BusinessProcessList 
                        HeaderContnet = {HeaderContnet}
                        moduleName={moduleName}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        setBulkDeleteButton={setBulkDeleteButton}
                        deleteStart={deleteStart}
                        setDeleteStart={setDeleteStart}
                        setuniquelevelid={setuniquelevelid}
                        bulkUpdateIds={bulkUpdateIds}
                        setBulkUpdateIds={setBulkUpdateIds}
                        setLists={setLists}
                    />
                }
            </Spin>
        </div>
    )
}

export default BusinessProcesses