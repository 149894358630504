import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography, Button, Tooltip,Collapse,Input } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import BCMLevel2, { getNewLevelId } from './BCMLevel2';
import { useNavigate } from "react-router";
import {  useParams } from "react-router-dom";
const baseStyle = {
    justifyContent: 'center',
    margin:'10px',
}

const BCMLevel1 = ({basePath,levelIndex,create_Duplicate,...props}) => {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const { moduleName:moduleNamePath } = useParams();
    const [bcm2Index,setBcm2Index] = useState(null)
    const [activeKey, setAcitveKey] = useState([])
    
    const navigate = useNavigate();
    let parentName;

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }
 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
         
    }, [dropdownRef]);

    const NewItem = ({...props}) => {
        const [inputItem,setInputItem]= useState(null)
        const inputRef = useRef(null)

        useEffect(()=>{
            inputRef.current.focus()
        },[])  

        const handleKeyPress = async(e) =>{
            if (e.key === 'Enter') {
                const obj = {
                    referenceid: 413,
                    displayname:inputItem,
                    attributes: [],
                    level:`L${levelIndex + 1}`,
                    levelid:props.newLevelId,
                    parentid: props.parentid,
                    sourceid: 1,
                }
            await create_Duplicate(obj)

            }
        }


        return (
            <>
                <Collapse
                    style={{ backgroundColor: '#fff' }}
                    onMouseEnter={(event) => event.stopPropagation()}
                    onMouseLeave={(event) => event.stopPropagation()}
                    //onChange={onChange}
                    //onClick={() => { props?.setItem(props.data); props?.setModalData({ level: (levelIndex + 1), ParentID: props?.data?.id }); }}
                    expandIconPosition={''}

                    items={[
                        {
                            key: props.key,
                            label: <>
                                <Tooltip
                                    title={"Enter Display Name"}
                                //onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                                >

                                    <Input onPressEnter={handleKeyPress} style={{ width: '100%' }} defaultValue="Enter Display Name" value={inputItem} onChange={(e) => setInputItem(e.target.value)} ref={inputRef} />
                                    {/* {props.data.levelid} {props.data.displayname} */}
                                </Tooltip>
                            </>,
                            extra: <>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                                    <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                                        e.stopPropagation();
                                        //setDropdown(!dropdown);
                                    }}>
                                        <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                                    </button>
                                    {/* {
                                        dropdown && (

                                            <ul ref={dropdownRef} style={{ padding: "0px 3px", display: 'flex', flexDirection: 'row-reverse', gap: 5, position: 'fixed', marginLeft: 70, backgroundColor: '#ffffff', borderRadius: 7, height: 32, borderColor: '#ccc', borderWidth: 1.5 }}>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button className='btn-icon'><i className="fa fa-trash-o delete" style={{ fontSize: 'large' }} /></Button></li>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }} className='btn-icon'><i className="fa fa-pencil edit" style={{ fontSize: 'large' }} /></Button></li>
                                            </ul>

                                        )
                                    } */}
                                </div>
                            </>
                        },
                    ]}
                    className='collapse-level-2 m-1'
                />
            </>
        )
    }

    return <>
        <Card
            size="small"
            style={{ ...baseStyle, backgroundColor: props?.backgroundColor}}
        >
            <div className='d-flex align-items-center justify-between'>
                <Typography.Title
                    level={5}

                    className={props?.backgroundColor ? 'text-white title-level-1 cursor-pointer' : 'title-level-1 cursor-pointer'}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => { navigate('/business-capability-master/'+moduleNamePath+'/'+props?.data?.id ,{state:{from:basePath,defaultActiveKey:2}}  )}}
                > 
                    {props?.data?.levelid} {props?.data?.displayname}
                </Typography.Title>
                <div className='level-btn-1'>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={() => {
                            setDropdown(!dropdown);
                        }}>
                            <i className="fa fa-ellipsis-v edit" />
                        </button>
                        { 
                            dropdown && (
                                <ul className="dropdown-menu" ref={dropdownRef}>
                                    <li><Button onClick={() => { props?.setItem(props?.data, { type: 'delete' }); props?.setModalData({ level: 1, ParentID: props?.data?.id, DisplayName:props?.data?.DisplayName }); }} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                                    {/* <li><Button onClick={() => { props?.setItem(props?.data, { type: 'edit' }); props?.setModalData({ level: 1, ParentID: props?.data?.id }); }} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}
                                    {/* <li><Button onClick={() => { navigate('/business-capability-master', { state: { type: 'edit',Level:1, ParentID: props?.data?.id , ParentName:props?.data?.DisplayName, underLevel:props?.underLevel } })}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                        */}
                                    <li><Button onClick={() => { navigate('/business-capability-master/'+moduleNamePath+'/'+props?.data?.id  )}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                        
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                props?.data?.child?.map((item, index) => {
                    return <div  key={item.levelid}>
                        <BCMLevel2 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} data={item}  underLevel={props?.data.displayname} underLevelID={props?.data.levelid} level={levelIndex+1} bcm2Index={bcm2Index} setBcm2Index={setBcm2Index} create_Duplicate={create_Duplicate} activeKey={activeKey} setAcitveKey={setAcitveKey}/>
                        </div>
                })
            }
            { props?.data?.levelid === props.bcm2Index && 
                <NewItem newLevelId={getNewLevelId(props?.data?.levelid, levelIndex + 1, props.data)} parentid={props?.data?.id}/>
            }
            <br />
            <br />
            <Tooltip title="Level 2">
                {/* <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => { props?.openModal(!props?.visibleModal); props?.setModalData({ level: 2, ParentID: props?.data?.id }); }}></Button> */}
                 

                <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => {
                    props.setBcm2Index(props?.data?.levelid)
                    //navigate('/business-capability-master/'+ moduleNamePath+"/add", { state: {Level:levelIndex+1,ParentID: props?.data?.id, ParentName: props?.data?.displayname, underLevel: props?.data?.displayname, levelID: props?.data?.levelid ,newLevelId : getNewLevelId(props?.data?.levelid,levelIndex+1,props.data) ,from:basePath,defaultActiveKey:2} })
                } }></Button>
            </Tooltip>
        </Card>
    </>;
}

BCMLevel1.defaultProps={
    levelIndex:1,
}
export default BCMLevel1;