import OgmaLib from '@linkurious/ogma';
import { NodeStyleRule, Ogma } from '@linkurious/ogma-react';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useReadCypher } from 'use-neo4j';
import { alterEdges, getColorCodeNode, getLabelIcon } from '../../../utils/CityMapCommon';
import ExportBtn from '../ExportBtn';
import Legends from '../Legends';
import GraphLayoutNode2 from './GraphLayoutNode2';

const GraphContainerNode2 = (props) => {
  const [query, setQuery] = useState("Matrix")
  const {error, loading, records, run } = useReadCypher(props?.cityMapNode2Query, { query })
  const {formData, setLoading, setFilterNodes2, selectedFilterNodes, setCityMapNode2Query} = props;
  const [graph, setGraph] = useState()
  const [graphLayout, setGraphLayout] = useState(false)
  const [dblClickEvent, setDblClickEvent] = useState(false)
  const [ogmaInstance, setOgmaInstance] = useState()
  const [ogmaDblClickData, setOgmaDblClickData] = useState([])
  const [exportGraphFormat, setExportGraphFormat] = useState('')


 const ogmaOptions = {
    backgroundColor:"transparent",
    // zoom: {
    //   maxValue: "50%"
    // },
    minimumHeight: 700,
 }
  
  useEffect(() => {
    setLoading(true)
    run({ query })
    .then(async (readResult) => {
      if(dblClickEvent) {
        return OgmaLib.parse.neo4j(readResult)
      } else {
        createQuery(readResult);
      }
    })
    .then(graph => {
      if(graph && Object.keys(graph).length > 0){
        const newNodes = graph.nodes.map(item => {
                        let labelName = item?.data?.neo4jLabels[0];
                        let iconName = getLabelIcon(labelName)
                          item.label = labelName
                          item.data = {
                              label: labelName,
                              neo4jProperties: item?.data?.neo4jProperties
                            }
                          item.attributes= {
                              text: item?.data?.neo4jProperties?.name,
                              icon: {
                                content: iconName,
                                font: 'Font Awesome 5 Free',
                                color: 'black',
                                style: 'bold'
                              },
                              outerStroke: {
                                color: '#204829',
                                width: 2
                              },
                              color: "white",
                            }

                        return item;
                      })
        
        graph.nodes = newNodes
        const newEdges = graph.edges.map(item => {
                        item.type = item?.data?.neo4jType;
                        item.attributes = {
                                text: item?.data?.neo4jType,
                                color: "green",
                                shape: {
                                  head: "arrow",
                                },
                                width: 1
                              }

                        return item;
                      })
        graph.edges = newEdges

        setTimeout(()=> {
          setOgmaDblClickData(graph);
        },1000)
        setDblClickEvent(false);
      }
    })
    .catch(err => {
    })
  }, [props?.cityMapNode2Query, formData])



  const createQuery = async (readResult) => {
    if (selectedFilterNodes.length > 0 ) {
      readResult = await alterEdges(readResult)
    }
    let nodeLabelData = []
    let nodeRelationsData = []
    let uniqueNode = [];
    let uniqueRelation = [];
    let newGraph = {};
    if(readResult){
      if (formData?.usecase === '2') {
        readResult.records.forEach(record => {
          if(record._fields[1].length > 0){
            record._fields[0].forEach(zeroRec => {
              let labelName = zeroRec.labels[0];
              let iconName = getLabelIcon(labelName)
              let nodeColor = zeroRec.properties.referenceId === formData?.comparison_node_2? "#e39662" : "white";
              if(!nodeLabelData.includes(labelName)){
                nodeLabelData.push(labelName)
              }
              const newNode = {
                label: zeroRec.labels[0],
                id: zeroRec.identity.low,
                data: {
                  label: labelName,
                  neo4jProperties: zeroRec.properties
                },
                attributes: {
                  text: zeroRec.properties.name,
                  icon: {
                    content: iconName,
                    font: 'Font Awesome 5 Free',
                    color: 'black',
                    style: 'bold'
                  },
                  outerStroke: {
                    color: '#204829',
                    width: 2
                  },
                  color: nodeColor,
                },
                
              };

              // Check if the same ID already exists in uniqueNode
              let isDuplicate = uniqueNode.some(node => node.id === newNode.id);
    
              if (!isDuplicate) {
                uniqueNode.push(newNode);
              }
            });
            record._fields[1].forEach(oneRec => {
              const newNode = {
                id: oneRec.identity.low,
                type: oneRec.type,
                source: oneRec.start.low,
                target: oneRec.end.low,
                attributes: {
                  text: oneRec.type,
                  color: "green",
                  shape: {
                    head: "arrow",
                  },
                  width: 1
                },
                
              };
    
              // let isDuplicate = uniqueRelation.some(node => node.end === newNode.end && node.start === newNode.start);
              let isDuplicate = uniqueRelation.some(node => node.id === newNode.id);
    
              if (!isDuplicate) {
                uniqueRelation.push(newNode);
              }
            });
          }

        });

        nodeLabelData = uniqueNode.reduce((acc, node) => {
          const existingLabel = acc.find(item => item.label === node.label);
    
          if (existingLabel) {
            existingLabel.count++;
          } else {
            acc.push({ label: node.label, count: 1, color: getColorCodeNode(node.label), icon: getLabelIcon(node.label) });
          }
    
          return acc;
        }, []);

        nodeRelationsData = uniqueRelation.reduce((acc, node) => {
          const existingType = acc.find(item => item.type === node.type);
    
          if (existingType) {
            existingType.count++;
          } else {
            acc.push({ type: node.type, count: 1 });
          }
    
          return acc;
        }, []);

        newGraph.nodes = uniqueNode
        newGraph.edges = uniqueRelation
        setGraph(newGraph);
        if(selectedFilterNodes.length === 0){
          setFilterNodes2(nodeLabelData)
        }
        
        if(nodeLabelData.length === 0){
          props?.setShowTwoNodeGraph2(false)
        }
      }
    }
  }

  // City Map Graph loader
  setLoading(loading)

  return (
    <Card className='mt-2' style={{ height: "800px", width: "100%" }}>
      <Ogma graph={graph}
        options={ogmaOptions} >
        <GraphLayoutNode2 
          graphLayout={graphLayout} 
          graph={graph} 
          setLoading={setLoading} 
          run={run}
          setCityMapNode2Query={setCityMapNode2Query}
          setDblClickEvent={setDblClickEvent}
          setOgmaInstance={setOgmaInstance}
          ogmaDblClickData={ogmaDblClickData}
          exportGraphFormat={exportGraphFormat}
          setExportGraphFormat={setExportGraphFormat}
          />
      </Ogma>
      <ExportBtn setExportGraphFormat={setExportGraphFormat} />
    </Card>
  );
}

export default GraphContainerNode2 