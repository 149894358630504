import React, { useEffect,useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import usePortfolioFetcher from '../../../hooks/usePortfolioFetcher';
import { usePortfolioSetting } from '../../../Context/portfolioSettingProvider';
import { Button, Layout, Modal, Pagination, Spin, message,Tooltip,Typography, Checkbox } from 'antd';
import { PageTitleHeading, debounceTimeOut, default_pageSize, default_pageSizeOptions, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys } from '../../../utils/Common';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';
import useDebounce from '../../../helper/useDebounce';
import BusinessProcessListContent from '../../businessProcess/list/BusinessProcessListContent';
import BusinessProcessListFilter from '../../businessProcess/list/BusinessProcessListFilter';
import TextWithTooltip from '../../../utils/TextWithTooltip';
import { PlusOutlined, ArrowRightOutlined, CheckSquareOutlined, BorderOutlined, CheckOutlined} from '@ant-design/icons';
import { useSaveState } from '../../../Context/StateProviderData';
import UpdateGlobalState from './UpdateGlobalState';

 
export default function PortfolioCommon() {
    return (
        <div>
        
        </div>
    )
}

 
const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (text?.length <= maxLength) {
    return <p>{text}</p>;
  }

  if (text?.length > maxLength) {
  
  return (
    <div>
      <p   >
        {isExpanded ? text : `${text?.substring(0, maxLength)}...`}
      </p>
      {/* <button onClick={toggleExpand} style={{ color: "blue", cursor: "pointer" }}>
        {isExpanded ? 'Show Less' : 'Show More'}
      </button> */}
    </div>
  );
}
};

export const PortfolioItemDetails = ({page,limit, item,moduleName,routeModuleName,basePath,defaultActiveKey,onClick,badgeValue,...props}) =>{
    const navigate = useNavigate();
    const param = useParams()
    const getModuleData = getMenuPortfolio({moduleName:moduleName,routeModuleName:routeModuleName});
    // console.log("testing portfolio two state pageandlimit",page,limit);

    return <React.Fragment key={Math.random()}>
        <div 
        className="my-1 portfolio-item cursor-pointer"
        onClick={() => {
            onClick(item);
            let newLink = getModuleData?.route ? getModuleData?.route + '/' + item?.id : '/';
            if (param?.id) {
                window.open(newLink, '_blank');
            } else {
                navigate(newLink, { state: { from: basePath, defaultActiveKey, page, limit, ...item } });
            }
        }}
    >
        {
            badgeValue &&
            <div>
                <div className="badge_id px-1" style={{ backgroundColor: getModuleData?.color }}>
                    {badgeValue?.toString() || ""}
                </div>
            </div>
        }

        {(item?.sourceAppConnection && item.sourceAppConnection.length > 0) || 
          (item?.targetAppConnection && item.targetAppConnection.length > 0) ? (
            <>
                <div className="des-bx" style={{ width: '50%' }}>
                    <h4 className="text-primary">{item?.displayname}</h4>
                </div>
                <div style={{ width: '50%',display:'flex' }}>
                    {item?.sourceAppConnection && item.sourceAppConnection.length > 0 && (
                        <>
                            <a 
                                href={'/porfolio/BusinessApplication/' + item.sourceAppConnection[0].id} 
                                target="_blank" 
                                style={{ textDecoration: 'none' }}
                                onClick={(e) => e.stopPropagation()}
                                className="ant-btn-link"
                            >
                                <Tooltip title={'Source App - '+item.sourceAppConnection[0].displayname}>
                                  <span style={{ display: 'inline-block', 
                                    width: '120px', 
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis', 
                                    backgroundColor: 'rgb(255, 179, 129)', 
                                    fontSize: '12px', 
                                    padding: '4px', 
                                    borderRadius: '4px', 
                                    textAlign: 'center'  }}>
                                      {item.sourceAppConnection[0].displayname}
                                  </span>
                                </Tooltip>
                            </a> 
                            <ArrowRightOutlined style={{margin:'-4px 2px 0px 2px'}}/>
                        </>
                    )}
                    {item?.targetAppConnection && item.targetAppConnection.length > 0 && (
                        <a 
                            href={'/porfolio/BusinessApplication/' + item.targetAppConnection[0].id} 
                            target="_blank" 
                            style={{ textDecoration: 'none' }}
                            onClick={(e) => e.stopPropagation()}
                            className="ant-btn-link"
                        >
                          <Tooltip title={'Target App - '+item.targetAppConnection[0].displayname}>
                            <span style={{ display: 'inline-block', 
                                width: '120px', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                backgroundColor: 'rgb(255, 179, 129)', 
                                fontSize: '12px', 
                                padding: '4px', 
                                borderRadius: '4px', 
                                textAlign: 'center' }}>
                                {item.targetAppConnection[0].displayname}
                            </span>
                            </Tooltip>
                        </a>
                    )}
                </div>
            </>
        ) : (
            <div className="des-bx">
                <h4 className="text-primary">{item?.displayname}</h4>
            </div>
        )}
    </div>
    <div className="des-bx">
        <ExpandableText text={item?.description} maxLength={250} />
    </div>

    </React.Fragment>
}

PortfolioItemDetails.defaultProps = {
    item:{},
    moduleName:'',
    onClick:()=>{},
    badgeValue:'',
    defaultActiveKey:1,
    basePath:'',
}

export const isValidHttpUrl = (string)=> {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
}

export const showViewType = ({moduleName,viewType}) =>{
  let moduleData = getMenuPortfolio({moduleName});
  let viewTypes = moduleData?.view_type?.split(",");
  // console.log('testing view Type moduleData', moduleData);
  
  if(viewTypes?.includes(viewType)){
    return true;
  }else{
    return false;
  }
}

export const ListSelectAll = ({reset,...props}) => {
  const [checked2,setChecked2] = useState(false);

  useEffect(()=>{
    setChecked2(false);
  },[reset])

  const onChange = (e) => {
    if(e?.target?.checked){
      props?.setChecked(true);
      setChecked2(true);
    }else{
      props?.setChecked(false)
      setChecked2(false);
    }
  }

  return <React.Fragment>
    <Checkbox onChange={onChange}>
      {
        ((()=>{
          if(checked2){
            return <CheckSquareOutlined />
          }else{
            return <BorderOutlined />
          }
        })())
      }
    </Checkbox>
  </React.Fragment>
}

export const ListSelectAllCheckboxComponent = ({selectAllChecked,onCheckAllChange}) => {

  return (
    <React.Fragment>
      <Tooltip title={selectAllChecked?'Unselect All':'Select All'}>
        <Button 
          icon={<Checkbox onChange={(e)=> { onCheckAllChange(e?.target?.checked); } } checked={selectAllChecked}/>}
        />
      </Tooltip>
    </React.Fragment>
  );
}