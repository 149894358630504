import { encodeQueryData } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}businessapplication?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}businessapplication`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}businessapplication/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deleteBusinessApplication(id) {
  const url = `${API_BASE_URL}businessapplication/`;
  let response = await http.delete(url + id);
  return response;
}

async function getAllTagsList() {
  const url = `${API_BASE_URL}businessapplication/get-all-tags`;
  let response = await http.get(url);
  return response;
}
async function getById(id) {
  const url = `${API_BASE_URL}businessapplication/`;
  let response = await http.get(url + id);
  return response;
}

const BusinessApplicationServices = {
  getList,
  create,
  update,
  deleteBusinessApplication,
  getAllTagsList,
  getById,
  getListByID:getById,
};

export default BusinessApplicationServices;
