import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getAllTags({ moduleName = null, addAssociation = false }) {
  let url = `${API_BASE_URL}tag`;

  // Add moduleName parameter if it exists
  if (moduleName) {
    url += `?moduleName=${moduleName}`;
  }

  // Add addAssociation parameter if true
  if (addAssociation) {
    url += moduleName
      ? `&addAssociation=${addAssociation}`
      : `?addAssociation=${addAssociation}`;
  }

  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}tag`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}tag/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deleteBusinessApplication(id) {
  const url = `${API_BASE_URL}tag/`;
  let response = await http.delete(url + id);
  return response;
}

async function getAllTagsList() {
  const url = `${API_BASE_URL}tag/get-all-tags`;
  let response = await http.get(url);
  return response;
}

const PortfolioTagServices = {
  getAllTags,
  create,
  update,
  deleteBusinessApplication,
  getAllTagsList,
};

export default PortfolioTagServices;
