import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import BusinessCapabilityGrid from '../../businessCapabilityMaster/BusinessCapabilityGrid';
import BusinessCapabilityList from '../../businessCapabilityMaster/BusinessCapabilityList';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { ListSelectAllCheckboxComponent,showViewType } from '../common/PortfolioCommon';
import { Spin } from 'antd';

const BusinessCapability = ({moduleName}) => {
    const location = useLocation()
    const mainDivRef = useRef(null);
    const [activeGridStyle, setActiveGridStyle] = useState(1);
    const [moduleData,setModuleData] = useState(null);  
    const [deleteStart,setDeleteStart] = useState(false);
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAllChecked,setSelectAllChecked] = useState(false);

    useEffect(()=>{
        setActiveGridStyle(location?.state?.defaultActiveKey || 1);
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    },[moduleName])

    useEffect(()=>{
        setLoading(true);
        setSelectAllChecked(false);
        setLoading(false);
    },[lists])

    const onCheckAllChange = (checked) => {
        setLoading(true);
        setTimeout(() => {
            if(checked){
                setBulkUpdateIds([...bulkUpdateIds,...lists?.map(v=>v.id)])
            }else{
                setBulkUpdateIds([])
            }
            setSelectAllChecked(checked);
            setLoading(false);
        }, lists?.length*5);
    }

    return <React.Fragment>
        <div className='mb-0' ref={mainDivRef}>
            <Spin spinning={loading}>
            {activeGridStyle===2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <BusinessCapabilityGrid 
                    activeGridStyle={activeGridStyle}
                    setActiveGridStyle={setActiveGridStyle }
                    width={mainDivRef?.current?.clientWidth}
                    basePath={location?.pathname}
                />
            }
            {activeGridStyle===1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <BusinessCapabilityList 
                    activeGridStyle={activeGridStyle}
                    setActiveGridStyle={setActiveGridStyle }
                    basePath={location?.pathname}
                    showButtons={{
                        view:false,
                        edit:false,
                        delete:true,
                    }}                    
                    //setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                    HeaderButtons={<ListSelectAllCheckboxComponent onCheckAllChange={onCheckAllChange} selectAllChecked={selectAllChecked}/>}
                    bulkUpdateIds={bulkUpdateIds}
                    setBulkUpdateIds={setBulkUpdateIds}
                    setLists={setLists}
                />
            }
            </Spin>
        </div>
    </React.Fragment>;
}

BusinessCapability.defautProps = {

}

export default BusinessCapability
