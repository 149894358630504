import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  Flex,
  Form,
  Layout,
  message,
  Result,
  Select,
  Switch,
} from "antd";
import LoadingBar from "react-top-loading-bar";
import { PageTitleHeading } from "../../../utils/Common";
import CompareChart from "./CompareChart";
import ScenarioServices from "../../../services/services/ScenarioServices";
import { useLocation } from "react-router";
import { json, useNavigate, useParams } from "react-router-dom";
import ExpenseChart from "./ExpenseChart";

function CompareScenarioV2() {
  const location = useLocation();
  const loadingBarRef = useRef(null);
  const [filterForm] = Form.useForm();
  const selectDefaultProps = {
    showSearch: true,
    style: { minWidth: "150px" },
    size: "medium",
    allowClear: true,
  };
  const [portfolioList, setPortfolioList] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [finalChartData, setFinalChartData] = useState([]);
  const [finalBarChartData, setBarFinalChartData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  //   const data = [
  //     {
  //       scenario_id: 332,
  //       name: "Sprint Demo 15",
  //       startTime: "2024-10-01T23:58:20.657Z",
  //       endTime: "2024-12-06T23:57:22.786Z",
  //       portfolio: [
  //         {
  //           portfolio_id: 4,
  //           portfolioname: "Application",
  //           startTime: "2024-10-17T13:00:00.000Z",
  //           endTime: "2024-12-06T23:57:22.786Z",
  //           color: "#FFB381",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 18,
  //           portfolioname: "APQC Process",
  //           startTime: "2024-10-09T23:57:43.656Z",
  //           endTime: "2024-10-09T23:57:43.656Z",
  //           color: "#D4E2C6",
  //           count_items: 1,
  //         },

  //         {
  //           portfolio_id: 1,
  //           portfolioname: "Business Capability",
  //           startTime: "2024-10-01T23:58:20.657Z",
  //           endTime: "2024-10-09T23:58:12.772Z",
  //           color: "#B2E5E5",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 5,
  //           portfolioname: "Business Process",
  //           startTime: "2024-10-12T01:16:05.176Z",
  //           endTime: "2024-10-12T01:16:05.176Z",
  //           color: "#FFF280",
  //           count_items: 1,
  //         },
  //       ],
  //     },

  //     {
  //       scenario_id: 346,
  //       name: "Test  1809 Awa",
  //       startTime: "2024-09-01T01:36:12.430Z",
  //       endTime: "2025-02-07T13:00:00.000Z",
  //       portfolio: [
  //         {
  //           portfolio_id: 4,
  //           portfolioname: "Application",
  //           startTime: "2024-09-01T01:36:12.430Z",
  //           endTime: "2025-02-07T13:00:00.000Z",
  //           color: "#FFB381",
  //           count_items: 4,
  //         },

  //         {
  //           portfolio_id: 18,
  //           portfolioname: "APQC Process",
  //           startTime: "2024-10-08T01:36:50.782Z",
  //           endTime: "2024-11-09T01:36:40.216Z",
  //           color: "#D4E2C6",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 5,
  //           portfolioname: "Business Process",
  //           startTime: "2024-10-02T01:37:45.944Z",
  //           endTime: "2024-12-07T01:37:30.621Z",
  //           color: "#FFF280",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 11,
  //           portfolioname: "Initiative",
  //           startTime: "2024-09-25T01:38:00.902Z",
  //           endTime: "2024-11-09T01:38:06.535Z",
  //           color: "#D4E2D4",
  //           count_items: 6,
  //         },

  //         {
  //           portfolio_id: 12,
  //           portfolioname: "Organization",
  //           startTime: "2024-10-10T01:40:02.037Z",
  //           endTime: "2024-11-11T13:00:00.000Z",
  //           color: "#C4DFDF",
  //           count_items: 3,
  //         },
  //       ],
  //     },
  //   ];

  useEffect(() => {
    getscenariocomparison();

    // getUniquePortFolioFromData();
    // filterDataPortfoliowise();
  }, []);

  useEffect(() => {
    filterDataPortfoliowise();
  }, [selectedPortfolio]);

  useEffect(() => {
    getUniquePortFolioFromData();
    filterDataPortfoliowise();
  }, [data]);

  const getscenariocomparison = () => {
    const integer_array = location?.state?.compare?.compareIds?.map(Number);

    const obj = {
      scenario_ids: integer_array,
    };

    // const obj = {
    //   scenario_ids: [346, 332],
    // };

    ScenarioServices.getscenariocomparison(obj)
      .then((result) => {
        if (result.status === 200) {
          if (result.data) {
            setData(result.data);
          }
        } else {
          message.error("error occured during getting daya");
        }
      })
      .catch((err) => {});
  };

  const getUniquePortFolioFromData = () => {
    const uniquePortfolios = Array.from(
      new Set(
        data?.flatMap((scenario) =>
          scenario?.portfolio?.map((portfolio) =>
            JSON.stringify({
              portfolioname: portfolio?.portfolioname,
              id: portfolio?.portfolio_id,
              color: portfolio?.color,
            })
          )
        )
      )
    )?.map((item) => JSON.parse(item));

    setPortfolioList(uniquePortfolios);
  };

  const filterDataPortfoliowise = () => {
    const dataFinal = [];
    let totalOpex = 0;
    let totalCapex = 0;
    let totalExpenditure = 0;
    let symbol = "";
    const scenarioData1 = data?.map((scenario) => {
      const scenarioData = data?.find((event) => event.name === scenario.name);

      totalOpex = 0;
      totalCapex = 0;
      totalExpenditure = 0;

      scenarioData.portfolio.forEach((portfolio) => {
        portfolio.data.forEach((milestone) => {
          totalOpex += parseFloat(milestone?.opex);
          totalCapex += parseFloat(milestone?.capex);
          totalExpenditure += parseFloat(milestone?.totalexp);
          symbol = milestone?.currency;
        });
      });

      dataFinal.push({
        type: scenarioData?.type,
        id: scenarioData?.id,
        name: scenarioData?.name,
        start: new Date(scenarioData?.start),
        end: new Date(scenarioData?.end),
        progress: scenarioData?.progress,
        hideChildren: scenarioData.hideChildren,
        color: scenarioData.color,
        count_items: scenarioData.count_items,
        opex: totalOpex,
        capex: totalCapex,
        totalexp: totalExpenditure,
        currency: symbol,
        noofRelation: scenarioData?.total_relations,
        relation_modulename: scenarioData?.relation_modulename,
      });

      scenarioData?.portfolio?.map((item) => {
        if (selectedPortfolio.includes(item.portfolio_id)) {
          totalOpex = 0;
          totalCapex = 0;
          totalExpenditure = 0;

          item.data.forEach((milestone) => {
            totalOpex += parseFloat(milestone?.opex);
            totalCapex += parseFloat(milestone?.capex);
            totalExpenditure += parseFloat(milestone?.totalexp);
            symbol = milestone?.currency;
          });

          const {
            type,
            portfolio_id,
            portfolioname: name,
            start,
            end,
            progress,
            project,
            color,
            count_items,
            data: dataItem,
            currency,
          } = item;

          const id = portfolio_id + "_" + scenarioData.id;
          dataFinal.push({
            type: "project",
            istask: "YES",
            textColor: color,
            id,
            name,
            start: new Date(start),
            end: new Date(end),
            color,
            count_items,
            progress,
            project,
            hideChildren: true,
            opex: totalOpex,
            capex: totalCapex,
            totalexp: totalExpenditure,
            currency: currency,
            noofRelation: scenarioData?.total_relations,
            relation_modulename: scenarioData?.relation_modulename,
          });

          if (dataItem.length) {
            dataItem.map((item1) => {
              const {
                type,
                item_id,
                displayname,
                start,
                end,
                progress,
                project,
                opex,
                capex,
                totalexp,
                expiry,
                relation_modulename,
                total_relations,
                currency,
              } = item1;

              const id1 = item_id + "_" + portfolio_id + "_" + scenarioData.id;

              //if (!dataFinal.some((item) => item.id === id1)) {

              dataFinal.push({
                type,
                id: id1,
                name: displayname,
                start: new Date(start),
                end: new Date(end),
                progress: id,
                project: id,
                opex: opex,
                capex: capex,
                totalexp: totalexp,
                //expiry: expiry ? new Date(expiry) : null,
                link:
                  `/portfolio/` +
                  relation_modulename +
                  `/` +
                  scenarioData?.id +
                  `/` +
                  item_id,
                currency: currency,
                noofRelation: total_relations,
                relation_modulename: relation_modulename,
              });
              //}
            });
          }
        }
      });

      // dataFinal.push({
      //   type: scenarioData.type,

      //   name: scenarioData.name,
      //   scenario_id: scenarioData.scenario_id,
      //   startTime: scenarioData.startTime,
      //   endTime: scenarioData.endTime,
      //   color: "",
      //   count_items: "0",
      // });

      // scenarioData?.portfolio?.map((item) => {
      //   if (selectedPortfolio.includes(item.portfolio_id)) {
      //     const { portfolioname, startTime, endTime, color, count_items } =
      //       item;
      //     const name = scenarioData.name + "-" + portfolioname;
      //     dataFinal.push({ name, startTime, endTime, color, count_items });
      //   }
      // });
    });

    //Data processing for bar chart
    const barChartData = [];

    console.log("dataFinal", dataFinal);

    dataFinal?.map((item) => {
      if (item?.type === "project" && !item?.istask) {
        const { name, totalexp } = item;
        const obj = {
          labels: [name],
          start: 0,
          end: totalexp,
          color: "#59A985",
        };
        barChartData.push(obj);
      }

      if (item?.type === "project" && item?.istask === "YES") {
        const { name, totalexp } = item;
        if (totalexp > 0) {
          const obj = {
            labels: [name],
            start: 0,
            end: totalexp,
            color: "#669ec4",
          };
          barChartData.push(obj);
        }
      }

      if (item?.type === "milestone") {
        const { name, totalexp } = item;
        if (totalexp > 0) {
          const obj = {
            labels: [name],
            start: 0,
            end: totalexp,
            color: "#f7a5f1",
          };
          barChartData.push(obj);
        }
      }
    });

    //end  Data Processing  for barchart
    setBarFinalChartData(barChartData);

    setFinalChartData(dataFinal);
  };

  const handleChange = (tags) => {
    setSelectedPortfolio(tags);
  };

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };

  //console.log("object finalChartData", finalChartData);

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <Layout>
        <Layout.Content className="business-capability-landscape-report">
          <section>
            <PageTitleHeading text={"Compare Scenario"} />
          </section>
          <section>
            <Flex className="overflow-x pb-2">
              <Form
                form={filterForm}
                layout="inline"
                style={{ maxWidth: "none" }}
                className="mt-2"
              >
                <Form.Item>
                  <Switch
                    checkedChildren="Timeline"
                    unCheckedChildren="Expenses"
                    defaultChecked
                    onChange={handleSwitchChange}
                    style={{
                      backgroundColor: isChecked ? "#52c41a" : "#ff4d4f",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Select Portfolio">
                  <Select
                    {...selectDefaultProps}
                    mode="tags"
                    placeholder={"Select Portfolio"}
                    allowClear={true}
                    onChange={handleChange}
                  >
                    {portfolioList?.map((item) => {
                      return (
                        <Select.Option value={item.id}>
                          <span
                            className="portfolio-option-color"
                            style={{ "--color": item?.color }}
                          ></span>
                          {item.portfolioname}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </Flex>
            <Flex className="overflow-x pb-2">
              <Button
                type="primary"
                onClick={() => {
                  setTimeout(() => {
                    navigate(`/compare-scenario-v2`);
                  }, 1000);
                }}
                className="px-4"
              >
                Back
              </Button>
            </Flex>
          </section>

          {/* <section>
            <Card className="mt-2">
              <Flex gap={8} className="overflow-x pb-2">
                {(finalChartData && finalChartData.length && (
                  <CompareChart events={finalChartData} />
                )) ||
                  "Data not found"}
              </Flex>
            </Card>
          </section> */}

          <section>
            {(isChecked && (
              <Card className="mt-2">
                {(finalChartData && finalChartData.length && (
                  <CompareChart events={finalChartData} />
                )) ||
                  "Data not found"}
              </Card>
            )) || (
              <Card className="mt-2">
                {(finalChartData && finalChartData.length && (
                  <ExpenseChart events={finalBarChartData} />
                )) ||
                  "Data not found"}
              </Card>
            )}
          </section>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
}

export default CompareScenarioV2;
