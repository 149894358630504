
import React, { createContext, useContext, useReducer } from "react";
const BusinessCapabilityContext = createContext();
export const useBusinessCapability = () =>
  useContext(BusinessCapabilityContext);

const initialState = {
  data: [],
  loading: false,
  error: false,
  allTags: [],
  isEdit: false,
  getOneBusinessCapability: {},
};

const businessCapabilityReducer = (state, action) => {
  switch (action.type) {
    case "SET_BUSINESS_CAPABILITY":
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    case "ADD_BUSINESS_CAPABILITY":
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case "UPDATE_BUSINESS_CAPABILITY":
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_BUSINESS_CAPABILITY":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case "EMPTY_BUSINESS_CAPABILITY":
      return {
        ...state,
        data: [],
      };
    case "SET_ALL_TAGS":
      return {
        ...state,
        allTags: action.payload,
      };
    case "SET_IS_EDIT":
      return {
        ...state,
        isEdit: action.payload,
      };
    case "SET_ONE_BUSINESS_CAPABILITY":
      return {
        ...state,
        getOneBusinessCapability: action.payload,
      };
    default:
      return state;
  }
};

export const BusinessCapabilityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    businessCapabilityReducer,
    initialState
  );

  return (
    <BusinessCapabilityContext.Provider value={{ state, dispatch }}>
      {children}
    </BusinessCapabilityContext.Provider>
  );
};
