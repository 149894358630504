import "./BusinessApplication.scss";
import LoadingBar from "react-top-loading-bar";
import React, { useEffect, useState, useRef } from "react";
import { Tabs, message } from "antd";
import { useLocation, useParams } from "react-router";

import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { useMenu } from "../../../Context/MenuProvider";
import { useBusinessApplication } from "../../../Context/businessApplication";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import { useTabContext } from "../../portfoliosingleentry/TabProvider";
import useFetchPortfolioTag from "../../../hooks/useFetchPortfolioTag";
import TabConnections from "../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections";
import TabInformations from "../../portfoliosingleentry/portfolioTabs/tabinformations/TabInformations";
import TabLinks from "../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks";
import TabDiagrams from "../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams";
import TabComments from "../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments";
import TabChangeLogAlerts from "../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import { PageTitleHeading, addIsTextToProperties, deepFindByName, getRecentlyView_ScenarioName } from "../../../utils/Common";
import { staticSettingPortfolioList } from "../../../utils/settingCommon";
import { GoBack } from "../../../utils/GoBackHistory";

const BusinessApplicationEntry = () => {
    const ref = useRef(null);
    const location = useLocation();
    if (!location?.state) {
        location.state = JSON.parse(sessionStorage.getItem('editData-BA')) || null;
    } else {
        sessionStorage.setItem('editData-BA', JSON.stringify(location?.state))
    }
    const { loading, fetchPortfolio } = usePortfolioFetcher();
    const { stateMenu, dispatchMenu } = useMenu();

    const { state: businessApplicationState, dispatch: businessApplicationDispatch, } = useBusinessApplication();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const [activeTabKey, setActiveTabKey] = useState("tab_information");
    // const { tabDisabled, toggleTabDisabled } = useTabContext();
    const { id = null, moduleName = null, scenarioId } = useParams();
    const [portfolioId, setPortfolioId] = useState("");

    const [title, setTitle] = useState("Add New Application");
    const [formItems, setFormItems] = useState({});

    //Tag
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error, fetchTagsData } = useFetchPortfolioTag();
    const [scenarioName, setScenarioName] = useState();
    const [refreshTab, setrefreshTab] = useState(false);

    //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100,);
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    useEffect(() => {
        getScenarioName();
    }, [scenarioId]);

    const getScenarioName = async () => {
        const scenario = await getRecentlyView_ScenarioName(scenarioId);
        if (scenario?.length) {
            setScenarioName(scenario[0]?.scenarioName);
        }
    };

    useEffect(() => {
        if (!id || id?.toLowerCase() === "add") {
            const newResponse = {
                applicationName: "Add New Application",
            };
            businessApplicationDispatch({
                type: "SET_ONE_BUSINESS_APPLICATION",
                payload: newResponse || {},
            });
            setPortfolioId(null);
        } else {
            setPortfolioId(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (businessApplicationState.getOneBusinessApplication) {
            setTitle(
                businessApplicationState.getOneBusinessApplication.displayname,
            );
        }
    }, [businessApplicationState.getOneBusinessApplication]);


    useEffect(() => {
        fetchAllPortfolio();
        console.log('testing portfolio two state location 3', location);

        if (location?.state?.activeTabKey) {
            handleTabChange(location?.state?.activeTabKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Fetch tags items
    const fetchAllPortfolioTags = async () => {
        try {
            await fetchTagsData();
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.continuousStart();
        }
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName(
                portfolioSettingState?.data,
                staticSettingPortfolioList?.application,
                false,
            );
            // Here fetching tags for particular portfolio
            if (portfolioValues?.moduleName) {
                dispatchTag({ type: "EMPTY_TAGS" });
                dispatchTag({
                    type: "SET_MODULE_NAME",
                    payload: portfolioValues?.moduleName,
                });
            }
            setFormItems(addIsTextToProperties(portfolioValues));
        }
        if (ref.current) {
            ref.current.complete();
        }
    }, [portfolioSettingState?.data]);

    // Tag useEffect start
    useEffect(() => {
        if (stateTag?.moduleName) {
            fetchAllPortfolioTags();
        }
    }, [stateTag?.moduleName]);
    // Tag useEffect end

    const tabsProps = {
        itemData: formItems,
        setActiveTabKey,
        handleTabChange,
        refreshTab,
        setrefreshTab,
        // toggleTabDisabled,
        // tabDisabled,
        id: portfolioId,
        moduleName,
        formItems,
        setFormItems,
        fetchAllPortfolio,
        fetchAllPortfolioTags,
    };

    const PortfolioSingleTabs = [
        {
            key: "tab_information",
            label: "Information",
            disabled: false,
            children: <TabInformations {...tabsProps} locationData={location?.state} />,
        },
        {
            key: "tab_connections",
            label: "Relations",
            disabled: portfolioId ? false : true,
            children: <TabConnections {...tabsProps} />,
        },
        {
            key: "tab_links",
            label: "Links",
            disabled: portfolioId ? false : true,
            children: <TabLinks {...tabsProps} />,
        },
        {
            key: "tab_diagram",
            label: "Diagram",
            disabled: portfolioId ? false : true,
            children: <TabDiagrams {...tabsProps} titleName={title} />,
        },
        {
            key: "tab_comments",
            label: "Comments",
            disabled: portfolioId ? false : true,
            children: <TabComments {...tabsProps} />,
        },
        // {
        //   key: "tab_survey",
        //   label: "Survey",
        //   disabled:portfolioId?false:true,
        //   children: <TabSurveys {...tabsProps} />,
        // },
        {
            //key: "tab_logalerts",
            key: "tab_changelogAlert",
            label: "Change Log/Alerts",
            disabled: portfolioId ? false : true,
            children: <TabChangeLogAlerts {...tabsProps} />,
        },
    ];

    return (
        <div className="container-fluid">
            <LoadingBar color="blue" ref={ref} />
            <div className="business-application-entry-page mb-5">
                <div className="d-flex justify-content-between align-items-center ">
                    <PageTitleHeading
                        text={(() => {
                            if (title) {
                                return "Application: " + title;
                            } else {
                                return `Add New Application`;
                            }
                        })()}
                    />
                    {scenarioId && (<div style={{ background: "#ffaa02", paddingTop: 1, paddingLeft: 5, paddingRight: 5, margin: 3, borderRadius: 5, height: 25 }}>
                        {scenarioName ? "CURRENT WORKSPACE: " + scenarioName : ""}
                    </div>)}

                    <div>
                        <GoBack path={location?.state?.from} state={location?.state} back />
                    </div>
                </div>
                <Tabs
                    type="card"
                    className=""
                    items={PortfolioSingleTabs}
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                />
            </div>
        </div>
    );
};

export default BusinessApplicationEntry;
