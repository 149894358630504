import React, { useEffect,useState } from "react";
import { Checkbox } from "antd";
import Delayed from "../../../utils/Delayed";
import { PortfolioItemDetails } from "../../portfolio/common/PortfolioCommon";
import { checkRole } from "../../../helper/useUserData";
import { useNavigate } from "react-router";
	 
const BusinessProcessListContent = ({page,limit, moduleName,lists,filterText,sortBy,filterBy,filterLevel,ButtonComponents,loading,basePath,DisableItemClass,create_duplicate,...props}) => {
    const [ListData, setListData] = useState(null);
    const [activeRowId, setActiveRowId] = useState(null);
    const navigate = useNavigate();
    
    useEffect(()=>{
        let tempList = generateList(lists);
        setListData(tempList || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[lists])

    const generateList = (items)=>{
        let temp = [];
		if(items?.length > 0) {
			items?.forEach(item=>{
				temp.push(item);
				if(item?.child){
					let temp2 = generateList(item?.child);
					temp = [...temp,...temp2]
				}
			})
		}
        return temp;
    }
    
    const getCard = (item) => {
        return (
            <div key={Math.random()} className={`portfolio-listbx ${(activeRowId===item?.id)?`active-row`:``} ${DisableItemClass({item})?.className || ""}`}>
                <table width={`100%`}>
                    <tbody>
                        <tr>
                            {props?.isListCheckbox && checkRole('checkbox') && <td>
                                    <Checkbox value={item?.id} onChange={props?.onCheckboxChange}/>
                                </td>
                            } 
                            <td width={`95%`}>
                                <PortfolioItemDetails 
                                    routeModuleName={moduleName}
                                    item={item} 
                                    basePath={basePath} 
                                    defaultActiveKey={1} 
                                    badgeValue={item.levelid?item.levelid:item.referenceid}
                                    page={page}
                                    limit={limit}
                                   //onClick={(item)=>{ props?.setEditPortfolio(item);setActiveRowId(item?.id) }} 
                                    //editLink={false}
                                />
                            </td>
                            <td width={`5%`} className="text-nowrap">
                                {ButtonComponents && <ButtonComponents {...props} item={item} basePath={basePath} create_duplicate={create_duplicate}/>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const filterData = (items) =>{
        
        if (filterText?.length > 0) {
            if (filterBy === "displayName") {
                items = items.filter((item) =>
                    item?.DisplayName?.includes(filterText)
                );
            } else if (filterBy === "level") {
                items = items.filter((item) =>
                    item?.Level?.includes(filterText)
                );
            } else if (filterBy === "description") {
                items = items.filter((item) =>
                    item?.DisplayName?.includes(filterText)
                );
            }
        }

        if(filterLevel){
            items = items.filter((item) =>
                item?.Level?.includes(filterLevel)
            );
        }

        items = items?.sort((a, b) => {
            return a?.LevelID?.localeCompare(b?.levelid, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        })
        return items;
    }

    return (
        <div className="w-100">
            {
                ListData && ListData?.length > 0 && filterData(ListData)?.map((item) => {
                    return getCard(item);
                }) || <Delayed waitBeforeShow={3500}><div className={`text-base font-bold mt-4 ${loading?'d-none':''}`}>No items found.</div></Delayed>
            }
        </div>
    );
};

BusinessProcessListContent.defaultProps = {
    DisableItemClass:()=>{ return false},
    ButtonComponents:()=>{},
    lists:[],
    loading:false,
    moduleName:'',
    isListCheckbox:false,
    onCheckboxChange:()=>{}
}
export default BusinessProcessListContent;
