import React, { useState, useEffect } from 'react';
import { Col, Row, Divider, Card, Table, Checkbox, Button, Modal, Drawer, message } from 'antd';
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";
import SubscriberServices from "../../../../services/services/SubscriberServices";

import ChangeLogList from "./ChangeLogsList";

import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import { applicationsList } from "../../../../utils/businessApplication";
import moment from 'moment';
import TextWithTooltip from './../../../../utils/TextWithTooltip';
import { checkRole } from '../../../../helper/useUserData';
import { Link } from 'react-router-dom';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    FullscreenExitOutlined,
    FullscreenOutlined,
    EyeOutlined,
    DeploymentUnitOutlined,
    PictureOutlined
} from "@ant-design/icons";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { PageTitleHeading } from '../../../../utils/Common';
import DiagramData from '../tabdiagrams/DiagramData';
import { error } from 'jquery';
import { auto } from '@popperjs/core';
import DeleteSvg from "../../../../assets/Remove.svg";



const TabChangeLogAlerts = ({ setActiveTabKey, toggleTabDisabled, id: modelId, moduleName: moduleName, ...props }) => {
    //const [data1,setData1]=useState();
    const { state, dispatch } = useApplicationChangeLogs();
    const [, setbusinessApplicationId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [modelDiagramDifference, setmodelDiagramDifference] = useState(false);
    const [modelXMLDiagramDifference, setmodelXMLDiagramDifference] = useState(false);
    const handle = useFullScreenHandle();
    const [fullscreen, setFullscreen] = useState(false);
    const [fullscreenXML, setFullscreenXML] = useState(false);
    const [oldDiagramData, setOldDiagramData] = useState(null);
    const [newDiagramData, setNewDiagramData] = useState(null);
    const [oldXMLDiagramData, setOldXMLDiagramData] = useState(null);
    const [newXMLDiagramData, setNewXMLDiagramData] = useState(null);
    const [oldPreview, setOldPreview] = useState(false);
    const [newPreview, setNewPreview] = useState(false);
    const [oldPreviewXML, setOldPreviewXML] = useState(false);
    const [newPreviewXML, setNewPreviewXML] = useState(false);
    const [subscriberData, setSubscriberData] = useState([]);
    const [createLoading, setCreateLoading] = useState(false);

    const userID = localStorage.getItem("email");

    const fetchData = async ({ moduleName, modelId }) => {
        try {
            setLoading(true);
            const response = await ChangeLogsServices.getChangeLogsList({
                moduleName,
                modelId,
            });

            const newData = response.data.data;
            await dispatch({ type: "SET_LINK", payload: newData });
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const fetchSubscriber = async (objParam) => {
        try {
            const response = await SubscriberServices.getSubscriber(objParam);
            if (response?.data?.data && response?.data?.data?.length) {
                console.log("Subscriber Data", response?.data?.data);
                setSubscriberData(response?.data?.data);
            } else {
                setSubscriberData([]);
            }
        }
        catch (err) {
            setSubscriberData([]);
            console.log("Error.", err);
        }
    };

    const CreateSubscriber = async (objParam) => {
        try {

            setCreateLoading(true);

            const response = await SubscriberServices.createSubscriber(objParam);
            if (response?.data?.code == 200) {
                message.success("Subscriber added successfully");
                setCreateLoading(false);
                fetchSubscriber(objParam);
            }
            else {
                setCreateLoading(false);
                message.error(response?.data?.msg);
            }
        }
        catch (err) {
            console.log("Error.", err);
            setCreateLoading(false);
        }
    };

    const RemoveSubscriber = async (objParam) => {
        try {


            const response = await SubscriberServices.removeSubscriber(objParam);
            if (response?.data?.code == 200) {
                message.success("Subscriber removed successfully");
                fetchSubscriber(objParam);
            }
            else {
                message.error(response?.data?.msg);
            }
        }
        catch (err) {
            console.log("Error.", err);

        }
    };


    useEffect(() => {
        fetchData({ moduleName: moduleName, modelId: modelId })
    }, [props.refreshTab])

    useEffect(() => {
        setbusinessApplicationId(state.businessApplicationId);
        fetchData({
            moduleName: moduleName,
            modelId: modelId,
        });

        //get Subscriber 
        fetchSubscriber({ ModuleName: moduleName })

    }, [state.businessApplicationId, setActiveTabKey]);

    useEffect(() => {
        setData(state.data);
    }, [state.data]);

    const columns = [
        {
            title: 'Change Description',
            dataIndex: 'DataField',
            key: 'DataField',
            render: (text) => <TextWithTooltip text={text} characterLimit={20} />
        },
        // {
        //     title: 'Old Value',
        //     dataIndex: 'OldValue',
        //     key: 'OldValue',
        //     render:(text)=><TextWithTooltip text={text} characterLimit={35}/>
        // },
        {
            title: 'Old Value',
            dataIndex: 'OldValue',
            key: 'OldValue',
            render: (text, record) => {
                if (text && text?.startsWith("data:image/svg+xml")) {
                    return <Link characterLimit={20} onClick={() => {
                        setOldDiagramData(record?.OldValue?.toString());
                        setNewDiagramData(record?.NewValue?.toString());
                        setOldPreview(true)
                        setNewPreview(false)
                        setmodelXMLDiagramDifference(false)
                        setmodelDiagramDifference(true);
                    }}>{text && text?.startsWith("data:image/svg+xml") && "View Old SVG Diagram"} </Link>;
                } else if (text && text?.startsWith("<?xml version") || text?.startsWith("<mxGraphModel") || text?.startsWith("<mxfile host")) {
                    console.log("Records Old Value>>>>>>>>>>>>>>>>>>>>", record)
                    return <Link characterLimit={20} onClick={() => {
                        setOldXMLDiagramData(record?.OldValue?.toString());
                        setNewXMLDiagramData(record?.NewValue?.toString());
                        setOldPreviewXML(true)
                        setNewPreviewXML(false)
                        setmodelDiagramDifference(false)
                        setmodelXMLDiagramDifference(true)
                    }}>View Old XML Diagram </Link>
                } else {
                    return <TextWithTooltip text={text} characterLimit={35} />;
                }
            }
        },
        {
            title: 'New Value',
            dataIndex: 'NewValue',
            key: 'NewValue',
            render: (text, record) => {
                if (text && text?.startsWith("data:image/svg+xml")) {
                    return <Link characterLimit={20} onClick={() => {
                        if (text && text?.startsWith("data:image/svg+xml")) {
                            setOldDiagramData(record?.OldValue?.toString())
                            setNewDiagramData(record?.NewValue?.toString());
                            setNewPreview(true)
                            setOldPreview(false)
                            setmodelXMLDiagramDifference(false);
                            setmodelDiagramDifference(true);
                        }
                        console.log("Records New Value>>>>>>>>>>>>>>>>>>>>", text);

                    }} >{text && text?.startsWith("data:image/svg+xml") && "View New SVG Diagram"} </Link>;
                } else if (text && text?.startsWith("<?xml version") || text?.startsWith("<mxGraphModel") || text?.startsWith("<mxfile host")) {
                    return <Link characterLimit={20} onClick={() => {
                        setOldXMLDiagramData(record?.OldValue?.toString());
                        setNewXMLDiagramData(record?.NewValue?.toString());
                        setNewPreviewXML(true)
                        setOldPreviewXML(false)
                        setmodelDiagramDifference(false)
                        setmodelXMLDiagramDifference(true)
                        console.log("Records New Value>>>>>>>>>>>>>>>>>>>>1", text);

                    }} >View New XML Diagram </Link>;
                } else {
                    return <TextWithTooltip text={text} characterLimit={25} />;
                }
            }
        },
        {
            title: 'User',
            key: 'user_name',
            dataIndex: 'user_name',
            width: '100px',
            render: (_, { user_name }) => (
                user_name?.name
            ),
        },
        {
            title: 'Date Time',
            key: 'createdAt',
            dataIndex: 'createdAt',
            width: '100px',
            render: (_, { createdAt }) => (
                new Date(createdAt).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
        },
    ];

    const PreviewDiagram = () => {

        return (
            <FullScreen handle={handle} className="p-2">
                <div className="card w-100 border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom">
                        <PageTitleHeading text={"Diagram View and Difference"} size={4} />
                        {/* <Tooltip title={fullscreen?'Exit':'Fullscreen'}> */}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <Button icon={<PictureOutlined />} onClick={() => {
                                setNewPreview(true)
                                setOldPreview(true)
                            }}></Button>
                            <Button
                                onClick={() => {
                                    setFullscreen(!fullscreen);
                                }}
                                icon={
                                    fullscreen ? (
                                        <FullscreenExitOutlined />
                                    ) : (
                                        <FullscreenOutlined />
                                    )
                                }
                                className="pull-right"
                            />
                        </div>
                    </div>
                    {<div className="card-body" style={{ maxHeight: "60vh", overflowY: "scroll", display: 'flex', flexDirection: 'column', justifyContent: `${oldPreview && newPreview ? "space-around" : 'center'}`, alignItems: "center", gap: 10 }}>
                        {oldDiagramData && oldPreview && <div >
                            <object
                                data={oldDiagramData && oldDiagramData}
                                alt={"selectdiagramData?.name"}
                                className="img-fluid"
                                type="image/svg+xml"
                                style={{ maxHeight: window?.innerHeight / 2 }}
                            ></object></div>}

                        {newDiagramData && newPreview && <div >
                            <object
                                data={newDiagramData && newDiagramData}
                                alt={"selectdiagramData?.name"}
                                className="img-fluid"
                                type="image/svg+xml"
                                style={{ maxHeight: window?.innerHeight / 2 }}
                            ></object></div>}


                    </div>}
                </div>
            </FullScreen>
        )
    }

    const PreviewDiagramXML = () => {

        return (
            <FullScreen handle={handle} className="p-2">
                <div className="card w-100 border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom">
                        <PageTitleHeading text={"Diagram View and Difference"} size={4} />
                        {/* <Tooltip title={fullscreen?'Exit':'Fullscreen'}> */}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <Button icon={<PictureOutlined />} onClick={() => {
                                setOldPreviewXML(true)
                                setNewPreviewXML(true)
                            }}></Button>
                            <Button
                                onClick={() => {
                                    setFullscreenXML(!fullscreenXML);
                                }}
                                icon={
                                    fullscreenXML ? (
                                        <FullscreenExitOutlined />
                                    ) : (
                                        <FullscreenOutlined />
                                    )
                                }
                                className="pull-right"
                            />
                        </div>
                    </div>
                    {<div className="card-body" style={{ height: `${fullscreenXML ? "100vh" : window.innerHeight - 200}px`, overflow: "scroll", display: 'flex', flexDirection: 'column', justifyContent: `${oldPreviewXML && newPreviewXML ? "space-between" : 'flex-start'}`, alignItems: "center", gap: 100 }}>
                        {oldXMLDiagramData && oldPreviewXML && <div >
                            <div /* style={{ marginTop: `${newPreviewXML === false ? 600 : fullscreenXML ? 0 : 1200}px` }}*/>
                                <DiagramData
                                    dataGraphXML={oldXMLDiagramData && oldXMLDiagramData}
                                /></div>
                        </div>}
                        {/* style={{ transform: 'scale(0.7, 0.7)' }} */}
                        {/* style={{ transform: 'scale(0.7, 0.7)' }} */}
                        {newXMLDiagramData && newPreviewXML && <div >
                            <div /* style={{ marginTop: `${oldPreviewXML === false ? 600 : -400}px` }}*/>
                                <DiagramData
                                    dataGraphXML={newXMLDiagramData && newXMLDiagramData}
                                /></div></div>}


                    </div>}
                </div>
            </FullScreen>
        )
    }

    return <>
        <Row className="justify-content-start">
            <Col span={24}>
                {/* <h5>Change log/Alerts</h5> */}
                <div className="txtdes">
                    <p>
                        <span>(this screen shows changes done to current object and also shows users who have subscribed to be notified if current object gets changed )</span>

                    </p>
                </div>
                <Divider />
                <Row className="justify-content-start">
                    <Col span={20}>
                        <div className="difference-viewer">
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <div className="card-body">
                                        <Table columns={columns} dataSource={data} rowKey={'id'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="difference-viewer" style={{ marginLeft: 5 }}>
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <div className="card-body">
                                        <h5>Subscribers</h5>
                                        <p>
                                            <span>(users who have subscribed to be alerted on changed )</span>
                                        </p>

                                        {/* {checkRole('checkbox') && <Checkbox><span className='fw-medium'>Add me as Subscriber</span></Checkbox>}
                                         */}

                                        {subscriberData?.map((item) => (
                                            <>
                                                <div style={{ paddingLeft: 5, paddingRight: 3, paddingBottom: 2, paddingTop: 2, backgroundColor: '#fff', margin: 1, borderRadius: 5 }}>
                                                    <Row className="justify-content-start">
                                                        <Col span={20}>
                                                            <b><TextWithTooltip text={item?.user_name?.name}
                                                                characterLimit={20} /> </b>
                                                            <TextWithTooltip text={item?.UserID}
                                                                characterLimit={20} />
                                                        </Col>
                                                        <Col span={4}>
                                                            
                                                            {userID===item?.UserID &&(  
                                                            <Button
                                                                icon={
                                                                    <img src={DeleteSvg} alt="Delete" width={20} />
                                                                }
                                                                style={{ width: "20px" }}
                                                                //shape={`circle`} 
                                                                onClick={() => {
                                                                    const obj = { ModuleName: moduleName, UserID: item?.UserID };
                                                                    RemoveSubscriber(obj);
                                                                    // Modal.confirm({
                                                                    //     centered: true,
                                                                    //     title: "Do you want to remove this portfolio?",
                                                                    //     icon: <ExclamationCircleFilled />,
                                                                    //     // content: 'Some descriptions',
                                                                    //     onOk() {
                                                                    //         deletePortfolio({
                                                                    //             scenarioid: scenarioId,
                                                                    //             portfolioid: item?.id,
                                                                    //         });
                                                                    //     },
                                                                    //     onCancel() {
                                                                    //         // console.log('Cancel');
                                                                    //     },
                                                                    // });
                                                                }}
                                                            />)}
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </>
                                        ))}

                                        <br />
                                        <Button type="primary"
                                            loading={createLoading}
                                            onClick={() => {
                                               
                                                const obj = { ModuleName: moduleName, UserID: userID };
                                                CreateSubscriber(obj);
                                            }}>Add me as Subscriber</Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Modal
            open={modelDiagramDifference}
            title="Title"
            onOk={() => {
                setmodelDiagramDifference(false)
                setOldPreview(false)
                setNewPreview(false)
            }}
            onCancel={() => {
                setmodelDiagramDifference(false)
                setOldPreview(false)
                setNewPreview(false)

            }}
        // footer={[
        //     <Button key="back"
        //     onClick={()=>setOldPreview(true)}
        //     >
        //         view Old Diagram
        //     </Button>,
        //     <Button key="submit" type="primary"
        //     >
        //         Submit
        //     </Button>,

        // ]}
        >
            <PreviewDiagram />
        </Modal>
        <Modal
            open={modelXMLDiagramDifference}
            width={"95vw"}
            title="Title"
            onOk={() => {
                setmodelXMLDiagramDifference(false)
                setOldPreviewXML(false)
                setNewPreviewXML(false)
            }}
            onCancel={() => {
                setmodelXMLDiagramDifference(false)
                setOldPreviewXML(false)
                setNewPreviewXML(false)

            }}
        >
            <PreviewDiagramXML />
        </Modal>
        <Drawer
            open={fullscreen}
            onClose={() => {
                setFullscreen(!fullscreen);
            }}
            title={false}
            closeIcon={false}
            destroyOnClose={true}
            width={window?.innerWidth}
            height={window?.innerHeight}
            placement={`top`}
        >
            <PreviewDiagram />
        </Drawer>
        <Drawer
            open={fullscreenXML}
            onClose={() => {
                setFullscreenXML(!fullscreenXML);
            }}
            title={false}
            closeIcon={false}
            destroyOnClose={true}
            width={window?.innerWidth}
            height={window?.innerHeight}
            placement={`top`}
        >
            <PreviewDiagramXML />
        </Drawer>
    </>
}

export default TabChangeLogAlerts;