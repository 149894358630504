import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

async function saveSheetAzure(formData) {
    const url = `${API_BASE_URL}etl/dumpSheetAzure`;
    let response = await http.post(url, formData,{ headers: { "content-type": "multipart/form-data" },});
    return response;
}

async function saveSyncData(formData) {
    const url = `${API_BASE_URL}etl/manualSync`;
    let response = await http.post(url, formData);
    return response;
}

async function saveAttributeData(formData) {
    const url = `${API_BASE_URL}etl/dumpAttribute`;
    let response = await http.post(url, formData,{ headers: { "content-type": "multipart/form-data" },});
    return response;
}

async function getAvailableApis(params) {
    const url = `${API_BASE_URL}etl/getAvailableApis${params ? `?${encodeQueryData(params)}` : ''}`;
    let response = await http.get(url);
    return response;
}

const dumpAttribute_URL =  `${API_BASE_URL}etl/dumpAttribute`;
const dumpSheetAzure_URL =  `${API_BASE_URL}etl/dumpSheetAzure`;

async function saveImportMaster(formData) {
    const url = `${API_BASE_URL}etl/importMasters`;
    let response = await http.post(url, formData,{ headers: { "content-type": "multipart/form-data" },});
    return response;
}


async function SaveImportMasters_AllPortfolio(formData) {
    const url = `${API_BASE_URL}etl/importAllPortfolio`;
    let response = await http.post(url, formData,{ headers: { "content-type": "multipart/form-data" },});
    return response;
}


async function getExcelTemplate(data) {
    const url = `${API_BASE_URL}etl/getExcelTemplate`;
    let response = await http.post(url, data);
    return response;
  }


  
async function ExportAllPortfolio(data, controller = null) {
  const url = `${API_BASE_URL}etl/ExportAllPortfolio`;
  let response;
  if (controller) {
    response = await http.post(url, data, controller);
  } else {
    response = await http.post(url, data);
  }

  return response;
}

async function getImportExportStatus(id) {
    const url = `${API_BASE_URL}etl/import-export-status/`;
    let response = await http.get(url + id);
    return response;
  }



const DataImportScenarioServices = {
    saveSheetAzure,
    saveSyncData,
    saveAttributeData,
    getAvailableApis,
    dumpAttribute_URL,
    dumpSheetAzure_URL,
    saveImportMaster,
    SaveImportMasters_AllPortfolio,
    getExcelTemplate,
    ExportAllPortfolio,
    getImportExportStatus
}


export default DataImportScenarioServices;