import React, { useEffect, useState } from "react";
import { Waterfall } from '../../chart/horizontalBarChart/Waterfall'
import { color } from "d3";

function ExpenseChart({events}) {
 

    const [taskList, setTaskList] = useState([]);
    useEffect(() => {
        if (events) {
          setTaskList(events);
        }
      }, [events]);

    // const data = [
    //     { year: 'Scenario-', value: 38, color: '#497AAB' },
    //     { year: 'Application Portfolio', value: 52, color: '#497AAB' },
    //     { year: 'Capability ', value: 61, color: '#497AAB' },
    //     { year: 'Scenario- 2', value: 38, color: '#497AAB' },
    //     { year: 'Application Portfolio 2', value: 52, color: '#497AAB' },
    //     { year: 'Capability 2', value: 61, color: '#497AAB' },
    //     { year: 'Scenario-3', value: 38, color: '#497AAB' },
    //     { year: 'Application Portfolio 3', value: 52, color: '#497AAB' },
    //     { year: 'Capability 3', value: 61, color: '#497AAB' },
    // ];

    // const config = {
    //     data: data,
    //     xField: 'year',
    //     yField: 'value',
    //     sort: {
    //         reverse: true,
    //     },
    //     label: {
    //         // text: 'frequency',
    //         // formatter: '.1%',
    //         style: {
    //             // textAlign: (d) => (+d.value > 0.008 ? 'right' : 'start'),
    //             // fill: (d) => (+d.value > 0.008 ? '#fff' : '#000'),
    //             dx: (d) => (+d.value > 0.008 ? 25 : 5),
    //         },
    //         text: 'value',
    //         // textBaseline: 'bottom',
    //         position: 'right',
    //         textAlign: 'center',
    //     },
    //     tooltip: false,
    //     style: {
    //         fill: ({ color }) => {
    //             return color;
    //         },
    //         maxWidth: 40,
    //     },
    //     state: {
    //         // unselected: { opacity: 0.5 },
    //         // selected: { lineWidth: 3, stroke: 'red' },
    //     },
    //     interaction: {
    //         elementSelect: true,
    //     },

    //     scale: {
    //         x: {
    //             padding: 2,
    //         },
    //     },

    //     legend: {
    //         position: 'top-right', // Position of the legend (e.g., 'top-left', 'top-right', 'bottom-left', 'bottom-right')
    //         layout: 'horizontal', // Layout type ('horizontal' or 'vertical')
    //         marker: {
    //             symbol: 'circle', // Legend item shape (e.g., 'circle', 'square', 'line')
    //         },
    //         itemName: {
    //             style: {
    //                 fontSize: 12, // Font size for legend items
    //                 fill: '#000',  // Color for legend item text
    //             },
    //         },
    //         itemValue: {
    //             style: {
    //                 fontSize: 12,
    //                 fill: '#888', // Color for legend value text
    //             },
    //         },
    //     },
    // };

    //return <Bar {...config}   />; 

    // const testData = [
    //     {
    //         labels: ['Paragraphs are the building blocks of papers.'],
    //         start: 0,
    //         end: 3,
    //         color:'#59A985'
    //     },
    //     {
    //         labels: ['the unity and coherence of ideas among sentences '],
    //         start: 0,
    //         end: 5
    //     },
    //     {
    //         labels: ['step 3'],
    //         start: 0,
    //         end: 4,
    //     },
    //     {
    //         labels: ['step 4'],
    //         start: 0,
    //         end: 8
    //     },
    //     {
    //         labels: ['step 5'],
    //         start: 0,
    //         end: 9
    //     },
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 10,
    //     }
    //     ,
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 12,
    //     }
    //     ,
    //     {
    //         labels: ['A paragraph is defined as “a group of sentences'],
    //         start: 0,
    //         end: 11,
    //         color:'#59A985'
    //     },
    //     {
    //         labels: ['Paragraphs are the building blocks of papers.'],
    //         start: 0,
    //         end: 3
    //     },
    //     {
    //         labels: ['the unity and coherence of ideas among sentences '],
    //         start: 0,
    //         end: 5
    //     },
    //     {
    //         labels: ['step 3'],
    //         start: 0,
    //         end: 4,
    //     },
    //     {
    //         labels: ['step 4'],
    //         start: 0,
    //         end: 8
    //     },
    //     {
    //         labels: ['step 5'],
    //         start: 0,
    //         end: 9
    //     },
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 10,
    //     }
    //     ,
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 12,
    //     }
    //     ,
    //     {
    //         labels: ['A paragraph is defined as “a group of sentences'],
    //         start: 0,
    //         end: 11,
    //     },{
    //         labels: ['A paragraph is defined as “a group of sentences'],
    //         start: 0,
    //         end: 11,
    //     },
    //     {
    //         labels: ['Paragraphs are the building blocks of papers.'],
    //         start: 0,
    //         end: 3
    //     },
    //     {
    //         labels: ['the unity and coherence of ideas among sentences '],
    //         start: 0,
    //         end: 5,
    //         color:'#59A985'
    //     },
    //     {
    //         labels: ['step 3'],
    //         start: 0,
    //         end: 4,
    //     },
    //     {
    //         labels: ['step 4'],
    //         start: 0,
    //         end: 8
    //     },
    //     {
    //         labels: ['step 5'],
    //         start: 0,
    //         end: 9
    //     },
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 10,
    //     }
    //     ,
    //     {
    //         labels: ['step 6'],
    //         start: 0,
    //         end: 12,
    //     }
    //     ,
    //     {
    //         labels: ['A paragraph is defined as “a group of sentences'],
    //         start: 0,
    //         end: 50,
    //     }
    // ];

    return ((taskList && taskList?.length) && <Waterfall data={taskList} />);
}


export default ExpenseChart;