import React, { useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiList = () => {
    return (
        <div><SwaggerUI url="/openapi.yml" /></div>
    )
}
export default ApiList;

