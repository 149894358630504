import React, { useEffect } from 'react'
import { Pie } from '@antv/g2plot';
import { useNavigate } from 'react-router';

const LifeCycleStatusChart = ({lifeCyclechartData}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const piePlot = new Pie('life-cycle-status-chart', {
      appendPadding: 10,
      data:lifeCyclechartData,
      angleField: 'value',
      colorField: 'type',
      radius: 0.85,
      label: {
        type: 'spider',
        labelHeight: 28,
        content: '{name}: {value}',
      },
      legend:false,
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    });
      
    piePlot.render();

    // Add click event listener
    piePlot.on('element:click', (event) => {
      const { data } = event;
      if(data?.data?.id){
        navigate('/portfolio/BusinessApplication',{ state: {filter:{ "status": data?.data?.id?[data?.data?.id]:[]}} })
      }
    });

    piePlot.on('element:label:click', (event) => {
      const { data } = event;
      if(data?.data?.id){
        navigate('/portfolio/BusinessApplication',{ state: {filter:{ "status": data?.data?.id?[data?.data?.id]:[]}} })
      }
    });

    // Cleanup on component unmount
    return () => {
      piePlot.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCyclechartData]);

  return <div id="life-cycle-status-chart"  />;
}

export default LifeCycleStatusChart
