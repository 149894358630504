import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Radio ,Form, Flex} from "antd";

const CustomDiagramModal = ({
	visible,
	onCancel,
	onOk,
	initialValues,
	okText,
	cancelText,
	closable,
	loading,
	setLoading,
	okText2,
}) => {
	const [form] = Form.useForm();
	const [formData,setFormData] = useState({});
	
	useEffect(()=>{
		setFormData(initialValues)
	},[initialValues])

	const onFinish = (values) =>{
		let mName = initialValues?.moduleName.charAt(0).toLowerCase() + initialValues?.moduleName.slice(1)
		delete initialValues[mName];
		delete initialValues?.createdAt;
		delete initialValues?.updatedAt;
		values = { ...initialValues,...values};
		let openEditor = false;
		if(values?.diagram_type==="diagram_editor" && !initialValues?.id){
			openEditor = true;
		}
		setLoading(true);
		onOk({formData:values,openEditor});
	}

	return (
		<Modal open={visible} title="" onCancel={onCancel} destroyOnClose={true} maskClosable={false} closable={closable} footer={null} width={600}>
			<Form
				form={form}
				initialValues={initialValues}
				requiredMark={false}
				layout="vertical"
				onFinish={onFinish}
			>
				<div className="card card-body">
					<Form.Item label={'Module Name'} name={`moduleName`} className="d-none"><Input/></Form.Item>
					<Form.Item label="Name" name={`name`} rules={[{ required: true }]} className="mb-2">
						<Input placeholder="Name" />
					</Form.Item>
					<Form.Item label="Description" name={`description`} rules={[{ required: false }]} className="mb-3">
						<Input.TextArea placeholder="Description" />
					</Form.Item>
					<Form.Item name={`diagram_type`} rules={[{ required: true,message:"Diagram type is required" }]} className="mb-2">
						<Radio.Group buttonStyle="solid" onChange={(e)=> { setFormData({...formData,['diagram_type']:e?.target?.value}) }}>
							{
								diagramTypes?.map(item=>{
									return <Radio.Button 
										key={Math.random()} 
										value={item?.value} 
										disabled={initialValues?.id?true:false}
									>
										<div style={{...colorStyleCard,background:item?.color,float:'left',marginTop:7,marginRight:2}}/> 
										{item?.label}
									</Radio.Button>
									
								})
							}
						</Radio.Group>
					</Form.Item>
					<Form.Item name={`embed_diagram`} rules={[{ required: false }]} className={`mb-2 ${formData?.diagram_type==="embed_diagram"?'':'d-none'}`}>
						<Input.TextArea placeholder="Embed Diagram" />
					</Form.Item>
				</div>
				<Flex gap={8} align="center" justify="flex-end" className="mt-4">
					<Button type="default" htmlType="button" onClick={onCancel} className="mr-2" disabled={loading} loading={loading}>{cancelText}</Button>
					{/* <Button type="primary" htmlType="submit" className="mr-2" disabled={loading} loading={loading}>{okText}</Button> */}
					<Button type="primary" htmlType="submit" className="mr-2" disabled={loading} loading={loading}>
						{
							((()=>{
								if(formData?.diagram_type==="diagram_editor"){
									return okText2;
								}else{
									return okText;
								}
							})())
						}
					</Button>
				</Flex>
			</Form>
		</Modal>
	);
};
export default CustomDiagramModal;

export const diagramTypes = [
	{
		value:'diagram_editor',
		label: 'Diagram Editor',
		color: '#FF82AB',
	},
	{
		value:'embed_diagram',
		label: 'Embed Diagram',
		color: '#FFFF99',
	},
	{
		value:'data_as_diagram',
		label: 'Diagram As Data',
		color: '#00BFFF',
	},
]

export const colorStyleCard = {
    width:15,
    height:15,
    border:'1px solid #ddd',
    borderRadius:3,
}