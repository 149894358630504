import React, { useEffect, useState, useRef } from "react";
import { Divider, Flex, Spin, Input, Button, Tooltip } from "antd";
import { PageTitleHeading } from "../../../utils/Common";
import LoadingBar from "react-top-loading-bar";
import VideoGuideList from "./VideoGuideList"; 
import GlobalSearchServices from "../../../services/services/GlobalSearchServices";
import { TableOutlined, UnorderedListOutlined } from "@ant-design/icons";
import VideoGuideGrid from "./VideoGuideGrid";

const VideoGuidePage = () => {
  const loadingBarRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(1);
  const [videoDataList, setVideoDataList] = useState([]);
 
  
  const fetchShortGuideVideo = async (data) => {
    try {
      setLoading(true);
      const response = await GlobalSearchServices.fetchUserGuideVideo(data);
      if (response.status == 200) {
        setVideoDataList(response.data);
        setData(response.data);
      } else {
        setVideoDataList([]);
        setData([]);
        console.log("Response Error...", response.error);
      }
    } catch (err) {
      console.log("Error...", err);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShortGuideVideo({});
  }, []);

  const onSearch = (value, _e, info) => {
    console.log(info?.source, value);
    const obj = { searchText: value };

    fetchShortGuideVideo(obj);
  };

  

  return (
    <>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <div className="container-fluid">
        <Spin className="loading_bx" size="small" spinning={loading}>
          <Flex gap={8} justify="space-between" align="center">
            <Flex gap={8} align="center">
              <PageTitleHeading text={`User Guide Library`}/>
              <Tooltip title="Grid View">
                <Button icon={<TableOutlined />} type={tab===1 ? "primary":"default" } ghost={tab===1} onClick={()=>{ setTab(1) }}/>
              </Tooltip>
              <Tooltip title="List View">
                <Button icon={<UnorderedListOutlined />} type={tab===2 ? "primary":"default" } ghost={tab===2} onClick={()=>{ setTab(2) }}/>
              </Tooltip>
            </Flex>
            <Input.Search
              placeholder="search video"
              allowClear
              onSearch={onSearch}
              style={{
                width: 400,
              }}
            />
          </Flex>
          {
            ((()=>{
              switch (tab) {
                case 1:
                  return <VideoGuideGrid  data={data}/>;
                case 2:
                  return <VideoGuideList data={data}/>;
                default:
                  return <VideoGuideGrid  data={data}/>;
              }
            })())
          }
          
        </Spin>
      </div>
    </>
  );
};

export default VideoGuidePage;
