import React, { useEffect, useState } from 'react';
import { Layout, Col, Row, Button, Flex, Dropdown, Popover  } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import RDlogo from '../../../assets/images/RD-logo-01.jpeg';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import GlobalSearch from './GlobalSearch';
import "bootstrap";
import SideMenu from '../sideBar/SideMenuItems';
import SideBar from '../sideBar/SideBar';
// import SideBar from '../sideBar/SideBar';
import UserService from '../../../services/UserService';
import { BellOutlined } from '@ant-design/icons';
import NotificationHeader from './NotificationHeader';
import { auto } from '@popperjs/core';



const { Header } = Layout;



function AppHeader() {

    // const [collapsed, setCollapsed] = useState(false);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        let menuTemp = [];
        //menuTemp= await SideMenu();

        SideMenu().then((result) => {
            menuTemp = result;
            setMenu((menuTemp?.length && menuTemp) || []);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SideMenu]);

    return (
        <Header className="header">
            <nav className="menuBar">
                <Row>
                    <Col flex={`150px`} className="logo">
                        <Link to="/">
                            <img src={RDlogo} alt="logo" />
                            <h4>Connected Architecture</h4>
                        </Link>
                    </Col>
                    <Col xl={10} lg={9} md={4} sm={4} xs={2}>
                        <SideBar menus={menu}/>
                    </Col>
                    <Col xl={4}  lg={3} md={4} sm={6} xs={8}>
                        <GlobalSearch />
                    </Col> 
                    <Col xl={6}  lg={8} md={10} sm={2} xs={5}>
                        <HeaderMenus menus={HeaderMenusItems}/>
                    </Col>
                </Row>
            </nav>
        </Header>
    );
}

export default AppHeader;
