import React, { useState, useRef, useEffect } from 'react';
import { Collapse, List, Tooltip, Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BPMLevel3 from './BPMLevel3';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
const BPMLevel2 = ({ levelIndex, ...props }) => {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const { moduleName: moduleNamePath } = useParams();
    const [activeKey, setAcitveKey] = useState([])
    const [bcm2Index, setBcm2Index] = useState(null)


    useEffect(() => {


        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);



    const onChange = (key) => {
        props.setAcitveKey(key)
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        props?.setItemDelete(props.data, { type: 'delete' });
    };

    const createHierarchyArray = (val) => {
        const parts = val.split('.');
        const result = [];

        for (let i = 1; i <= parts.length; i++) {
            result.push(parts.slice(0, i).join('.'));
        }

        return result;
    }


    const NewItem = ({ ...props }) => {
        const [inputItem, setInputItem] = useState(null)
        const inputRef = useRef(null)

        useEffect(() => {
            inputRef.current.focus()
        }, [])

        const onChange = (key) => {
            //props.setAcitveKey(key)
        };

        const handleKeyPress = async (e) => {
            if (e.key === 'Enter') {
                const obj = {
                    referenceid: 413,
                    displayname: inputItem,
                    attributes: [],
                    level: `L${levelIndex + 1}`,
                    levelid: props.newLevelId,
                    parentid: props.parentid,
                    sourceid: 1,
                }
                
                await props.create_Duplicate(obj)
                console.log('testing bcm level 2>>>>>active-keys obj',props);
                props.setBcm2Index(null)
                

            }
            
        }


        return (
            <>
                <Collapse
                    activeKey={props.activeKey}
                    style={{ backgroundColor: '#fff' }}
                    onMouseEnter={(event) => event.stopPropagation()}
                    onMouseLeave={(event) => event.stopPropagation()}
                    onChange={onChange}
                    //onClick={() => { props?.setItem(props.data); props?.setModalData({ level: (levelIndex + 1), ParentID: props?.data?.id }); }}
                    expandIconPosition={'start'}

                    items={[
                        {
                            key: props.key,
                            label: <>
                                <Tooltip
                                    title={"Enter Display Name"}
                                //onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                                >

                                    <Input onPressEnter={handleKeyPress} style={{ width: '100%' }} defaultValue="Enter Display Name" value={inputItem} onChange={(e) => setInputItem(e.target.value)} ref={inputRef} />
                                    {/* {props.data.levelid} {props.data.displayname} */}
                                </Tooltip>
                            </>,
                            extra: <>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                                    <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                                        e.stopPropagation();
                                        //setDropdown(!dropdown);
                                    }}>
                                        <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                                    </button>
                                    {/* {
                                        dropdown && (

                                            <ul ref={dropdownRef} style={{ padding: "0px 3px", display: 'flex', flexDirection: 'row-reverse', gap: 5, position: 'fixed', marginLeft: 70, backgroundColor: '#ffffff', borderRadius: 7, height: 32, borderColor: '#ccc', borderWidth: 1.5 }}>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button className='btn-icon'><i className="fa fa-trash-o delete" style={{ fontSize: 'large' }} /></Button></li>
                                                <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }} className='btn-icon'><i className="fa fa-pencil edit" style={{ fontSize: 'large' }} /></Button></li>
                                            </ul>

                                        )
                                    } */}
                                </div>
                            </>
                        },
                    ]}
                    className='collapse-level-2 m-1'
                />
            </>
        )
    }


    return (
        <>
            <Collapse
                activeKey={props.activeKey}
                style={{ backgroundColor: '#fff' }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onChange={onChange}
                onClick={() => { props?.setItem(props.data); props?.setModalData({ level: (levelIndex + 1), ParentID: props?.data?.id }); }}
                expandIconPosition={'start'}
                items={[
                    {
                        key: props.data.levelid,
                        label: <>
                            <div
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                className='d-flex'
                            >
                                <Tooltip
                                    title={props?.data.displayname}
                                    onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }}
                                >
                                    {props.data.levelid} {props.data.displayname}
                                </Tooltip>
                                {/* {console.log(props?.data)} */}
                            </div>
                        </>, children: <>
                            <List
                                header={false}
                                size={`small`}
                                footer={
                                    <div >
                                        {props.data.levelid === props.bcm2Index && <NewItem newLevelId={getNewLevelId(props?.data?.levelid, levelIndex + 1, props.data)} parentid={props?.data?.id} create_Duplicate={props.create_Duplicate} setBcm2Index={props.setBcm2Index} />}

                                        <Tooltip title={`Level ` + (levelIndex + 1)} placement={`rightBottom`}>
                                            <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => {
                                                props.setBcm2Index(props.data.levelid)
                                                props.setAcitveKey(createHierarchyArray(props.data.levelid))
                                                //navigate(props.basePath+'/add', { state: {Level:(levelIndex+1),ParentID: props?.data?.id, ParentName: props?.data?.displayname, underLevel:props?.data?.displayname, levelID: props?.data?.levelid,newLevelId : getNewLevelId(props?.data?.levelid,levelIndex+1,props.data) ,from:props.basePath,defaultActiveKey:2 } })
                                            }}></Button>
                                        </Tooltip>
                                    </div>
                                }
                                bordered
                                dataSource={[...props.data.child]}
                                renderItem={(item, index) => (
                                    <BPMLevel2 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} key={index} data={item} underLevel={props?.data.displayname} underLevelID={props?.data.levelid} levelIndex={levelIndex + 1} activeKey={props.activeKey} setAcitveKey={props.setAcitveKey} bcm2Index={props.bcm2Index} setBcm2Index={props.setBcm2Index} />
                                )}
                            />
                        </>,
                        extra: <>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdown(!dropdown);
                                }}>
                                    <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                                </button>
                                {
                                    dropdown && (

                                        <ul ref={dropdownRef} style={{ padding: "0px 3px", display: 'flex', flexDirection: 'row-reverse', gap: 5, position: 'fixed', marginLeft: 70, backgroundColor: '#ffffff', borderRadius: 7, height: 32, borderColor: '#ccc', borderWidth: 1.5 }}>
                                            <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button onClick={handleDelete} className='btn-icon'><i className="fa fa-trash-o delete" style={{ fontSize: 'large' }} /></Button></li>
                                            {/* <li><Button onClick={handleEdit} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}

                                            {/* <li><Button onClick={() => { navigate('/business-capability-master', { state: { type: 'edit',Level:2, ParentID: props?.data?.id , ParentName:props?.data?.DisplayName, underLevel: props.underLevel} })}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}

                                            <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button onClick={() => { navigate(props.basePath + '/' + props?.data?.id, { state: { from: props.basePath, defaultActiveKey: 2 } }) }} className='btn-icon'><i className="fa fa-pencil edit" style={{ fontSize: 'large' }} /></Button></li>



                                        </ul>

                                    )
                                }
                            </div>
                        </>
                    },
                ]}
                className='collapse-level-2 m-1'
            />
        </>
    )
}

BPMLevel2.defaultProps = {
    levelIndex: 2,
}

export default BPMLevel2

export const getNewLevelId = (levelID, level, items) => {
    let currentLevelChild = items?.child.at(-1);
    if (currentLevelChild) {
        return currentLevelChild?.levelid?.split(".")?.map((e, i) => {
            if (i === level - 1) {
                return Number(e) + 1;
            }
            return e;
        }).join(".");

    } else {
        return items?.levelid + ".1";
    }
}