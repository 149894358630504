import React, { useEffect, useState, useRef  } from "react";
import { Link, useLocation  } from "react-router-dom";
import { Input,Tooltip, Spin } from "antd";
import { SearchOutlined, CopyOutlined, ExportOutlined,CheckCircleOutlined,CloseCircleOutlined } from "@ant-design/icons";
import GlobalSearchServices from "../../../services/services/GlobalSearchServices";
import './AppHeader.scss';

function GlobalSearch() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamSearch = queryParams.get('search');

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [linkClicked, setLinkClicked] = useState(false);
    const searchContainerRef = useRef(null);
    const [copiedUrl, setCopiedUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const searchTerm = queryParamSearch ? decodeURIComponent(queryParamSearch) : '';
        setSearchTerm(searchTerm);
    }, [queryParamSearch]);

    const handleSearch = async (event) => {
        const searchText = event.target.value;
        setSearchTerm(searchText);
        setLoading(true);

        try {
            if(searchText != '')
            {
                const response = await GlobalSearchServices.getList(searchText);
                setResults(response.data);
            }
            else setResults("");
        } catch (error) {
            console.error("Error searching:", error);
        } finally {
            setLoading(false);
        }
    };

    const showResults = searchTerm !== '' && results?.data !== undefined && !linkClicked; 
    const handleLinkClick = () => {
        setResults([]);
        setLinkClicked(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setLinkClicked(true);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCopyUrl = (item) => {
        const url = `${window.location.origin}/${item.routeurl}/${item.id}`;
        navigator.clipboard.writeText(url)
            .then(() => {
                setCopiedUrl(item.id);
                setTimeout(() => {
                    setCopiedUrl(null);
                }, 2000);
            })
            .catch((error) => {
                console.error('Failed to copy URL to clipboard:', error);
            });
    };

    const handleOpenInNewTab = (item) => {
        const url = `${window.location.origin}/${item.routeurl}/${item.id}`;
        window.open(url, '_blank');
    };
    const handleOpenDiagram = (diagram_id) => {
        const url = `${window.location.origin}/diagram-editor/${diagram_id}`;
        window.open(url, '_blank');
    };

    const handleFocus = async () => {
        if (linkClicked) {
            setLinkClicked(false);
        }
        
        try {
            if(searchTerm != '') {
                const response = await GlobalSearchServices.getList(searchTerm);
                setResults(response.data);
            }
            else setResults("");
        } catch (error) {
            console.error('Error searching:', error);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setResults([]);
        setLoading(false);
    };
    return (
        <div ref={searchContainerRef} className="global-search-container headerSearch">
            <Input
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
                prefix={<SearchOutlined className="searchIcon" />}
                onFocus={handleFocus}
                className="searchBox"
                suffix={
                    searchTerm && (
                        <CloseCircleOutlined className="clearIcon" onClick={handleClearSearch} />
                    )
                }
            />
            {loading && (
                <div className="search-results centerTxt">
                    <Spin className="loading-spinner" />
                </div>
            )}
            {showResults && !loading && (
                <div className="search-results">
                    {Object.keys(results?.data)?.map(category => (
                        <div key={category}>
                            {results?.data[category]?.length > 0 && (
                                <>
                                    <h3 style={{ backgroundColor:`${results?.data[category][0]?.backgroundcolor}` }}>{category}</h3>
                                    <ul>
                                        {results?.data[category]?.map(item => (
                                            <li>
                                                <Link to={`/${item?.routeurl}/${item?.id}?search=${encodeURIComponent(item?.displayname)}`} onClick={handleLinkClick}>
                                                    {item?.refId && <span className="levelClass" style={{ backgroundColor:`${results?.data[category][0]?.backgroundcolor}` }}>{item?.refId}</span>}
                                                    {item?.displayname}
                                                </Link>
                                                <div>                                                
                                                    <Tooltip title={copiedUrl === item.id ? "Copied!" : "Copy Portfolio URL"}>
                                                        {copiedUrl === item.id ? (
                                                            <CheckCircleOutlined style={{ paddingLeft: '0.5rem', color: 'green' }} />
                                                        ) : (
                                                            <CopyOutlined style={{ paddingLeft: '0.5rem' }} onClick={() => handleCopyUrl(item)} />
                                                        )}
                                                    </Tooltip>
                                                    {item?.diagram_id ? (
                                                        <Tooltip title="Open diagram in new tab">
                                                            <ExportOutlined style={{ marginLeft: '0.5rem' }} onClick={() => handleOpenDiagram(item?.diagram_id)} />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Open in new tab">
                                                            <ExportOutlined style={{ marginLeft: '0.5rem' }} onClick={() => handleOpenInNewTab(item)} />
                                                        </Tooltip>
                                                    )}   
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                    ))}
                    {Object.keys(results?.data)?.every(category => results?.data[category]?.length === 0) && (
                        <div className="no-results-message">
                            No record found!
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default GlobalSearch;
