import { Button, Flex, Tooltip } from 'antd';
import React, { useState } from 'react';
import SummaryReportList from './business-capability-summary-report/SummaryReportList';
import BusinessCapabilityList from './business-capability-summary-report/BusinessCapabilityList';

const BusinessCapabiltySummaryReports = ({...props}) => {
    const moduleName = `business_capability`;
    const [tab,setTab] = useState(1);
    const [generateModal,setGenerateModal] = useState(false);

    const Buttons = () => {
        return <React.Fragment>
            <Flex gap={8} align='center'>
                {
                    tab===1 && 
                    <Tooltip title="List Report">
                        <Button 
                            // icon={<UnorderedListOutlined />} 
                            type='primary'
                            onClick={()=>{setTab(2);}}
                            ghost
                        >
                            List Report
                        </Button>
                    </Tooltip>
                }
                <Tooltip 
                    title={(()=>{
                        if(tab===2){
                            return `Back`;
                        }else if(tab===1){
                            return `Generate Report`;
                        }
                        return ``;
                    })()}
                >
                    <Button 
                        // icon={<PlusOutlined />} 
                        type='primary'
                        onClick={()=>{
                            if(tab===1){
                                setGenerateModal(!generateModal); 
                            }   
                            setTab(1);
                        }}
                        ghost
                    >
                        {
                            (()=>{
                                if(tab===2){
                                    return `Back`;
                                }else if(tab===1){
                                    return `Generate Report`;
                                }
                                return ``;
                            })()
                        }
                    </Button>
                </Tooltip>
            </Flex>
        </React.Fragment>
    }

    const tabProps = {
        moduleName,
        Buttons,
        tab,
    }
    
    return (
        <React.Fragment>
            { 
                tab === 1 && 
                <BusinessCapabilityList 
                    setGenerateModal={setGenerateModal}
                    generateModal={generateModal}
                    {...tabProps}
                />
            }
            {   tab===2 && 
                <SummaryReportList
                    {...tabProps}
                />
            }
        </React.Fragment>
    )
}

export default BusinessCapabiltySummaryReports
