import { message } from "antd";
import $ from 'jquery';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';


export const mapLabelWithVariables = (labelName) => {
    if (labelName === "Strategic Objective") {
      return 'a';
    } else if (labelName === "Initiative") {
      return 'b';
    } else if (labelName === "Business Capability") {
      return 'c';
    } else if (labelName === "Application") {
      return 'd';
    } else if (labelName === "Business Process") {
      return 'e';
    } else if (labelName === "Component") {
      return 'g';
    } else if (labelName === "Product") {
      return 'h';
    } else if (labelName === "Technology") {
      return 'i';
    } else if (labelName === "Platform") {
      return 'k';
    } else if (labelName === "API") {
      return 'm';
    } else if (labelName === "Key Result") {
      return 'n';
    } else if (labelName === "Product Cluster") {
      return 'p';
    } else if (labelName === "Product Area") {
      return 'q';
    } else if (labelName === "Product Unit") {
      return 't';
    } else if (labelName === "Business Domain") {
      return 'o';
    } else if (labelName === "Database") {
      return 'r';
    } else if (labelName === "Column") {
      return 's';
    } else if (labelName === "Driver") {
      return 'u';
    } else if (labelName === "Application Environment") {
      return 'v';
    } else if (labelName === "Server") {
      return 'w';
    } else if (labelName === "Database Instance") {
      return 'x';
    } else if (labelName === "Integration") {
      return 'y';
    } else {
      return '';
    }
  
  }


  const mapRelationWithVariables = (relationName) => {
    if (relationName == "SUPPORTED_BY") {
      return 'a,b,c,d,e';
    } else if (relationName == "BUILT_ON") {
      return 'e,f,d,i,g,l';
    } else if (relationName == "CONTAINS") {
      return 'd,g';
    } else if (relationName == "MANAGED_BY") {
      return 'd,h';
    } else if (relationName == "USES") {
      return 'g,j,v,x';
    } else if (relationName == "CONSUMES") {
      return 'd,k';
    } else if (relationName == "CONSUME") {
      return 'g,m';
    } else if (relationName == "MEASURED_BY") {
      return 'a,n';
    } else if (relationName == "REALIZED_BY") {
      return 'n,b';
    } else if (relationName == "OWNED_BY") {
      return 'd,h';
    } else if (relationName == "BELONGS_TO") {
      return 'h,p,q,t';
    } else if (relationName == "LINKED_WITH") {
      return 'c,o';
    } else if (relationName == "STORED_IN") {
      return 'r,s';
    } else if (relationName == "RUNNING_IN") {
      return 'd,v';
    } else if (relationName == "DEPLOYED_ON") {
      return 'v,w';
    } else if (relationName == "RECEIVE_DATA_FROM") {
      return 'v,y';
    } else if (relationName == "PROVIDING_DATA_TO") {
      return 'y,a1';
    } else {
      return '';
    }
  }

  const makePropFilterQuery = (propfilter) => {
    var query = "";
    try {
      $('.ant-tabs-tabpane').each((key, val) => {

        var propsDiv = $(val).attr("id");
        if ($('#' + propsDiv + ' .prop-filter-parent input[type="checkbox"]:checked').length) {
          $('#' + propsDiv + ' .prop-filter-parent input[type="checkbox"]:checked').each((pKey, pVal) => {
            var value = $(pVal).attr('value');
            var name = $(pVal).attr('name');
            var pArr = $('input[name="'+name+'"]:checked');
            if (pKey === 0) {
              query += "(";
            }
  
            if (pKey === pArr.length - 1) {
              query += `${name}='${value}') AND `;
            } else {
              query += `${name}='${value}' OR `;
            }
          })
        }
  
      })
      query = query.substring(0, query.length - 4);
  
    } catch (err) {
      message.error(err.message);
    }
  
    return query;
  }


export const getQueryForCityMap = (referenceId, labelName, onlyNodes = false, activeLabels = [], activeRelations = [], propfilter = [],) => {
    try {
      let queryCityMap = '';
      let pathString = '';
      let variableArrAll = [];
      if (labelName === "Driver") {
        queryCityMap += "MATCH path = (u:`Driver`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path19 = (u)-[:INFLUENCES]->(a:`Strategic Objective`)";
        // queryCityMap += "OPTIONAL match path0 = (a)-[:SUPPORTED_BY]->(b:`Initiative`)";
        // queryCityMap += "OPTIONAL match path1 = (b)-[:SUPPORTED_BY]->(c:`Business Capability`)";
        // queryCityMap += "OPTIONAL match path2 = (c)-[:SUPPORTED_BY]->(d:`Application`)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path19),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path19),[])";
        variableArrAll = ['a', 'u'];
      } else if (labelName == "Strategic Objective") {
        queryCityMap += "MATCH path = (a:`Strategic Objective`{referenceId: '" + referenceId + "'})";
        // queryCityMap += "OPTIONAL match path0 = (a)-[:SUPPORTED_BY]->(b:`Initiative`)";
        // queryCityMap += "OPTIONAL match path1 = (b)-[:SUPPORTED_BY]->(c:`Business Capability`)";
        // queryCityMap += "OPTIONAL match path2 = (c)-[:SUPPORTED_BY]->(d:`Application`)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path13),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path13),[])";
        variableArrAll = ['a', 'n'];
      } else if (labelName == "Initiative") {
        queryCityMap += "MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(b) WHERE b.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['b']
          if(activeLabels.includes('Business Capability')){
            queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(c:`Business Capability`) WHERE b.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('c');
          }
          if(activeLabels.includes('Application')){
            queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(d:`Application`) WHERE b.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('d');
          }
          if(activeLabels.includes('Business Process')){
            queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE b.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('e');
          }
          if(activeLabels.includes('Business Domain')){
            queryCityMap +=" UNION MATCH p=(b)-[:LINKED_WITH]->(o:`Business Domain`) WHERE b.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('o');
          }
          if(activeLabels.includes('Integration')){
            queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(y:`Integration`) WHERE b.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('y');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(c:`Business Capability`) WHERE b.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(d:`Application`) WHERE b.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE b.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b)-[:LINKED_WITH]->(o:`Business Domain`) WHERE b.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b)-[:SUPPORTED_BY]->(y:`Integration`) WHERE b.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['b', 'c', 'd', 'e', 'o', 'y'];
        }
      } else if (labelName == "Business Capability") {
        queryCityMap += "MATCH p=(c:`Business Capability`)-[:SUPPORTED_BY]->(c) WHERE c.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['c'];
          if(activeLabels.includes('Application')){
            queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(d:`Application`) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('d');
          }
          if(activeLabels.includes('Initiative')){
            queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(c) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('b');
          }
          if(activeLabels.includes('Business Process')){
            queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('e');
          }
          if(activeLabels.includes('Product')){
            queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(f:`Product`) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('f');
          }
          if(activeLabels.includes('Business Domain')){
            queryCityMap +=" UNION MATCH p=(c)-[:LINKED_WITH]->(o:`Business Domain`) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('o');
          }
          if(activeLabels.includes('Key Result')){
            queryCityMap +=" UNION MATCH p=(c)-[:MEASURED_BY]->(n:`Key Result`) WHERE c.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('n');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(d:`Application`) WHERE c.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(c) WHERE c.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE c.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c)-[:SUPPORTED_BY]->(f:`Product`) WHERE c.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c)-[:LINKED_WITH]->(o:`Business Domain`) WHERE c.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c)-[:MEASURED_BY]->(n:`Key Result`) WHERE c.referenceId='" + referenceId + "' RETURN p";
        }

        variableArrAll = ['b', 'c', 'd', 'e', 'o', 'f', 'n'];
      } else if (labelName == "Business Domain") {
        queryCityMap += "MATCH p=(o:`Business Domain`)-[:SUPPORTED_BY]->(o) WHERE o.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['o'];
          if(activeLabels.includes('Business Capability')){
            queryCityMap +=" UNION MATCH p=(o)-[:LINKED_WITH]->(c:`Business Capability`) WHERE o.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('c');
          }
          if(activeLabels.includes('Initiative')){
            queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(o) WHERE o.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('b');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(o)-[:LINKED_WITH]->(c:`Business Capability`) WHERE o.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(o) WHERE o.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['c', 'o', 'b'];
        }

      } else if (labelName == "Application") {
        queryCityMap += "MATCH p=(d:`Application`)-[:SUPPORTED_BY]->(d) WHERE d.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['d']
          if(activeLabels.includes('Component')){
            queryCityMap +=" UNION MATCH p=(d)-[:CONTAINS]->(g:`Component`) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('g')
          }
          if(activeLabels.includes('Business Capability')){
            queryCityMap +=" UNION MATCH p=(c:`Business Capability`)-[:SUPPORTED_BY]->(d) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('c')
          }
          if(activeLabels.includes('Business Process')){
            queryCityMap +=" UNION MATCH p=(d)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('e');
          }
          if(activeLabels.includes('Product')){
            queryCityMap +=" UNION MATCH p=(d)-[:OWNED_BY]->(h:`Product`) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('h');
          }
          if(activeLabels.includes('Technology')){
            queryCityMap +=" UNION MATCH p=(d)-[:BUILT_ON]->(i:`Technology`) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('i');
          }
          if(activeLabels.includes('Application Environment')){
            queryCityMap +=" UNION MATCH p=(d)-[:RUNNING_IN]->(v:`Application Environment`) WHERE d.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('v');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(d)-[:CONTAINS]->(g:`Component`) WHERE d.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c:`Business Capability`)-[:SUPPORTED_BY]->(d) WHERE d.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d)-[:SUPPORTED_BY]->(e:`Business Process`) WHERE d.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d)-[:OWNED_BY]->(h:`Product`) WHERE d.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d)-[:BUILT_ON]->(i:`Technology`) WHERE d.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d)-[:RUNNING_IN]->(v:`Application Environment`) WHERE d.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['c', 'd', 'g', 'h', 'i', 'v', 'e'];
        }
      } else if (labelName == "Application Environment") {
        queryCityMap += "MATCH p=(v:`Application Environment`)-[:SUPPORTED_BY]->(v) WHERE v.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['v']
          if(activeLabels.includes('Technology')){
            queryCityMap +=" UNION MATCH p=(v)-[:DEPENDS_ON]->(i:`Technology`) WHERE v.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('i');
          }
          if(activeLabels.includes('Application')){
            queryCityMap +=" UNION MATCH p=(d:`Application`)-[:HAS]->(v) WHERE v.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('d');
          }
          if(activeLabels.includes('Component')){
            queryCityMap +=" UNION MATCH p=(v)-[:DEPENDS_ON]->(g:`Component`) WHERE v.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('g');
          }
          if(activeLabels.includes('Integration')){
            queryCityMap +=" UNION MATCH p=(v)-[:PRODUCE]->(y:`Integration`) WHERE v.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('y');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(v)-[:DEPENDS_ON]->(i:`Technology`) WHERE v.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d:`Application`)-[:HAS]->(v) WHERE v.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(v)-[:DEPENDS_ON]->(g:`Component`) WHERE v.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(v)-[:PRODUCE]->(y:`Integration`) WHERE v.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['g','i','y','v','d'];
        }
      } else if (labelName == "Server") {
        queryCityMap += "MATCH path = (w:`Server`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path21 = (v:`Application Environment`)-[:DEPLOYED_ON]->(w)";
        // queryCityMap += "OPTIONAL match path20 = (d:`Application`)-[:RUNNING_IN]->(v)";
        // queryCityMap += "OPTIONAL match path0 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        // queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        // queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // //queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[])+COALESCE (NODES(path21),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])+COALESCE (RELATIONSHIPS(path21),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'w', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path21),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path21),[])";
        variableArrAll = ['v', 'w'];
      } else if (labelName == "Database Instance") {
        queryCityMap += "MATCH path = (x:`Database Instance`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path22 = (v:`Application Environment`)-[:USES]->(x)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // queryCityMap += "OPTIONAL match path20 = (d:`Application`)-[:RUNNING_IN]->(v)";
        // queryCityMap += "OPTIONAL match path0 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        // queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        // queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // //queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
  
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[])+COALESCE (NODES(path22),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])+COALESCE (RELATIONSHIPS(path22),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'x', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path22),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[])+COALESCE (NODES(path22),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path22),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])+COALESCE (RELATIONSHIPS(path22),[])";
        variableArrAll = ['v', 'x'];
      } else if (labelName == "Integration") {
        queryCityMap += "MATCH path = (y:`Integration`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path23 = (v:`Application Environment`)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // queryCityMap += "OPTIONAL match path20 = (d:`Application`)-[:RUNNING_IN]->(v)";
        // queryCityMap += "OPTIONAL match path0 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(y)";
        // queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // //queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path23),[])+COALESCE (NODES(path1),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path23),[])+COALESCE (RELATIONSHIPS(path1),[])";
        variableArrAll = ['b', 'v', 'y'];
      } else if (labelName == "Business Process") {
        queryCityMap += "MATCH p=(e:`Business Process`)-[:SUPPORTED_BY]->(e) WHERE e.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['e']
          if(activeLabels.includes('Application')){
            queryCityMap +=" UNION MATCH p=(e)-[:SUPPORTED_BY]->(d:`Application`) WHERE e.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('d');
          }
          if(activeLabels.includes('Business Capability')){
            queryCityMap +=" UNION MATCH p=(c:`Business Capability`)-[:SUPPORTED_BY]->(e) WHERE e.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('c');
          }
          if(activeLabels.includes('Initiative')){
            queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(e) WHERE e.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('b');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(e)-[:SUPPORTED_BY]->(d:`Application`) WHERE e.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(c:`Business Capability`)-[:SUPPORTED_BY]->(e) WHERE e.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(b:`Initiative`)-[:SUPPORTED_BY]->(e) WHERE e.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['b', 'c', 'd', 'e'];
        }

        // queryCityMap += "OPTIONAL match path24 = (e)-[:CHILD_OF*]->(z:`Business Process`)";
        // queryCityMap += "OPTIONAL match path0 = (c:`Business Capability`)-[:SUPPORTED_BY]->(e)";
        // queryCityMap += "OPTIONAL match path3 = (b:`Initiative`)-[:SUPPORTED_BY]->(e)";
        // // queryCityMap += "OPTIONAL match path1 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // // //queryCityMap += "OPTIONAL match path2 = (c)-[:SUPPORTED_BY]->(d:`Application`)";
        // queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(d:`Application`)";
        // queryCityMap += "OPTIONAL match path26 = (z)-[:BUILT_ON]->(f:`Application`)";
        // // // queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        // // // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // // // queryCityMap += "OPTIONAL match path7 = (d)-[:BUILT_ON]->(i:`Technology`)";
        // // // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // // // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // // // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // // // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // // // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // // // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // // // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // // // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // // // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // // // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // // // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // // // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // // // queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        // // // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // // // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // // // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // // // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[])+COALESCE (NODES(path26),[])+COALESCE (NODES(path24),[]),";
        // // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])+COALESCE (RELATIONSHIPS(path24),[])+COALESCE (RELATIONSHIPS(path26),[])";
        // // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y', 'z'];
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[])+COALESCE (NODES(path26),[])+COALESCE (NODES(path24),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])+COALESCE (RELATIONSHIPS(path24),[])+COALESCE (RELATIONSHIPS(path26),[])";
        // variableArrAll = ['b', 'c', 'd', 'e', 'f', 'z'];
      } else if (labelName == "Component") {
        queryCityMap += "MATCH path = (g:`Component`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (d:`Application`)-[:CONTAINS]->(g)";
        // queryCityMap += "OPTIONAL match path7 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        // queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        // queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d:`Application`)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        // queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        // queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])";
        variableArrAll = ['d', 'g'];
      } else if (labelName == "Product") {
        queryCityMap += "MATCH p=(h:`Product`)-[:SUPPORTED_BY]->(h) WHERE h.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['h']
          if(activeLabels.includes('Product Cluster')){
            queryCityMap +=" UNION MATCH p=(h)-[:BELONGS_TO]->(g:`Product Cluster`) WHERE h.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('g');
          }
          if(activeLabels.includes('Application')){
            queryCityMap +=" UNION MATCH p=(d:`Application`)-[:OWNED_BY]->(h) WHERE h.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('d');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(h)-[:BELONGS_TO]->(g:`Product Cluster`) WHERE h.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(d:`Application`)-[:OWNED_BY]->(h) WHERE h.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['h', 'g', 'd'];
        }
        
      } else if (labelName == "Product Cluster") {
        queryCityMap += "MATCH p=(l:`Product Cluster`)-[:SUPPORTED_BY]->(l) WHERE l.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['l']
          if(activeLabels.includes('Product Area')){
            queryCityMap +=" UNION MATCH p=(l)-[:BELONGS_TO]->(q:`Product Area`) WHERE l.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('q');
          }
          if(activeLabels.includes('Product')){
            queryCityMap +=" UNION MATCH p=(h:`Product`)-[:BELONGS_TO]->(l) WHERE l.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('h');
          }
        }else{
          queryCityMap +=" UNION MATCH p=(l)-[:BELONGS_TO]->(q:`Product Area`) WHERE l.referenceId='" + referenceId + "' RETURN p";
          queryCityMap +=" UNION MATCH p=(h:`Product`)-[:BELONGS_TO]->(l) WHERE l.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['h', 'l', 'q'];
        }
      } else if (labelName == "Product Area") {
        queryCityMap += "MATCH p=(q:`Product Area`)-[:SUPPORTED_BY]->(q) WHERE q.referenceId='" + referenceId + "' RETURN p";
        if(activeLabels.length){
          variableArrAll = ['q']
          if(activeLabels.includes('Product Unit')){
            queryCityMap += "UNION match p=(q)-[:BELONGS_TO]->(t:`Product Unit`) WHERE q.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('t');
          }
          if(activeLabels.includes('Product Cluster')){
            queryCityMap += "UNION match p=(l:`Product Cluster`)-[:BELONGS_TO]->(q) WHERE q.referenceId='" + referenceId + "' RETURN p";
            variableArrAll.push('l');
          }
        }else{
          queryCityMap += "UNION match p=(q)-[:BELONGS_TO]->(t:`Product Unit`) WHERE q.referenceId='" + referenceId + "' RETURN p";
          queryCityMap += "UNION match p=(l:`Product Cluster`)-[:BELONGS_TO]->(q) WHERE q.referenceId='" + referenceId + "' RETURN p";
          variableArrAll = ['l', 'q', 't'];
        }

      } else if (labelName == "Product Unit") {
        queryCityMap += "MATCH path = (t:`Product Unit`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path18 = (q:`Product Area`)-[:BELONGS_TO]->(t)";
        // queryCityMap += "OPTIONAL match path0 = (p:`Product Cluster`)-[:BELONGS_TO]->(q)";
        // queryCityMap += "OPTIONAL match path17 = (h:`Product`)-[:BELONGS_TO]->(p)";
        // queryCityMap += "OPTIONAL match path16 = (d:`Application`)-[:OWNED_BY]->(h)";
        // queryCityMap += "OPTIONAL match path7 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        // queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        // queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        // queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        // queryCityMap += "OPTIONAL match path5 = (d:`Application`)-[:BUILT_ON]->(i:`Technology`)";
        // queryCityMap += "OPTIONAL match path6 = (d)-[:CONTAINS]->(g:`Component`)";
        // queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        // queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        // queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        // queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        // queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        // queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        // queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        // queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        // queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        // queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        // //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        // //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        // //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        // //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        // pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        // pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        // variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        variableArrAll = ['q', 't'];
      } else if (labelName == "Technology") {
        queryCityMap += "MATCH path = (i:`Technology`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (d:`Application`)-[:BUILT_ON]->(i)";
        queryCityMap += "OPTIONAL match path7 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d:`Application`)";
        queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        queryCityMap += "OPTIONAL match path5 = (d)-[:CONTAINS]->(g:`Component`)";
        queryCityMap += "OPTIONAL match path6 = (d)-[:OWNED_BY]->(h:`Product`)";
        //queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v'];
      } else if (labelName == "Platform") {
        queryCityMap += "MATCH path = (k:`Platform`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (d:`Application`)-[:CONSUMES]->(k)";
        queryCityMap += "OPTIONAL match path7 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        queryCityMap += "OPTIONAL match path5 = (d:`Application`)-[:BUILT_ON]->(i:`Technology`)";
        queryCityMap += "OPTIONAL match path6 = (d)-[:CONTAINS]->(g:`Component`)";
        queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        queryCityMap += "OPTIONAL match path9 = (d)-[:OWNED_BY]->(h:`Product`)";
        //queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        queryCityMap += "OPTIONAL match path11 = (g)-[:CONSUME]->(m:`API`)";
        queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v', 'y'];
      } else if (labelName == "API") {
        queryCityMap += "MATCH path = (m:`API`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (g:`Component`)-[:CONSUME]->(m)";
        queryCityMap += "OPTIONAL match path6 = (d:`Application`)-[:CONTAINS]->(g)";
        queryCityMap += "OPTIONAL match path7 = (c:`Business Capability`)-[:SUPPORTED_BY]->(d)";
        queryCityMap += "OPTIONAL match path1 = (b:`Initiative`)-[:SUPPORTED_BY]->(c)";
        queryCityMap += "OPTIONAL match path2 = (a:`Strategic Objective`)-[:SUPPORTED_BY]->(b)";
        queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        queryCityMap += "OPTIONAL match path5 = (d)-[:BUILT_ON]->(i:`Technology`)";
        queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        queryCityMap += "OPTIONAL match path11 = (d)-[:OWNED_BY]->(h:`Product`)";
        queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        queryCityMap += "OPTIONAL match path13 = (a)-[:MEASURED_BY]->(n:`Key Result`)";
        queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b)";
        queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v'];
      } else if (labelName == "Key Result") {
        queryCityMap += "MATCH path = (n:`Key Result`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (a:`Strategic Objective`)-[:MEASURED_BY]->(n)";
        queryCityMap += "OPTIONAL match path14 = (n)-[:REALIZED_BY]->(b:`Initiative`)";
        queryCityMap += "OPTIONAL match path2 = (a)-[:SUPPORTED_BY]->(b)";
        queryCityMap += "OPTIONAL match path1 = (b)-[:SUPPORTED_BY]->(c:`Business Capability`)";
        queryCityMap += "OPTIONAL match path7 = (c)-[:SUPPORTED_BY]->(d:`Application`)";
        queryCityMap += "OPTIONAL match path6 = (d)-[:CONTAINS]->(g:`Component`)";
        queryCityMap += "OPTIONAL match path13 = (g)-[:CONSUME]->(m:`API`)";
        queryCityMap += "OPTIONAL match path3 = (c)-[:SUPPORTED_BY]->(e:`Business Process`)";
        queryCityMap += "OPTIONAL match path4 = (e)-[:BUILT_ON]->(f:`Application`)";
        queryCityMap += "OPTIONAL match path5 = (d)-[:BUILT_ON]->(i:`Technology`)";
        queryCityMap += "OPTIONAL match path8 = (g)-[:USES]->(j:`Technology`)";
        queryCityMap += "OPTIONAL match path9 = (d)-[:CONSUMES]->(k:`Platform`)";
        queryCityMap += "OPTIONAL match path10 = (g)-[:BUILT_ON]->(l:`Platform`)";
        queryCityMap += "OPTIONAL match path11 = (d)-[:OWNED_BY]->(h:`Product`)";
        queryCityMap += "OPTIONAL match path12 = (m)-[:PRODUCE]->(g)";
        queryCityMap += "OPTIONAL match path15 = (c)-[:LINKED_WITH]->(o:`Business Domain`)";
        queryCityMap += "OPTIONAL match path16 = (h)-[:BELONGS_TO]->(p:`Product Cluster`)";
        queryCityMap += "OPTIONAL match path17 = (p)-[:BELONGS_TO]->(q:`Product Area`)";
        queryCityMap += "OPTIONAL match path18 = (q)-[:BELONGS_TO]->(t:`Product Unit`)";
        queryCityMap += "OPTIONAL match path19 = (u:`Driver`)-[:INFLUENCES]->(a)";
        queryCityMap += "OPTIONAL match path20 = (d)-[:RUNNING_IN]->(v:`Application Environment`)";
        //queryCityMap += "OPTIONAL match path21 = (v)-[:DEPLOYED_ON]->(w:`Server`)";
        //queryCityMap += "OPTIONAL match path22 = (v)-[:USES]->(x:`Database Instance`)";
        //queryCityMap += "OPTIONAL match path23 = (v)-[:RECEIVE_DATA_FROM]->(y:`Integration`)";
        //queryCityMap += "OPTIONAL match path25 = (y)-[:PROVIDING_DATA_TO]->(a1:`Application Environment`)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[])+COALESCE (NODES(path1),[])+COALESCE (NODES(path2),[])+COALESCE (NODES(path3),[])+COALESCE (NODES(path4),[])+COALESCE (NODES(path5),[])+COALESCE (NODES(path6),[])+COALESCE (NODES(path7),[])+COALESCE (NODES(path8),[])+COALESCE (NODES(path9),[])+COALESCE (NODES(path10),[])+COALESCE (NODES(path11),[])+COALESCE (NODES(path12),[])+COALESCE (NODES(path13),[])+COALESCE (NODES(path14),[])+COALESCE (NODES(path15),[])+COALESCE (NODES(path16),[])+COALESCE (NODES(path17),[])+COALESCE (NODES(path18),[])+COALESCE (NODES(path19),[])+COALESCE (NODES(path20),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])+COALESCE (RELATIONSHIPS(path1),[])+COALESCE (RELATIONSHIPS(path2),[])+COALESCE (RELATIONSHIPS(path3),[])+COALESCE (RELATIONSHIPS(path4),[])+COALESCE (RELATIONSHIPS(path5),[])+COALESCE (RELATIONSHIPS(path6),[])+COALESCE (RELATIONSHIPS(path7),[])+COALESCE (RELATIONSHIPS(path8),[])+COALESCE (RELATIONSHIPS(path9),[])+COALESCE (RELATIONSHIPS(path10),[])+COALESCE (RELATIONSHIPS(path11),[])+COALESCE (RELATIONSHIPS(path12),[])+COALESCE (RELATIONSHIPS(path13),[])+COALESCE (RELATIONSHIPS(path14),[])+COALESCE (RELATIONSHIPS(path15),[])+COALESCE (RELATIONSHIPS(path16),[])+COALESCE (RELATIONSHIPS(path17),[])+COALESCE (RELATIONSHIPS(path18),[])+COALESCE (RELATIONSHIPS(path19),[])+COALESCE (RELATIONSHIPS(path20),[])";
        variableArrAll = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 't', 'u', 'v'];
      } else if (labelName == "Database") {
        queryCityMap += "MATCH path = (r:`Database`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (s:`Column`)-[:STORED_IN]->(r)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])";
        variableArrAll = ['r', 's'];
      } else if (labelName == "Column") {
        queryCityMap += "MATCH path = (s:`Column`{referenceId: '" + referenceId + "'})";
        queryCityMap += "OPTIONAL match path0 = (s)-[:STORED_IN]->(r:`Database`)";
        pathString += "RETURN NODES(path)+COALESCE (NODES(path0),[]),";
        pathString += "RELATIONSHIPS(path)+COALESCE (RELATIONSHIPS(path0),[])";
        variableArrAll = ['r', 's'];
      }
      // if (activeLabels.length > 0) {
      //   let variableArr = [];
      //   activeLabels.map(function (e) {
      //     let variables = mapLabelWithVariables(e);
      //     let varArr = variables.split(',');
      //     variableArr = variableArr.concat(varArr);
      //   });
      //   let uniqueVariableArr = [...new Set(variableArr)];
      //   let findMainVarIndex = uniqueVariableArr.findIndex((p) => p === mapLabelWithVariables(labelName));
      //   if (findMainVarIndex > -1) {
      //     uniqueVariableArr.splice(findMainVarIndex, 1);
      //   }
      //   console.log(uniqueVariableArr,"=====uniqueVariableArr")
      //   if (uniqueVariableArr.length == 0) {
      //     // create cypher query for Properties
      //     var propFilterQuery = makePropFilterQuery(propfilter);
      //     if (propFilterQuery != '') {
      //       queryCityMap += " WITH " + mapLabelWithVariables(labelName) +
      //         " WHERE (" + propFilterQuery + ")" +
      //         " RETURN " + mapLabelWithVariables(labelName);
  
      //     } else {
      //       queryCityMap += " RETURN " + mapLabelWithVariables(labelName);
  
  
      //     }
  
      //   } else {
      //     var propFilterQuery = makePropFilterQuery(propfilter);
      //     console.log(propFilterQuery,"=====propFilterQuery")
      //     if (propFilterQuery != '') {
      //       // create cypher query for Properties
      //       queryCityMap += " WITH " + mapLabelWithVariables(labelName) + "," + uniqueVariableArr.join(',') +
      //         " WHERE (" + propFilterQuery + ")" +
      //         " RETURN " + mapLabelWithVariables(labelName) + "," + uniqueVariableArr.join(',');
      //     } else {

      //       queryCityMap += " RETURN " + mapLabelWithVariables(labelName) + "," + uniqueVariableArr.join(',');
      //     }
      //   }

  
  
      // } else if (activeRelations.length > 0) {
      //   let variableArr = [];
      //   activeRelations.map(function (e) {
      //     let variables = mapRelationWithVariables(e);
      //     let varArr = variables.split(',');
      //     variableArr = variableArr.concat(varArr);
      //   });
      //   let uniqueVariableArr = [...new Set(variableArr)];
      //   let findMainVarIndex = uniqueVariableArr.findIndex((p) => p === mapLabelWithVariables(labelName));
      //   if (findMainVarIndex > -1) {
      //     uniqueVariableArr.splice(findMainVarIndex, 1);
      //   }
      //   if (uniqueVariableArr.length == 0) {
      //     queryCityMap += "RETURN " + mapLabelWithVariables(labelName);
  
      //   } else {
      //     queryCityMap += "RETURN " + mapLabelWithVariables(labelName) + "," + uniqueVariableArr.join(',');
  
      //   }
      // } else {
        if (onlyNodes) {
          let uniqueVariableArr = variableArrAll;
          let findMainVarIndex = uniqueVariableArr.findIndex((p) => p === mapLabelWithVariables(labelName));
          if (findMainVarIndex > -1) {
            uniqueVariableArr.splice(findMainVarIndex, 1);
          }
          queryCityMap += "RETURN " + mapLabelWithVariables(labelName) + "," + uniqueVariableArr.join(',');
  
        } else {
          queryCityMap += pathString;
        }
      // }
      console.log(queryCityMap,"======queryCityMap")
      return queryCityMap.replace(/,\s*$/, "");
    }
    catch (err) {
      console.log(err);
    }
  }


  export const getLabelIcon = (n) => {
    try {
      if (n === 'Strategic Objective') {
        return '\uf140';
      }
      if (n === 'Initiative') {
        return '\uf0c1';
      }
      if (n === 'Business Capability') {
        return '\uf1f9';
      }
      if (n === 'Application Environment') {
        return '\uf080';
      }
      if (n === 'Application') {
        return '\uf085';
      }
      if (n === 'Business Process') {
        return '\uf00b';
      }
      if (n === 'Component') {
        return '\uf05b';
      }
      if (n === 'Product Cluster') {
        return '\uf00a';
      }
      if (n === 'Product Area') {
        return '\uf275';
      }
      if (n === 'Product Unit') {
        return '\uf0c0';
      }
      if (n === 'Product') {
        return '\uf218';
      }
      if (n === 'Technology') {
        return '\uf1de';
      }
      if (n === 'Platform') {
        return '\uf19c';
      }
      if (n === 'API') {
        return '\uf382';
      }
      if (n === 'Key Result') {
        return '\uf0c5';
      }
      if (n === 'Business Domain') {
        return '\uf0b1';
      }
      if (n === 'Database') {
        return '\uf0b1';
      }
      if (n === 'Column') {
        return '\uf0b1';
      }
      if (n === 'Driver') {
        return '\uf0b1';
      }
      if (n === 'Server') {
        return '\uf1b3';
      }
      if (n === 'Database Instance') {
        return '\uf0b1';
      }
      if (n === 'Integration') {
        return '\uf0ce';
      }
    }
    catch (err) {
      console.log(err);
    }
  }


  export const getColorCodeRelationShip = (relationName) => {
    if (relationName == "BUILT_ON") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "CONTAINS") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "MANAGED_BY") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "SUPPORTED_BY") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "USES") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "PRODUCE") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "CONSUME") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "CONSUMES") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "MEASURED_BY") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "REALIZED_BY") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "OWNED_BY") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "BELONGS_TO") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "LINKED_WITH") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "RECEIVE_DATA_FROM") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "DEPLOYED_ON") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "RUNNING_IN") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "INFLUENCES") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "PROVIDING_DATA_TO") {
      return 'rgb(171, 174, 138)';
    } else {
      return '';
    }
  }
  
  //Get color node for filter window
  export const getColorCodeNode = (relationName) => {
    if (relationName == "Strategic Objective") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "Initiative") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "Business Capability") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "Application") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "Business Process") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "Component") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "Product") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "Technology") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "Platform") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "API") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "Key Result") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "Product Cluster") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "Product Area") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "Business Domain") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "Database") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "Column") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "Product Unit") {
      return 'rgb(166, 226, 255)';
    } else if (relationName == "Driver") {
      return 'rgb(255, 194, 255)';
    } else if (relationName == "Application Environment") {
      return 'rgb(171, 174, 138)';
    } else if (relationName == "Server") {
      return 'rgb(156, 203, 255)';
    } else if (relationName == "Database Instance") {
      return 'rgb(0, 247, 255)';
    } else if (relationName == "Integration") {
      return 'rgb(166, 226, 255))';
    } else {
      return '';
    }
  }

  const getRelationShipBetweenTwoNodes = (label1, label2) => {
    return '';
  
  }


  export const alterEdges = (result1, result2 = [], compareType = '') => {
    try {
      let uniqueNodes1 = [];
      let uniqueNodes2 = []
      let uniqueNodes = []
      let parentNode
      if (compareType == '') {
        for (let index = 0; index < result1.records.length; index++) {
          for (let index1 = 0; index1 < result1.records[index]._fields.length; index1++) {
            if (index1 == 0) {
              parentNode = result1.records[index]._fields[index1];
            } else {
              if (result1.records[index]._fields[index1] != null) {
                if (uniqueNodes.findIndex(uniq => parseInt(uniq?.identity.low) === parseInt(result1.records[index]._fields[index1]?.identity.low)) === -1) {
                  uniqueNodes.push(result1.records[index]._fields[index1]);
                }
              }
            }
          }
        }
      } else if (compareType == '2' || compareType == '3') {
        for (let index = 0; index < result1.records.length; index++) {
          for (let index1 = 0; index1 < result1.records[index]._fields.length; index1++) {
            if (index1 == 0) {
              if (compareType == '2') {
                parentNode = result1.records[index]._fields[index1];
              }
            } else {
              if (result1.records[index]._fields[index1] != null) {
                if (uniqueNodes1.findIndex(uniq => parseInt(uniq?.identity.low) === parseInt(result1.records[index]._fields[index1]?.identity.low)) === -1) {
                  uniqueNodes1.push(result1.records[index]._fields[index1]);
                }
              }
            }
          }
        }
        for (let index = 0; index < result2.records.length; index++) {
          for (let index1 = 0; index1 < result2.records[index]._fields.length; index1++) {
            if (index1 == 0) {
              if (compareType == '3') {
                parentNode = result2.records[index]._fields[index1];
              }
            } else {
              if (result2.records[index]._fields[index1] != null) {
                if (uniqueNodes2.findIndex(uniq => parseInt(uniq?.identity.low) === parseInt(result2.records[index]._fields[index1]?.identity.low)) === -1) {
                  uniqueNodes2.push(result2.records[index]._fields[index1]);
                }
              }
            }
          }
        }
        if (compareType == '2') {
          if (uniqueNodes1.length > 0) {
            for (let k = 0; k < uniqueNodes1.length; k++) {
              let findIndex = uniqueNodes2.findIndex((p) => p.properties.referenceId === uniqueNodes1[k].properties.referenceId);
              if (findIndex == -1) {
                uniqueNodes.push(uniqueNodes1[k]);
              }
            }
          }
        } else {
          if (uniqueNodes2.length > 0) {
            for (let k = 0; k < uniqueNodes2.length; k++) {
              let findIndex = uniqueNodes1.findIndex((p) => p.properties.referenceId === uniqueNodes2[k].properties.referenceId);
              if (findIndex == -1) {
                uniqueNodes.push(uniqueNodes2[k]);
              }
            }
          }
        }
      }
      let startNode = {}
  
      let endNode = {}
  
      let newRecords = []
      if (uniqueNodes.length == 0) {
        let sampleObject = {
          "keys": [
            "connected",
            "referenceIds"
          ],
          "length": 2,
          "_fields": [],
          "_fieldLookup": {
            "connected": 0,
            "referenceIds": 1
          }
        }
        sampleObject['_fields'][0] = [];
        sampleObject['_fields'][1] = [];
        sampleObject['_fields'][0].push(parentNode);
        newRecords.push(sampleObject);
        result1.records = newRecords
        return result1;
      } else {
        let sampleObject = {
          "keys": [
            "connected",
            "referenceIds"
          ],
          "length": 2,
          "_fields": [],
          "_fieldLookup": {
            "connected": 0,
            "referenceIds": 1
          }
        }
        sampleObject['_fields'][0] = [];
        sampleObject['_fields'][1] = [];
        sampleObject['_fields'][0].push(parentNode);
        let loopI = 1;

        uniqueNodes.map((data, index) => {
          sampleObject['_fields'][0].push(data);

          let mapObject = {
            "identity": {
              "low": loopI.toString(),
              "high": 0
            },
            "type": getRelationShipBetweenTwoNodes(parentNode.labels[0], data.labels[0]),
            "properties": {},
            "elementId": "5:b76d4650-ee6a-4c18-901b-29042c2579f7:" + loopI,
            "start": {
              "low" : parentNode.identity.low,
              "high": 0
            },
            "end": {
              "low": data.identity.low,
              "high": 0
            } 
          }
          sampleObject['_fields'][1].push(mapObject);
          loopI++;
  
        })
        newRecords.push(sampleObject);
        result1.records = newRecords
        return result1;
      }
    }
    catch (err) {
      message.error(err.message);
    }
  }

  export const alterAndDownloadCsv = (csvData) => {
    // Parse the CSV data
    const parsedData = Papa.parse(csvData, { header: true });
    parsedData.meta.fields.splice(parsedData.meta.fields.indexOf('neo4jLabels'), 1);
  
    // Rename the "neo4jLabels" column to "label" and exchange "referenceid" with "id"
    parsedData.data.forEach(row => {
      row.id = row.referenceId;
      row.label = row.neo4jLabels;
    });
  
    // remame "neo4jLabels" to "label"
    const labelIndex = parsedData.meta.fields.indexOf('label');
    if (labelIndex !== -1) {
      parsedData.meta.fields[labelIndex] = 'label';
    }
  
    //removing referenceId column
    const refIndex = parsedData.meta.fields.indexOf('referenceId');
    if (refIndex !== -1) {
      parsedData.meta.fields.splice(refIndex, 1);
    }
  
    // Convert the modified data back to CSV format
    const modifiedCsvData = Papa.unparse(parsedData, { header: true });
  
    // Create a data URL and trigger download
    const blob = new Blob([modifiedCsvData], { type: 'text/xlsx' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'exported_data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }