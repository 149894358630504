import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  message,
  Row,
  Spin,
  Upload,
  Form,
  Flex,
  Tooltip,
  Modal,
  Input,
} from "antd";
import GlobalSearchServices from "../../services/services/GlobalSearchServices";

import {
  PlusOutlined,
  ScheduleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { PageTitleHeading } from "../../utils/Common";
import ShortVideoGuide from "./ShortVideoGuide";
import ShortVideoList from "./ShortVideoList";

const ShortVideoGuide_New = () => {
  const [loading, setLoading] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [data, setData] = useState([]);
  const [videoDataList, setVideoDataList] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const [listMode, setListMode] = useState("ALL");
  const [modelHeaderText, setModelHeaderText] = useState("Add");

  const { Search } = Input;

  const fetchShortGuideVideo = async (data) => {
    try {
      const response = await GlobalSearchServices.FetchShortGuideVideo(data);
      if (response.status == 200) {
        setVideoDataList(response.data);
        setData(response.data);
      } else {
        setVideoDataList([]);
        setData([]);
        console.log("Response Error...", response.error);
      }
    } catch (err) {
      console.log("Error...", err);
    }
  };

  useEffect(() => {
    fetchShortGuideVideo({});
  }, []);

  const onSearch = (value, _e, info) => {
    console.log(info?.source, value);
    const obj = { searchText: value };

    fetchShortGuideVideo(obj);
  };

  return (
    <>
      <div className="headingbx">
        <PageTitleHeading text={`User Guide Library`} />

        <Search
          placeholder="search video"
          allowClear
          onSearch={onSearch}
          style={{
            width: 400,
          }}
        />
        <Flex wrap gap="small" className="ml-2">
          <Tooltip title="Add Video">
            <Button
              className=""
              icon={<PlusOutlined style={{ color: "#2A609D" }} />}
              onClick={() => {
                setAddModel(true);
                setInitialValue({});
                setModelHeaderText("Add");
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              (listMode === "ALL" && "Video list which will display on Home") ||
              "All Video list"
            }
          >
            <Button
              className=""
              icon={
                (listMode === "ALL" && (
                  <ScheduleOutlined style={{ color: "#2A609D" }} />
                )) || <MenuOutlined style={{ color: "#2A609D" }} />
              }
              onClick={() => {
                 
                if (listMode === "ALL") { 
                  const HomeVideoList = data?.filter(
                    (f) => f.showonhome === true
                  );
                  setVideoDataList(HomeVideoList);
                  setListMode("Home");
                } else {
                  setVideoDataList(data);
                  setListMode("ALL");
                }
              }}
            />
          </Tooltip>
        </Flex>
      </div>

      <div className="mr-3 mt-2">
        {/* <Spin className="loading_bx" size="small" spinning={loading}> */}
        <React.Fragment>
          <ShortVideoList
            data={videoDataList}
            setAddModel={setAddModel}
            setInitialValue={setInitialValue}
            setModelHeaderText={setModelHeaderText} 
            fetchShortGuideVideo={fetchShortGuideVideo}
          />
        </React.Fragment>
        {/* </Spin> */}
      </div>

      <Modal
        destroyOnClose
        title={false}
        header={false}
        open={addModel}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setAddModel(false);
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
        centered
      >
        <div className="m-4 my-2" style={{ padding: 20 }}>
          <PageTitleHeading text={ modelHeaderText==="Add" && `Add and Upload Video` || `Edit and Upload Video` } />
          <ShortVideoGuide
            fetchShortGuideVideo={fetchShortGuideVideo}
            initialValue={initialValue}
            setInitialValue={setInitialValue} 
            modelHeaderText={modelHeaderText}
          />
        </div>
      </Modal>
    </>
  );
};
export default ShortVideoGuide_New;
