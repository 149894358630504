/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { PageTitleHeading } from "../../../utils/Common";
import GlobalSearchServices from "../../../services/services/GlobalSearchServices";
import HomeVideo from "./homeVideo";
import { Link } from "react-router-dom";

const HomeShortVideoGuide = () => {
  const [shortVideo, setShortVideo] = useState([]);

  useEffect(() => {
    getShortVideoUpload();
  }, []);

  const getShortVideoUpload = async () => {
    try {
      let response = await GlobalSearchServices?.fetchShortGuideVideoForHome();
      if (response?.data) {
        setShortVideo(response?.data);
      }
    } catch (error) {
      console.error("error:", error);
    }
  };
  
  return (
    <>
      <div className="headingbx">
        <PageTitleHeading text={"User Guide Library"} className={`mt-2 mb-0`} />
        <Link to="/userguidevideo" className="">
          View all
        </Link>
      </div>
      <HomeVideo data={shortVideo} />

    
    </>
  );
};

export default HomeShortVideoGuide;
