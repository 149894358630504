import AdminLayout from "../layouts/AdminLayout";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import $ from "jquery";
import { useEffect, useState,useRef } from "react";
import { getScenarioSubType } from "../../utils/ScenarioCommon";
import ScenarioServices from "../../services/services/ScenarioServices";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useNavigate } from "react-router";
import UserService from "../../services/UserService";
import LoadingBar from 'react-top-loading-bar'
import { checkRole } from "../../helper/useUserData";
const { Title } = Typography;

const CompareScenario = (props) => {
  const ref = useRef(null)
  const [loading, setLoading] = useState(false);
  const [scenarioSubType, setScenarioSubType] = useState([]);
  const [scenarioOrderBy, setScenarioOrderBy] = useState([]);
  const [baseScenario, setBaseScenario] = useState([]);
  const [selectedBaseScenario, setSelectedBaseScenario] = useState(null);
  const [compareForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    getScenarioSubType(loading, setLoading, setScenarioSubType);
    if (ref.current) {
      ref.current.complete();
    }
  }, []);

  const getBaseScenario = async (value) => {
    let obj = {};
    obj.scenarioSubtypeID = value;
    if (!UserService.hasRole(['Admin'])) {
      obj.userEmail = localStorage.getItem('email');
    }
    setLoading(true);
    await ScenarioServices.getScenarioList(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          let orderBy = [];
          let scenarioList = responseVal?.map((element) => {
            return { value: element?.id, label: element?.name, checked: false };
          });
          setBaseScenario(scenarioList);
          //   set Scenario Order by
          if (value == 1) {
            orderBy = [
              {
                value: "column_info_flow_base_id",
                label: "Info Flow Base Id",
                disabled: true,
              },
              {
                value: "column_business_capability",
                label: "Business Capability",
                disabled: false,
                selected: true,
              },
            ];
          } else if (value == 2) {
            orderBy = [
              {
                value: "column_info_flow_base_id",
                label: "Info Flow Base Id",
                disabled: false,
                selected: true,
              },
              {
                value: "column_business_capability",
                label: "Business Capability",
                disabled: true,
              },
            ];
          } else {
            orderBy = [];
          }
          setScenarioOrderBy(orderBy);
          setSelectedBaseScenario(null);
          compareForm.resetFields([
            "baseScenarioID",
            "orderByScenario",
            "searchScenario",
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const searchScenario = (e) => {
    let $rows = $("#compare-table tbody tr");
    let val = e?.target?.value.toLowerCase();
    $rows
      .show()
      .filter(function () {
        var text = $(this).text().replace(/\s+/g, " ").toLowerCase();
        return !~text.indexOf(val);
      })
      .hide();
  };

  const handleSelectBaseScenario = (val) => {
    setSelectedBaseScenario(val);
    $("#compare-table tbody tr input[type=checkbox]").each((index, element) => {
      if (element.value == val) {
        $("#compare-table tbody tr input[value='" + val + "']").prop(
          "checked",
          true
        );
      }
    });
  };

  const handleCompare = () => {
    compareForm
      .validateFields()
      .then((data) => {
        compareForm.submit();
      })
      .catch((err) => { });
  };

  const onFormSubmit = async (formData) => {
    const { baseScenarioID, orderByScenario } = formData;
    let compareScenarioIds = [];
    $("#compare-table")
      .find("tbody .comparable-checked-scenarios")
      .each(function () {
        if (
          $(this).prop("checked") == true &&
          $(this).prop("value") != baseScenarioID
        ) {
          compareScenarioIds.push($(this).prop("value"));
        }
      });

    if (compareScenarioIds.length === 0) {
      message.error("Please select atleast one scenario to compare.");
    } else {
      navigate('/compare-scenario-list', { state: { baseScenarioID, compareScenarioIds: compareScenarioIds, filterColumn: orderByScenario } })
    }
  };

  return (
    <>
    <LoadingBar color='#1f1f1f' ref={ref} />
      <Title level={5} className="text-start py-2">
        Compare Landscape
      </Title>
      <Spin className="loading_bx" size="small" spinning={loading}>
        <Card className="mb-2">
          <Form
            form={compareForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFormSubmit}
            initialValues={{}}
            requiredMark={false}
          >
            <Form.Item
              label={
                <>
                  <span>Landscape Sub Type</span>
                  <span className="text-danger">*</span>
                </>
              }
              name="scenarioSubtypeID"
              rules={[
                { required: true, message: "Please enter scenario Sub Type" },
              ]}
              className="mb-2 text-start"
            >
              <Select
                allowClear
                placeholder="Select"
                onChange={getBaseScenario}
              >
                {scenarioSubType?.map((e) => {
                  return (<Select.Option value={e.value} key={Math.random()}>{e.label}</Select.Option>);
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span>Base Landscape</span>
                  <span className="text-danger">*</span>
                </>
              }
              name="baseScenarioID"
              rules={[
                { required: true, message: "Please enter Base Landscape" },
              ]}
              className="mb-2 text-start"
            >
              <Select
                allowClear
                placeholder="Select"
                onChange={handleSelectBaseScenario}
              >
                {baseScenario?.map((e) => {
                  return (<Select.Option value={e?.value} key={Math.random()}>{e?.label}</Select.Option>);
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span>Landscape Order By</span>
                  <span className="text-danger">*</span>
                </>
              }
              name="orderByScenario"
              rules={[
                { required: true, message: "Please enter landscape order by" },
              ]}
              className="mb-2 text-start"
            >
              <Select allowClear placeholder="Select">
                {scenarioOrderBy?.map((e) => {
                  return (
                    <Select.Option value={e?.value} disabled={e?.disabled} key={Math.random()} selected={e.selected && 'selected'}>
                      {e?.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span>Choose other landscapes to compare</span>
                </>
              }
              name="searchScenario"
              className="mb-2 text-start"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Input
                onChange={searchScenario}
                placeholder="Type to search..."
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </Card>

        <Row>
          <Col xs={24} md={24} lg={24}>
            <Card
              className="text-start table-responsive"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <table
                id="compare-table"
                className="table table-striped dataTable table-bordered"
                style={{ width: "100%" }}
              >
                <thead className="fw-2">
                  <tr className="sticky">
                    <th width="5%" className="text-center">Select</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {baseScenario.length ? (
                    baseScenario?.map((e, index) => {
                      return (
                        <tr key={Math.random()}>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={
                                selectedBaseScenario === e?.value ? true : e.checked ? true : false
                              }
                              disabled={
                                selectedBaseScenario === e?.value ? true : false
                              }
                              className="comparable-checked-scenarios"
                              onChange={() => {
                                const scenario = Object.assign([], baseScenario);
                                scenario[index].checked = !e.checked;
                                setBaseScenario(scenario);
                              }}
                              value={e?.value}
                            />
                          </td>
                          <td>{e?.label}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>

        {checkRole('add') && <Button className="mt-4" onClick={handleCompare}>
          Compare
        </Button>}
      </Spin>
    </>
  );
};

export default CompareScenario;
