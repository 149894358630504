import React, { useEffect, useState } from "react"; 
import Delayed from "../../../utils/Delayed"; 
import { left } from "@popperjs/core";
import { Card, Col, Flex, Row, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";

function VideoGuideGrid({ data  }) {

  return (
    <React.Fragment>
      <Row gutter={[16,16]}>
        {
          data?.length > 0 &&
          data?.map((item, index) => {
            return (
              <Col span={6} key={index+Math.random()}>
                <div className="embed-responsive embed-responsive-16by9">
                  <video width="320" height="240" autoPlay={false} muted={true} controls >
                    <source src={item.linkUrl} type="video/mp4" className="embed-responsive-item"/>
                    <source src={item.linkUrl} type="video/ogg" className="embed-responsive-item"/>
                  </video>
                </div>
                <Flex vertical>
                  {new Date(item.createdAt).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    //hour: '2-digit',
                    //minute: '2-digit',
                    //second: '2-digit',
                  })}
                 <h4> <ExpandableText text={item?.caption} className="text-primary"/></h4>
                 <h6> <ExpandableText text={item?.tags?.join(",")} rows="10" /></h6>
                  <ExpandableText text={item?.description} className="fs-small"/>
                </Flex>
              </Col>
            )
          })
        }
      </Row>
    </React.Fragment>
  );
}

export default VideoGuideGrid;


const ExpandableText = ({ rows=1,text="",className="",...props }) => {

  return (<React.Fragment>
    <Typography.Paragraph 
      ellipsis={{
        rows,
        tooltip:true,
      }} 
      className={`mb-0 ${className}`}
      {...props}
    > 
      {text} 
    </Typography.Paragraph>
  </React.Fragment>)
};
