import React, { useEffect } from 'react';
import { Bar } from '@antv/g2plot';
import { useNavigate } from 'react-router';

const ScenarioChart = ({ scenarioChartData }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const convertedData = scenarioChartData.filter((f)=>f.status!=='Total')?.map(item => {
            return {
                ...item,
                value: parseInt(item.value, 10)  // Convert string to integer
            }
        });
        
        const barPlot = new Bar('scenario-chart-3', {
            data:convertedData,
            xField: 'value',
            yField: 'status',
            barWidthRatio: 0.5, // Adjust the ratio to control spacing
            barStyle:{
                padding:20,
            },
            color: ({status}) => {
                return convertedData.find(f=>f.status===status)?.color;
            },
            label: {
                position: 'right', // 'top', 'bottom', 'middle'
            },
            interactions: [{ type: 'element-active' }],
        });
        
        barPlot.render();

        // Add click event listener
        barPlot.on('element:click', (event) => {
            const { data } = event;
            if(data?.data?.status){
                navigate('/list-scenario',{ state: {filter:{ "approvalstatus": data?.data?.status}} })
            }
        });

        barPlot.on('element:label:click', (event) => {
            const { data } = event;
            if(data?.data?.status){
                navigate('/list-scenario',{ state: {filter:{ "approvalstatus": data?.data?.status}} })
            }
        });

        // Cleanup on component unmount
        return () => {
            barPlot.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioChartData]);

    return <div id="scenario-chart-3"  />;
}

export default ScenarioChart
