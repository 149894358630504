import { Column } from '@antv/g2plot';
import { Typography } from 'antd';
import React, { useEffect } from "react";

const DCBarCharts2 = ({ portfolioData, width=50,data, ...props }) => {
  const chartId = `dcbar-chart-`+Math.random();
  useEffect(() => {
    const barPlot = new Column(chartId, {
      data,
      xField: "type",
      yField: "value",
      maxColumnWidth:40,
      color: ({name}) => {
        return portfolioData?.color;
      },
      label: {
        position: 'middle', // 'top', 'bottom', 'middle'
        style: {
          fill: '#000', // Change this to your desired label color
          fontSize: 12, // Optional: set font size
        },
      },
      forceFit: true,
      xAxis: {
        grid: null, // This hides the grid lines for the x-axis
      },
      yAxis: {
        grid: null, // This hides the grid lines for the x-axis
      },
    });    
    barPlot.render();

    // Cleanup on component unmount
    return () => {
        barPlot.destroy();
    };
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [data]);

  return (
    <React.Fragment>
      <div>
        {props?.title && <Typography.Title level={5} className="mb-0">{props?.title}</Typography.Title>}
        <div id={chartId}  style={{maxHeight:150}}/>
      </div>
    </React.Fragment>
  );
};

export default React.memo(DCBarCharts2);
