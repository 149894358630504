import { Button, Checkbox, Input, message, Space, Tabs } from "antd";
import React, { useState } from "react";
import {
  getQueryForCityMap,
  mapLabelWithVariables,
} from "../../utils/CityMapCommon";
import $ from "jquery";

const PropertiesFilter = (props) => {
  const { setCityMapQuery, formData, selectedFilterNodes, setShowGraph } =
    props;
  const handleApplyFilter = (e) => {
    e.preventDefault();
    let formValues = $("#prop-filter-form").serializeArray();

    if (Object.values(formValues).length) {
      var propFilerQuery = getQueryForCityMap(
        formData?.city_map_node,
        formData?.city_map_label,
        false,
        selectedFilterNodes,
        [],
        Object.values(formValues)
      );
      if (propFilerQuery && propFilerQuery != "") {
        setCityMapQuery(propFilerQuery);
      }
    }
  };

  const handleClearFilter = () => {
    try {
      $("#prop-filter-form")[0].reset();
      var propFilerQuery = getQueryForCityMap(
        formData?.city_map_node,
        formData?.city_map_label,
        false,
        selectedFilterNodes
      );
      if (propFilerQuery && propFilerQuery != "") {
        setCityMapQuery(propFilerQuery);
        setShowGraph(true);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <form id="prop-filter-form" onsubmit="return false;">
        <Tabs
          tabPosition="left"
          className="prop-filter-tabs"
          items={props?.propFilterData.map((element) => {
            let labelVar = mapLabelWithVariables(element?.label);
            return {
              label: element?.name,
              key: element?.id,
              children: (
                <Space size={[8, 16]} wrap>
                  {element?.options.map((items) => {
                    return (
                      <div className="prop-filter-parent">
                        <input
                          type="checkbox"
                          name={labelVar + ".`" + element?.name + "`"}
                          value={items?.value}
                        />
                        <label>{items?.showName}</label>
                      </div>
                    );
                  })}
                </Space>
              ),
            };
          })}
        />
        <div className="mt-4 text-end">
          <Button className="btn-success me-2" onClick={handleApplyFilter}>
            Apply Filter
          </Button>
          <Button className="" onClick={handleClearFilter}>
            {" "}
            Clear All Filters
          </Button>
        </div>
      </form>
    </>
  );
};

export default PropertiesFilter;
