import "./BusinessApplication.scss";
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import BusinessApplicationList from './BusinessApplicationList';
import BusinessApplicationGrid from './BusinessApplicationGrid';
import BusinessApplicationMatrx from "./BusinessApplicationMatrx";
import { getMenuPortfolio } from "../../../utils/settingCommon";
import { ListSelectAllCheckboxComponent, showViewType } from "../common/PortfolioCommon";
import { Spin } from "antd";

const BusinessApplication = ({moduleName,...props}) => {
    const location = useLocation()
    const mainDivRef = useRef(null);
    const [activePage, setActivePage] = useState(1);
    const [filterOptions, setFilterOptions] = useState({});
    const [moduleData,setModuleData] = useState(null);  
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAllChecked,setSelectAllChecked] = useState(false);
    

    useState(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1)
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    },[])

    useEffect(()=>{ console.log("called:",bulkUpdateIds);
     },[bulkUpdateIds])

    useEffect(()=>{
        setLoading(true);
        setSelectAllChecked(false);
        setLoading(false);
    },[lists])

    const onCheckAllChange = (checked) => {
        setLoading(true);
        setTimeout(() => {
            if(checked){
                setBulkUpdateIds([...bulkUpdateIds,...lists?.map(v=>v.id)])
            }else{
                setBulkUpdateIds([])
            }
            setSelectAllChecked(checked);
            setLoading(false);
        }, lists?.length*5);
    }    

    const HeaderContent = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={moduleData?.name || moduleName}
        matrix={false}
        list={false}
        grid={false}
        addBtnTooltip={`Add New`}
        filterOptions={filterOptions}
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
        Buttons={<ListSelectAllCheckboxComponent onCheckAllChange={onCheckAllChange} selectAllChecked={selectAllChecked}/>}
    />;



    return <React.Fragment>
        <div className='business-application-page' ref={mainDivRef}>
            <Spin spinning={loading}>
            {
                ((()=>{
                    if(activePage===1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"})){
                        return <React.Fragment>
                            <BusinessApplicationList 
                                HeaderContent = {HeaderContent}
                                activePage={activePage}
                                moduleName={moduleName}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}                                
                                isListCheckbox={true}
                                setBulkDeleteButton={setBulkDeleteButton}
                                deleteStart={deleteStart}
                                setDeleteStart={setDeleteStart}
                                bulkUpdateIds={bulkUpdateIds}
                                setBulkUpdateIds={setBulkUpdateIds}
                                setLists={setLists}
                            />
                        </React.Fragment>
                    }else if(activePage===2 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"})){
                        return <React.Fragment>
                            <BusinessApplicationGrid 
                                HeaderContent = {HeaderContent}
                                activePage={1}
                                moduleName={moduleName}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                            />
                        </React.Fragment>
                    }else if(activePage===3 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"})){
                        return <React.Fragment>
                            <BusinessApplicationMatrx 
                                HeaderContent = {HeaderContent}
                                moduleName={moduleName}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                            />
                        </React.Fragment>
                    }
                    return <React.Fragment></React.Fragment>;
                })())
            }
            </Spin>
        </div>
    </React.Fragment>;
}

BusinessApplication.defaultProps = {}

export default BusinessApplication