import React from 'react';
import UserService from "../services/UserService";
import {Navigate, Outlet} from 'react-router-dom'


const  ProtectedRoutes=(props) =>{

  return UserService.isLoggedIn()?<Outlet/>: <Navigate to="/"/>
}

export default ProtectedRoutes;